import { Session } from "./sessionstore";
import { ApiConfig } from "../state/applicationconfig";
import { HttpClient } from "./httpclient";
import { AuthApi } from "./impl/auth";
import { DataApi } from "./impl/data";
import { LangApi } from "./impl/lang";
import { MetadataApi } from "./impl/metadata";
import { ProjectApi } from "./impl/project";
import { FetchStatus, IAuthApi, IDataApi, ILangApi, IMetadataApi, IProjectApi } from "./types";
import {
  createAuthRequestInterceptor,
  createApiErrorInterceptor,
  createStatusErrorInterceptor,
  createTokenRefreshInterceptor,
  createLoadingInterceptor,
  createLoadingCompleteInterceptor,
} from "./interceptors";
import { createOrganizationSwitchInterceptor } from "../state/organization";

/**
 * API Client Library
 *
 * Contains the API endpoints for communicating with the backend services.
 * The auth.authenticate method is used in the authentication flow.
 *
 * For the complete authentication flow and token refresh mechanism, see:
 * - src/contexts/AuthContext.tsx
 * - docs/AUTH.md
 */

export class Apis {
  private _config: ApiConfig;
  private _auth: IAuthApi;
  private _metadata: IMetadataApi;
  private _project: IProjectApi;
  private _data: IDataApi;
  private _lang: ILangApi;
  private _globalStatusFn: (status: FetchStatus) => void = () => {};
  private _sessionRefreshFn: () => Promise<void> = async () => {};
  private _needSessionRefreshFn: () => Promise<boolean> = async () => false;

  get auth(): IAuthApi {
    return this._auth;
  }
  get metadata(): IMetadataApi {
    return this._metadata;
  }
  get project(): IProjectApi {
    return this._project;
  }
  get data(): IDataApi {
    return this._data;
  }
  get lang(): ILangApi {
    return this._lang;
  }

  public set globalStatusFn(fn: (status: FetchStatus) => void) {
    this._globalStatusFn = fn;
  }

  public set sessionRefreshFn(fn: () => Promise<void>) {
    this._sessionRefreshFn = fn;
  }

  public set needSessionRefreshFn(fn: () => Promise<boolean>) {
    this._needSessionRefreshFn = fn;
  }

  constructor(config: ApiConfig) {
    this._config = config;
    const endpoints = this._config.endpoints;
    const statusFn = (status: FetchStatus) => {
      this._globalStatusFn(status);
    };

    // Create error interceptors
    const errorInterceptors = [
      createStatusErrorInterceptor(statusFn), // Handle status first
      createApiErrorInterceptor(), // Then convert to API errors
    ];

    const projectErrorInterceptors = [...errorInterceptors];

    // Create auth client first without any interceptors
    const authClient = new HttpClient(endpoints.auth, [], [], errorInterceptors);
    this._auth = new AuthApi(authClient);

    // Create interceptors after auth is initialized
    const requestInterceptors = [
      createLoadingInterceptor(statusFn),
      createTokenRefreshInterceptor(
        () => this._sessionRefreshFn(),
        () => this._needSessionRefreshFn()
      ),
      createAuthRequestInterceptor(Session.shared()),
    ];

    const responseInterceptors = [createLoadingCompleteInterceptor(statusFn)];

    // Create other clients with all interceptors
    this._project = new ProjectApi(
      new HttpClient(endpoints.project, requestInterceptors, responseInterceptors, projectErrorInterceptors)
    );

    this._data = new DataApi(
      new HttpClient(endpoints.data, requestInterceptors, responseInterceptors, errorInterceptors)
    );
    this._metadata = new MetadataApi(
      new HttpClient(endpoints.metadata, requestInterceptors, responseInterceptors, projectErrorInterceptors)
    );
    this._lang = new LangApi(
      new HttpClient(endpoints.lang, requestInterceptors, responseInterceptors, errorInterceptors)
    );

    // We cherry-pick the organization lookup function from the metadata client to avoid circular dependency
    projectErrorInterceptors.push(
      createOrganizationSwitchInterceptor(async (type: string, id: number) => {
        const orgInfo = await this._metadata.lookupResourceOrganization(type, id);
        return orgInfo;
      })
    );
  }

  private static _sharedInstance: Apis | null;
  public static shared(): Apis {
    return this._sharedInstance!;
  }
  public static createShared(config: ApiConfig) {
    this._sharedInstance = new Apis(config);
  }
}
