import React from "react";
import { DownloadUrlInfo } from "../api/apimodels";

export function createDownloadHandler(
  filename: string,
  downloadUrlFn: () => Promise<DownloadUrlInfo>
): (e: React.MouseEvent<HTMLElement>) => Promise<void> {
  return async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const link = document.createElement("a");
    link.download = filename!;
    //let url = signedLinkFn ? await signedLinkFn(downloadUrl ?? "") : downloadUrl;
    const { url } = await downloadUrlFn();
    link.href = url;
    link.click();
  };
}
