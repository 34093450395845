import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ConversationSummary, ConversationSummaryResponse } from "../../../api/apimodels";
import moment from "moment";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import ConfirmButton from "../../common/ConfirmButton";

interface CollectConversationsProps {
  conversations: ConversationSummaryResponse;
  onLoadMore: (offset: number) => void;
  loading: boolean;
  onDeleteConversations?: (conversationIds: string[]) => Promise<void>;
}

export function CollectConversations({
  conversations,
  onLoadMore,
  loading,
  onDeleteConversations,
}: CollectConversationsProps) {
  const [selectedConversations, setSelectedConversations] = useState<string[]>([]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    const newOffset = (page - 1) * conversations.limit;
    onLoadMore(newOffset);
    setSelectedConversations([]);
  };

  const handleSelectConversation = (conversationId: string) => {
    setSelectedConversations((prev) =>
      prev.includes(conversationId) ? prev.filter((id) => id !== conversationId) : [...prev, conversationId],
    );
  };

  const handleSelectAll = () => {
    if (selectedConversations.length === conversations.conversations.length) {
      setSelectedConversations([]);
    } else {
      setSelectedConversations(conversations.conversations.map((c) => c.conversation_id));
    }
  };

  const handleDeleteSelected = async () => {
    if (onDeleteConversations && selectedConversations.length > 0) {
      await onDeleteConversations(selectedConversations);
      setSelectedConversations([]);
    }
  };

  const currentPage = Math.floor(conversations.offset / conversations.limit) + 1;
  const totalPages = Math.ceil(conversations.total_count / conversations.limit);

  return (
    <Stack spacing={3}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6">
          {conversations.conversations.length > 0 ? (
            <>
              Conversations ({conversations.offset + 1}-
              {Math.min(conversations.offset + conversations.conversations.length, conversations.total_count)} of{" "}
              {conversations.total_count})
            </>
          ) : (
            "No conversations found"
          )}
        </Typography>
        {onDeleteConversations && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={
                selectedConversations.length === conversations.conversations.length &&
                conversations.conversations.length > 0
              }
              indeterminate={
                selectedConversations.length > 0 && selectedConversations.length < conversations.conversations.length
              }
              onChange={handleSelectAll}
              disabled={conversations.conversations.length === 0}
            />
            <Tooltip title="Delete selected conversations">
              <span>
                <ConfirmButton
                  variant="outlined"
                  color="error"
                  size="small"
                  confirmText="Delete"
                  dialogTitle="Delete selected conversations?"
                  dialogContent={
                    <>
                      Are you sure you want to delete {selectedConversations.length} conversation(s)? This affects{" "}
                      <strong>all future imports</strong>.
                    </>
                  }
                  startIcon={<DeleteSweepIcon />}
                  onConfirm={handleDeleteSelected}
                  disabled={selectedConversations.length === 0 || loading}
                >
                  Delete Selected ({selectedConversations.length})
                </ConfirmButton>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>

      {conversations.conversations.length === 0 ? (
        <Alert severity="info">No conversations found with the current filters.</Alert>
      ) : (
        <>
          {conversations.conversations.map((conversation) => (
            <ConversationCard
              key={conversation.conversation_id}
              conversation={conversation}
              selected={selectedConversations.includes(conversation.conversation_id)}
              onSelect={onDeleteConversations ? handleSelectConversation : undefined}
              onDelete={onDeleteConversations ? () => onDeleteConversations([conversation.conversation_id]) : undefined}
            />
          ))}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} disabled={loading} />
          </Box>
        </>
      )}
    </Stack>
  );
}

interface ConversationCardProps {
  conversation: ConversationSummary;
  selected?: boolean;
  onSelect?: (conversationId: string) => void;
  onDelete?: () => Promise<void>;
}

export function ConversationCard({ conversation, selected = false, onSelect, onDelete }: ConversationCardProps) {
  const [showAllMessages, setShowAllMessages] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDelete) {
      setIsDeleting(true);
      try {
        await onDelete();
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 1,
        borderColor: selected ? "primary.main" : undefined,
        bgcolor: selected ? "action.selected" : undefined,
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {onSelect && (
                <Checkbox
                  checked={selected}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => onSelect(conversation.conversation_id)}
                  sx={{ ml: -1, mr: 1 }}
                />
              )}
              <Typography variant="subtitle1" fontWeight="medium">
                Conversation #{conversation.conversation_id}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Chip
                label={conversation.metadata.completed ? "Completed" : "Incomplete"}
                color={conversation.metadata.completed ? "success" : "warning"}
                size="small"
                sx={{ mr: onDelete ? 1 : 0 }}
              />
              {onDelete && (
                <Tooltip title="Delete conversation">
                  <IconButton size="small" color="error" onClick={handleDelete} disabled={isDeleting}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Typography variant="body2" color="text.secondary">
            Started: {moment(conversation.metadata.started_at).format("YYYY-MM-DD HH:mm")} • Messages:{" "}
            {conversation.metadata.message_count}
          </Typography>

          <Divider />

          <Box>
            <Stack spacing={1}>
              {conversation.messages
                .slice(0, showAllMessages ? conversation.messages.length : 3)
                .map((message, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    justifyContent={message.by === "system" ? "flex-start" : "flex-end"}
                  >
                    <Box
                      sx={{
                        p: 1,
                        borderRadius: 1,
                        bgcolor: message.by === "system" ? "action.hover" : "primary.dark",
                        color: message.by === "system" ? "text.primary" : "primary.contrastText",
                        maxWidth: "85%",
                        width: "fit-content",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography variant="body2" whiteSpace="pre-wrap">
                        {message.content}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        color={message.by === "system" ? "text.secondary" : "primary.contrastText"}
                        align="right"
                      >
                        {moment(message.timestamp).format("HH:mm")}
                      </Typography>
                    </Box>
                  </Stack>
                ))}
              {!showAllMessages ? (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  onClick={() => setShowAllMessages(true)}
                  sx={{ cursor: "pointer" }}
                >
                  + {conversation.messages.length - 3} more messages
                </Typography>
              ) : null}
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
