import {
  Alert,
  Backdrop,
  Box,
  Breadcrumbs,
  CircularProgress,
  Fade,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLoaderData, useSearchParams, useNavigate } from "react-router-dom";
import { DatasetArtifact, ExplorationModel } from "../../api/apimodels";
import { ApiId } from "../../api/apimodels";
import { Nav } from "../../components/common/Nav";
import * as PageInfo from "../../pages/PageInfo";
import { ExplorationDetailsDataTab } from "./data/ExplorationDataTab";
import { ExplorationInfoTab } from "./info/ExplorationInfoTab";
import { ExplorationCompareTab } from "./compare/ExplorationCompareTab";
import { HierarchyFiles } from "./hierarchy/types";
import { ExplorationHierarchyTab } from "./hierarchy/ExplorationHierarchyTab";
import { DeleteButton } from "../../components/common/DeleteButton";
import { Apis } from "../../api/apis";

const hasHierarchy = (artifacts: DatasetArtifact[] | undefined): boolean => {
  return artifacts ? !!HierarchyFiles.find(a => artifacts.map(a => a.name).includes(a)) : false;
};

export const ExplorationDetailsPage = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { exploration, projectId } = useLoaderData() as { exploration: ExplorationModel, projectId: ApiId };
  const section = searchParams.get("section") ?? "data";
  const artifacts = exploration.dataset?.artifacts;
  const navigate = useNavigate();

  const setSection = (section: string) => {
    setSearchParams(new URLSearchParams({ section }));
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box component="main" sx={{ flexGrow: 1, overflow: "auto" }}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
            <Nav to="./..">{PageInfo.ExplorationPage.name}</Nav>
            <Typography color="text.primary">{exploration.name}</Typography>
          </Breadcrumbs>
          {exploration.dataset && <ToggleButtonGroup size="small" sx={{ mr: 3 }} exclusive value={section} onChange={(e, s) => setSection(s)}>
            <ToggleButton value="info">Info</ToggleButton>
            {hasHierarchy(artifacts) && <ToggleButton value="hierarchy">Hierarchy</ToggleButton>}
            <ToggleButton value="data">Data</ToggleButton>
          </ToggleButtonGroup>
          }
        </Stack>
        {exploration.is_archived && (
          <Box sx={{ mx: 3, mt: 2 }}>
            <Alert severity="warning">This exploration is archived.</Alert>
          </Box>
        )}
        {!exploration.dataset && (
          <Box sx={{ mx: 3, mt: 2 }}>
            <Alert severity="error">
              <Stack direction="column" justifyContent="space-between" alignItems="flex-start" gap={1}>
                <Typography>This exploration is using a deleted dataset. You cannot access it anymore.</Typography>
                <DeleteButton
                  entityType="exploration"
                  hardDelete={false}
                  variant="outlined"
                  onDelete={async () => {
                    await Apis.shared().project.deleteExploration(projectId!, exploration.id!);
                    navigate(`/project/${projectId}/exploration`);
                  }}
                />
              </Stack>
            </Alert>
          </Box>
        )}
        {exploration.dataset && section === "data" && <ExplorationDetailsDataTab loading={loading} setLoading={setLoading} />}
        {exploration.dataset && section === "info" && <ExplorationInfoTab loading={loading} setLoading={setLoading} />}
        {exploration.dataset && section === "hierarchy" && (
          <ExplorationHierarchyTab loading={loading} setLoading={setLoading} artifacts={artifacts} />
        )}
        {exploration.dataset && section === "compare" && (
          <ExplorationCompareTab loading={loading} setLoading={setLoading} selections={exploration.selections} />
        )}
      </Box>
      <Fade in={loading} timeout={{ enter: 3000, exit: 200 }}>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} />
        </Backdrop>
      </Fade>
    </Box>
  );
};
