import { Navigate, useLocation } from "react-router-dom";
import { Session } from "../../api/sessionstore";
import { useAuth } from "../../contexts/AuthContext";
import { ApiLoadingState } from "../../api/types";

type PrivateRouteProps = {
  children: React.ReactNode;
};

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  // If auth is still loading, show nothing or a loading indicator
  // This prevents premature redirects during the authentication process
  if (loading === ApiLoadingState.loading) {
    return null; // Or return a loading spinner component
  }

  // Only redirect to login if we're sure the user is not authenticated
  // and we're done loading the auth state
  if (!user && !Session.shared().token) {
    // Store the current location as a deepLink before redirecting
    // This is now handled in the authenticatedLoader in index.tsx, but
    // kept here as a safety net
    if (location.pathname !== "/login" && location.pathname !== "/__/auth/handler") {
      Session.shared().deepLink = location.pathname + location.search;
    }
    // Redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If we have a user or session token, render children
  return <>{children}</>;
};
