import { ErrorInterceptor, RequestContext, RequestInterceptor, ResponseInterceptor } from "./httpclient";
import { ISession } from "./sessionstore";
import { ApiError, ApiGeneralError, ApiNotAuthorizedError, ApiNotFoundError, FetchStatus } from "./types";

export function createTokenRefreshInterceptor(
  sessionRefreshFn: () => Promise<void>,
  needSessionRefreshFn: () => Promise<boolean>
): RequestInterceptor {
  // Store the active refresh promise
  let activeRefreshPromise: Promise<void> | null = null;

  return async (config: RequestInit): Promise<RequestInit> => {
    if (await needSessionRefreshFn()) {
      // If a refresh is already in progress, wait for it
      if (!activeRefreshPromise) {
        // Start a new refresh
        activeRefreshPromise = sessionRefreshFn().finally(() => {
          activeRefreshPromise = null;
        });
      }

      // Wait for the active refresh to complete
      await activeRefreshPromise;
    }
    return config;
  };
}

export function createAuthRequestInterceptor(session: ISession): RequestInterceptor {
  return async (config: RequestInit): Promise<RequestInit> => {
    // Add token to request headers
    const newConfig = { ...config };
    const headers: Record<string, any> = { ...newConfig.headers };
    headers["Authorization"] = `Bearer ${session.token}`;
    if (session.organizationId) {
      headers["X-Organization-ID"] = session.organizationId;
    }
    newConfig.headers = headers;

    return newConfig;
  };
}

export function createApiErrorInterceptor(): ErrorInterceptor {
  return async (error: any) => {
    try {
      // If it's already an ApiError, just rethrow it
      if (error instanceof ApiError) {
        throw error;
      }

      // Handle fetch errors (network issues, etc)
      if (error instanceof Error && !(error instanceof ApiError)) {
        throw new ApiGeneralError(undefined, {
          message: error.message,
          type: "network_error",
        });
      }

      // Handle Response objects with error status
      if (error instanceof Response) {
        let details: Record<string, any> = {};
        try {
          details = await error.json();
        } catch (e) {}

        switch (error.status) {
          case 401:
          case 403:
            throw new ApiNotAuthorizedError(error.status, details);
          case 404:
            throw new ApiNotFoundError(error.status, details);
          default:
            if (error.status >= 400) {
              throw new ApiGeneralError(error.status, details);
            }
            throw error;
        }
      }

      // Fallback
      throw error;
    } catch (e) {
      return e; // Throwing will be taken care of by the httpclient
    }
  };
}

export function createStatusErrorInterceptor(statusFn?: (status: FetchStatus) => void): ErrorInterceptor {
  return async (error: any) => {
    try {
      return error;
    } finally {
      statusFn?.(FetchStatus.idle);
    }
  };
}

export function createLoadingInterceptor(statusFn: (status: FetchStatus) => void): RequestInterceptor {
  return async (config: RequestInit) => {
    statusFn?.(FetchStatus.started);
    return config;
  };
}

export function createLoadingCompleteInterceptor(statusFn: (status: FetchStatus) => void): ResponseInterceptor {
  return async (context: RequestContext) => {
    statusFn?.(FetchStatus.idle);
    return context.response;
  };
}
