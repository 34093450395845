export function stripMarkdownBlock(markdown: string): string {
  const START_MARKER = "```markdown";
  const END_MARKER = "```";
  let result = markdown;
  if (markdown.startsWith(START_MARKER)) {
    result = markdown.substring(START_MARKER.length);
    if (result.endsWith(END_MARKER)) {
      result = result.substring(0, result.length - END_MARKER.length);
    }
  }
  return result;
}
