import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { useState } from "react";

interface ArchiveButtonProps extends ButtonProps {
  entityType?: string;
  isArchived: boolean;
  onToggleArchive: () => Promise<void>;
  confirmTitle?: string;
  confirmMessage?: string;
  tooltipTitle?: string;
  showIcon?: boolean;
}

export function ArchiveButton({
  entityType = "item",
  isArchived,
  onToggleArchive,
  confirmTitle,
  confirmMessage,
  tooltipTitle,
  showIcon = false,
  children,
  ...buttonProps
}: ArchiveButtonProps) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleToggleArchive = async () => {
    setIsUpdating(true);
    try {
      await onToggleArchive();
    } catch (error) {
      console.error("🗄️ ❌ Failed to toggle archive state", error);
    } finally {
      setIsUpdating(false);
      setConfirmOpen(false);
    }
  };

  const actionText = isArchived ? "Unarchive" : "Archive";
  const defaultChildren = isArchived ? "Unarchive" : "Archive";
  const defaultTooltip = isArchived ? "Unarchive" : "Archive";

  const title = confirmTitle || `${actionText} ${entityType}?`;
  const message =
    confirmMessage ||
    (isArchived
      ? `This will make the ${entityType} visible in standard views again.`
      : `Archiving will hide this ${entityType} from standard views without deleting it.`);

  const button = (
    <Button
      color={isArchived ? "primary" : "inherit"}
      onClick={() => setConfirmOpen(true)}
      disabled={isUpdating || buttonProps.disabled}
      startIcon={showIcon ? isArchived ? <UnarchiveIcon /> : <ArchiveIcon /> : undefined}
      {...buttonProps}
    >
      {children || defaultChildren}
    </Button>
  );

  return (
    <>
      {tooltipTitle !== undefined ? <Tooltip title={tooltipTitle || defaultTooltip}>{button}</Tooltip> : button}

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleToggleArchive} variant="contained">
            {actionText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
