import React from "react";
import ReactDOM from "react-dom/client";
import { Navigate, RouterProvider, createBrowserRouter, redirect } from "react-router-dom";
import App, { Main } from "./App";
import { Apis } from "./api/apis";
import { Session } from "./api/sessionstore";
import { environmentType, localBaseUrl, productionBaseUrl, sharedApiConfig } from "./config";
import "./index.css";
import ErrorPage from "./pages/ErrorPage";
import OverviewPage from "./pages/OverviewPage";
import { DatasetDetailsPage } from "./pages/dataset/DatasetDetailsPage";
import DatasetsPage from "./pages/dataset/DatasetPage";
import { ExplorationDetailsPage } from "./pages/explorations/ExplorationsDetailsPage";
import ExplorationPage from "./pages/explorations/ExplorationsPage";
import { ProcessorDetailsPage } from "./pages/processor/ProcessorDetailsPage";
import ProcessorsPage from "./pages/processor/ProcessorsPage";
import ProjectPage from "./pages/project/ProjectPage";
import { PromptDetailsPage } from "./pages/prompt/PromptDetailsPage";
import PromptsPage from "./pages/prompt/PromptsPage";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react";
import { Environments } from "./state/applicationconfig";
import LoginPage from "./pages/LoginPage";
import { PrivateRoute } from "./components/routes/PrivateRoute";
import { createAuthenticatedLoader } from "./routes/loader";

if (environmentType === Environments.production) {
  Sentry.init({
    dsn: "https://0291c0cecfce2c7dae418adcea8af77e@o4508682318905344.ingest.de.sentry.io/4508812540444752",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [localBaseUrl, productionBaseUrl],
    replaysSessionSampleRate: environmentType === Environments.production ? 0.1 : 1.0, // we send 10% of the production sessions to sentry, and all development sessions
    replaysOnErrorSampleRate: 1.0,
  });
}

Session.createShared(Session.getDefaultStorageProvider());
Apis.createShared(sharedApiConfig);

const authenticatedLoader = createAuthenticatedLoader("/login");

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/",
        id: "projects",
        element: (
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        ),
        loader: async ({ request }) => {
          return authenticatedLoader(async () => {
            const sp = new URL(request.url);
            const showArchived = !!sp.searchParams.get("archived");
            const projects = await Apis.shared().metadata.fetchProjects(showArchived);
            const archivedCount = await Apis.shared().metadata.fetchArchivedProjectsCount();
            return { projects, archivedCount };
          });
        },
        errorElement: <ErrorPage />,
        children: [
          {
            path: "overview",
            element: <OverviewPage />,
          },
          {
            path: "project/:projectId",
            id: "project",
            loader: async ({ params }) => {
              return authenticatedLoader(async () => {
                return await Apis.shared().project.fetchProject(params.projectId!);
              });
            },
            children: [
              {
                element: <ProjectPage />,
                index: true,
                loader: async ({ params }) =>
                  authenticatedLoader(async () => await Apis.shared().project.fetchProject(params.projectId!)),
              },
              {
                path: "dataset",
                element: <DatasetsPage />,
                loader: async ({ params, request }) =>
                  authenticatedLoader(async () => {
                    const sp = new URL(request.url);
                    const showArchived = !!sp.searchParams.get("archived");
                    const [datasets, archivedCount] = await Promise.all([
                      Apis.shared().project.fetchDatasets(
                        params.projectId!,
                        undefined,
                        showArchived ? "archived" : "active"
                      ),
                      Apis.shared().project.fetchArchivedDatasetsCount(params.projectId!),
                    ]);
                    return { datasets, archivedCount };
                  }),
              },
              {
                path: "dataset/:datasetId",
                element: <DatasetDetailsPage />,
                loader: async ({ params, request }) =>
                  authenticatedLoader(async () => {
                    const labels = await Apis.shared().project.fetchDatasetLabels(params.projectId!, params.datasetId!);
                    const processors = await Apis.shared().project.fetchProcessors(params.projectId!);

                    const datasets = await Apis.shared().project.fetchDatasets(
                      params.projectId!,
                      params.datasetId!,
                      "all"
                    );

                    if (datasets.empty) {
                      console.warn("Dataset not found", params.projectId!, params.datasetId!);
                      return redirect("/project/" + params.projectId! + "/dataset");
                    }

                    return [datasets, labels, processors];
                  }),
              },
              {
                path: "processor",
                element: <ProcessorsPage />,
                loader: async ({ params, request }) =>
                  authenticatedLoader(async () => {
                    const sp = new URL(request.url);
                    const showArchived = !!sp.searchParams.get("archived");
                    const [processors, archivedCount] = await Promise.all([
                      Apis.shared().project.fetchProcessors(params.projectId!, showArchived),
                      Apis.shared().project.fetchArchivedProcessorsCount(params.projectId!),
                    ]);
                    return { processors, archivedCount };
                  }),
              },
              {
                path: "processor/:processorId",
                element: <ProcessorDetailsPage />,
                loader: async ({ params }) =>
                  authenticatedLoader(async () => {
                    return await Apis.shared().project.fetchProcessor(params.projectId!, params.processorId!);
                  }),
              },
              {
                path: "exploration",
                element: <ExplorationPage />,
                loader: async ({ params, request }) =>
                  authenticatedLoader(async () => {
                    const sp = new URL(request.url);
                    const showArchived = !!sp.searchParams.get("archived");
                    const [data, archivedCount] = await Promise.all([
                      Apis.shared().project.fetchExplorations(params.projectId!, showArchived),
                      Apis.shared().project.fetchArchivedExplorationsCount(params.projectId!),
                    ]);
                    return { data, archivedCount };
                  }),
              },
              {
                path: "exploration/:explorationId",
                element: <ExplorationDetailsPage />,
                loader: async ({ params }) =>
                  authenticatedLoader(async () => {
                    const parallelQueries: any[] = [];
                    const exploration = await Apis.shared().project.fetchExploration(
                      params.projectId!,
                      params.explorationId!
                    );
                    if (!exploration.dataset) {
                      return { exploration, projectId: params.projectId! };
                    }
                    const datasetRootId = exploration.dataset?.root_id ?? exploration.dataset?.id;
                    parallelQueries.push(
                      Apis.shared().data.queryStatistics(
                        params.projectId!,
                        exploration.dataset_id,
                        exploration.dataset!.filename!
                      ),
                      Apis.shared().project.fetchDatasetLabels(params.projectId!, datasetRootId!),
                      Apis.shared().project.fetchPrompts(params.projectId!)
                    );
                    const results = await Promise.all(parallelQueries);
                    const [statistics, labels, prompts] = results;
                    return { exploration, statistics, labels, prompts, projectId: params.projectId! };
                  }),
              },
              {
                path: "prompt",
                element: <PromptsPage />,
                loader: async ({ params, request }) =>
                  authenticatedLoader(async () => {
                    const sp = new URL(request.url);
                    const type = sp.searchParams.get("type") ?? undefined;
                    const showArchived = !!sp.searchParams.get("archived");
                    const [prompts, archivedCount] = await Promise.all([
                      Apis.shared().project.fetchPrompts(params.projectId!, type, showArchived),
                      Apis.shared().project.fetchArchivedPromptsCount(params.projectId!),
                    ]);
                    return { prompts, archivedCount };
                  }),
              },
              {
                path: "prompt/:promptId",
                element: <PromptDetailsPage />,
                loader: async ({ params }) =>
                  authenticatedLoader(async () => {
                    const exploration = await Apis.shared().project.fetchPrompt(params.projectId!, params.promptId!);
                    return exploration;
                  }),
              },
            ],
          },
          {
            path: "",
            element: <Navigate to="/overview" />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
