import { Alert, Box, CssBaseline, Snackbar, SnackbarCloseReason } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Outlet, useNavigation, useOutlet } from "react-router-dom";
import { Apis } from "./api/apis";
import { ApiLoadingState, FetchStatus } from "./api/types";
import "./App.css";
import NavigationFramework from "./components/common/NavigationFramework";
import { ApplicationThemeProvider } from "./components/theme";
import { AuthProvider } from "./contexts/AuthContext";
import {
  ApplicationActionTypes,
  ApplicationContextProvider,
  useApplication,
  useApplicationDispatch,
} from "./state/applicationstate";
import { ProjectContextProvider } from "./state/projectstate";

export function Main() {
  const applicationDispatch = useApplicationDispatch();
  const outlet = useOutlet();
  const { state: navState } = useNavigation();
  const { notifications } = useApplication();
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.idle);

  const onCloseNotification = useCallback(
    (id: string, event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === "clickaway") {
        return;
      }
      applicationDispatch({
        type: ApplicationActionTypes.removeNotification,
        id,
      });
    },
    [applicationDispatch]
  );

  useEffect(() => {
    Apis.shared().globalStatusFn = (status: FetchStatus) => {
      setFetchStatus(status);
    };
  }, []);

  const note = notifications && notifications.length > 0 ? notifications[0] : undefined;

  return (
    <Box sx={{ height: "100%" }}>
      <NavigationFramework>
        <ProjectContextProvider>
          <CssBaseline />
          {outlet}
          {/* <ModalLoader active={navState === "loading" || fetchStatus !== FetchStatus.idle} /> */}
        </ProjectContextProvider>
      </NavigationFramework>
      {note && (
        <Snackbar
          open={true}
          onClose={(e, reason) => {
            onCloseNotification(note.id!, e, reason);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={note.type} variant="filled" onClose={(e) => onCloseNotification(note.id!, e)}>
            {note.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

const ENABLE_GLOBAL_LOADER = false; // TODO: Remove global loaders completely or change so that we never should in-your-face loaders based on this property

export function ThemedApp() {
  const { uiTheme } = useApplication();
  const applicationDispatch = useApplicationDispatch();

  if (ENABLE_GLOBAL_LOADER) {
    Apis.shared().globalStatusFn = (status: FetchStatus) => {
      if (status === FetchStatus.started) {
        applicationDispatch({
          type: ApplicationActionTypes.setLoading,
          state: ApiLoadingState.loading,
        });
      } else if (status === FetchStatus.idle) {
        applicationDispatch({
          type: ApplicationActionTypes.setLoading,
          state: ApiLoadingState.notLoading,
        });
      }
    };
  }

  return (
    <ApplicationThemeProvider uiTheme={uiTheme}>
      <Box sx={{ height: "100%" }}>
        <CssBaseline />
        <Outlet />
      </Box>
    </ApplicationThemeProvider>
  );
}

export function App() {
  return (
    <AuthProvider>
      <ApplicationContextProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemedApp />
        </LocalizationProvider>
      </ApplicationContextProvider>
    </AuthProvider>
  );
}

export default App;
