import {
  ApiId,
  CollectDatasetImport,
  CollectProjectStats,
  DatasetModel,
  DataHeatmapResult,
  DataSamplesResult,
  DataStatisticsResult,
  ExplorationModel,
  ExplorationSelection,
  ExplorationFilter,
  JobInfoModel,
  LabelMappings,
  LangTextAggregateOptions,
  LangTextSummmary,
  LangTextSummaryAvailableOptions,
  LangTextSummaryStatistics,
  LogEntryModel,
  OrganizationInfo,
  ProcessorModel,
  ProjectCreateModel,
  ProjectModel,
  PromptModel,
  RegistryImage,
  AuthenticateResponse,
  ResourceLookupResult,
} from "./apimodels";

export type SignedLinkFn = (url: string) => Promise<string>;

export type FetchFn = (input: string, init?: RequestInit | undefined) => Promise<Response>;

export enum FetchStatus {
  idle,
  started,
  authenticating,
  fetching,
  refreshing,
}

export class NotAuthenticatedError extends Error {
  private _url: string | undefined;
  private _apiError: ApiError | undefined;

  constructor(message: string, url?: string, apiError?: ApiError) {
    super(message);
    this._url = url;
    this._apiError = apiError;
  }

  public get url(): string | undefined {
    return this._url;
  }

  public get apiError(): ApiError | undefined {
    return this._apiError;
  }
}

export class ApiError extends Error {
  protected _statusCode: number | undefined = undefined;
  protected _details: Record<string, any> | undefined = undefined;

  constructor(statusCode?: number, details?: Record<string, any>) {
    super();
    this._statusCode = statusCode;
    this._details = details;
  }

  public get statusCode(): number | undefined {
    return this._statusCode;
  }

  public get details(): Record<string, any> | undefined {
    return this._details;
  }
}

export class ApiNotAuthorizedError extends ApiError {}

export class ApiGeneralError extends ApiError {}

export class ApiNotFoundError extends ApiError {}

export class ApiNoContentError extends ApiError {}

export enum ApiLoadingState {
  notLoading,
  loading,
  error,
}

export interface ApiBatchResult<T> {
  empty: boolean;
  count: number;
  items: T[];
}

export interface IAuthApi {
  login(username: string, password: string): Promise<{ token: string; refreshToken: string }>;
  googleLoginUrl(targetPath?: string): string;
  tokenRefresh(
    organizationId: number | undefined,
    refreshToken: string,
    projectId?: number
  ): Promise<{ token: string; refreshToken: string }>;
  listOrganizations(token: string): Promise<ApiBatchResult<OrganizationInfo>>;
  authenticate(token: string): Promise<AuthenticateResponse>;
}

export interface IMetadataApi {
  lookupResourceOrganization(type: string, id: number): Promise<ResourceLookupResult>;
  fetchProjects(showArchived?: boolean): Promise<ProjectModel[]>;
  createProject(data: ProjectCreateModel, defaultContent?: boolean): Promise<ProjectModel>;
  updateProject(projectId: ApiId, name?: string, subtitle?: string, description?: string): Promise<ProjectModel>;
  deleteProject(projectId: ApiId, hard?: boolean): Promise<void>;
  toggleArchiveProject(projectId: ApiId): Promise<void>;
  fetchArchivedProjectsCount(): Promise<number>;
}

export interface IDataApi {
  queryStatistics(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    filter?: ExplorationFilter,
    includeLabels?: boolean,
    includeThemeTopicFrequency?: boolean,
    includeWordsStats?: boolean
  ): Promise<DataStatisticsResult>;
  queryHeatmap(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    xAxis: [string, string],
    yAxis: [string, string],
    filter?: ExplorationFilter,
    xSort?: Record<string, number>,
    ySort?: Record<string, number>
  ): Promise<DataHeatmapResult>;
  querySamples(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    start: number,
    count: number,
    filter?: ExplorationFilter,
    allColumns?: boolean
  ): Promise<DataSamplesResult>;
}

export interface ILangApi {
  summaryOptions(): Promise<LangTextSummaryAvailableOptions>;
  querySummaryStatistics(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    ids: string[],
    options: LangTextAggregateOptions,
    numOutputTokens: number
  ): Promise<LangTextSummaryStatistics>;
  generateSummary(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    ids: string[],
    options: LangTextAggregateOptions
  ): Promise<LangTextSummmary>;
}

export interface IProjectApi {
  fetchProject(id: ApiId): Promise<ProjectModel>;

  fetchDataset(projectId: ApiId, datasetId: ApiId): Promise<DatasetModel>;
  fetchDatasetLabels(projectId: ApiId, datasetId: ApiId): Promise<LabelMappings | undefined>;
  fetchDatasets(
    projectId: ApiId,
    datasetId?: ApiId,
    filter?: "active" | "archived" | "all"
  ): Promise<ApiBatchResult<DatasetModel>>;

  createDataset(projectId: ApiId, data: DatasetModel): Promise<DatasetModel>;
  createProcessedDataset(
    projectId: ApiId,
    parentId: ApiId,
    name: string | undefined,
    description: string | undefined,
    processorId: ApiId,
    processorParams: Record<string, any>,
    imageTag: string,
    imageName: string
  ): Promise<DatasetModel>;
  updateDataset(projectId: ApiId, datasetId: ApiId, name?: string, description?: string): Promise<DatasetModel>;
  deleteDataset(projectId: ApiId, datasetId: ApiId, hard?: boolean): Promise<void>;
  toggleArchiveDataset(projectId: ApiId, datasetId: ApiId): Promise<void>;
  uploadDatasetContent(projectId: ApiId, datasetId: ApiId, filename: string, data: ArrayBuffer): Promise<Response>;
  uploadDatasetLabelsContent(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    data: ArrayBuffer
  ): Promise<Response>;
  uploadDatasetArtifact(projectId: ApiId, datasetId: ApiId, filename: string, data: ArrayBuffer): Promise<Response>;
  datasetDownloadUrl(projectId: ApiId, datasetId: ApiId): string;
  datasetLabelsDownloadUrl(projectId: ApiId, datasetId: ApiId): string;
  datasetArtifactDownloadUrl(projectId: ApiId, datasetId: ApiId, filename: string): string;

  fetchJobs(
    projectId: ApiId,
    completed: boolean,
    pending: boolean,
    relatedEntity?: string,
    relatedIds?: ApiId[],
    jobIds?: ApiId[]
  ): Promise<JobInfoModel[]>;
  fetchJob(projectId: ApiId, jobId: ApiId): Promise<JobInfoModel>;
  fetchJobLogs(projectId: ApiId, jobId: ApiId): Promise<LogEntryModel[]>;

  fetchProcessor(projectId: ApiId, processorId: ApiId): Promise<ProcessorModel>;
  fetchProcessors(projectId: ApiId, showArchived?: boolean): Promise<ApiBatchResult<ProcessorModel>>;
  createProcessor(projectId: ApiId, data: ProcessorModel): Promise<ProcessorModel>;
  updateProcessor(projectId: ApiId, processorId: ApiId, name?: string, description?: string): Promise<ProcessorModel>;
  deleteProcessor(projectId: ApiId, processorId: ApiId, hard?: boolean): Promise<void>;
  uploadProcessorContent(projectId: ApiId, processorId: ApiId, filename: string, data: ArrayBuffer): Promise<Response>;
  processorDownloadUrl(projectId: ApiId, processorId: ApiId): string;
  toggleArchiveProcessor(projectId: ApiId, processorId: ApiId): Promise<void>;
  fetchProcessorRegistryImages(projectId: ApiId, imageName?: string, latestOnly?: boolean): Promise<RegistryImage[]>;

  // Download URLs
  createDatasetDownloadUrl(projectId: ApiId, datasetId: ApiId): Promise<{ url: string; expiresIn: number }>;
  createDatasetLabelsDownloadUrl(projectId: ApiId, datasetId: ApiId): Promise<{ url: string; expiresIn: number }>;
  createDatasetArtifactDownloadUrl(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string
  ): Promise<{ url: string; expiresIn: number }>;
  createProcessorDownloadUrl(projectId: ApiId, processorId: ApiId): Promise<{ url: string; expiresIn: number }>;

  // Explorations
  fetchExploration(projectId: ApiId, explorationId: ApiId): Promise<ExplorationModel>;
  fetchExplorations(projectId: ApiId, showArchived?: boolean): Promise<ApiBatchResult<ExplorationModel>>;
  createExploration(projectId: ApiId, data: ExplorationModel): Promise<ExplorationModel>;
  deleteExploration(projectId: ApiId, explorationId: ApiId, hard?: boolean): Promise<void>;
  updateExploration(
    projectId: ApiId,
    explorationId: ApiId,
    name?: string,
    description?: string
  ): Promise<ExplorationModel>;
  createExplorationSelection(
    projectId: ApiId,
    explorationId: ApiId,
    selection: ExplorationSelection
  ): Promise<ExplorationSelection>;
  saveExplorationSelection(
    projectId: ApiId,
    explorationId: ApiId,
    selectionId: ApiId,
    selection: ExplorationSelection
  ): Promise<ExplorationSelection>;
  deleteExplorationSelection(projectId: ApiId, explorationId: ApiId, selectionId: ApiId): Promise<void>;
  toggleArchiveExploration(projectId: ApiId, explorationId: ApiId): Promise<void>;

  fetchPrompts(projectId: ApiId, type?: string, showArchived?: boolean): Promise<ApiBatchResult<PromptModel>>;
  fetchPrompt(projectId: ApiId, promptId: ApiId): Promise<PromptModel>;
  createPrompt(projectId: ApiId, data: PromptModel): Promise<PromptModel>;
  updatePrompt(projectId: ApiId, promptId: ApiId, data: PromptModel): Promise<PromptModel>;
  deletePrompt(projectId: ApiId, promptId: number, hard?: boolean): Promise<void>;
  toggleArchivePrompt(projectId: ApiId, promptId: ApiId): Promise<void>;

  fetchCollectProjects(projectId: ApiId): Promise<CollectProjectStats[]>;
  importCollectDataset(projectId: ApiId, data: CollectDatasetImport): Promise<DatasetModel>;
  fetchCollectProjectStatistics(
    projectId: ApiId,
    data: {
      project_id: string;
      only_complete: boolean;
      start_time?: string;
      end_time?: string;
    }
  ): Promise<any>;

  fetchCollectProjectConversations(
    projectId: ApiId,
    data: {
      project_id: string;
      only_complete: boolean;
      start_time?: string;
      end_time?: string;
      limit?: number;
      offset?: number;
    }
  ): Promise<any>;

  deleteCollectConversations(
    projectId: ApiId,
    data: {
      project_id: string;
      conversation_ids: string[];
    }
  ): Promise<void>;

  restoreAllCollectConversations(
    projectId: ApiId,
    data: {
      project_id: string;
    }
  ): Promise<any>;

  fetchArchivedDatasetsCount(projectId: ApiId): Promise<number>;
  fetchArchivedProcessorsCount(projectId: ApiId): Promise<number>;
  fetchArchivedPromptsCount(projectId: ApiId): Promise<number>;
  fetchArchivedExplorationsCount(projectId: ApiId): Promise<number>;
}
