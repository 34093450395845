import { redirect } from "react-router-dom";
import { Session } from "../api/sessionstore";

export const createAuthenticatedLoader = (redirectTo: string) => {
  const authenticatedLoader = async <T>(loaderFn: () => Promise<T>): Promise<T | Response> => {
    // Check if user is authenticated by checking for a token
    const deepLink = window.location.pathname + window.location.search;
    console.log("🔒 🔗 authenticatedLoader::DeepLink:", deepLink, "stored:", Session.shared().deepLink);

    if (!Session.shared().token) {
      // Redirect to login if not authenticated
      console.log("🔒 🚫 Unauthorized access attempt, redirecting to login");
      if (window.location.pathname !== redirectTo && window.location.pathname !== "/__/auth/handler") {
        // Store the full URL including search params
        Session.shared().deepLink = deepLink;
        console.log("🔒 💾 Saved deep link:", Session.shared().deepLink);
      } else {
        Session.shared().deepLink = null;
      }
      return redirect(redirectTo);
    }

    // If authenticated, proceed with the original loader
    try {
      const result = await loaderFn();

      // After successful authentication and data loading, check if we have a stored deep link
      // that's different from the current path
      const storedDeepLink = Session.shared().deepLink;
      if (storedDeepLink && storedDeepLink !== deepLink) {
        console.log("🔒 🔄 Redirecting to stored deep link:", storedDeepLink);
        Session.shared().deepLink = null; // Clear the deep link after using it
        return redirect(storedDeepLink);
      }

      return result;
    } catch (error: unknown) {
      console.log("🔒 ⚠️ Authentication error in loader", error);
      // If the error is due to authentication (e.g. token expired), redirect to login
      if (
        typeof error === "object" &&
        error !== null &&
        "status" in error &&
        (error.status === 401 || error.status === 403)
      ) {
        console.log("🔒 ⚠️ Authentication error in loader, redirecting to login");
        // Save deep link in case of auth errors too
        if (window.location.pathname !== redirectTo && window.location.pathname !== "/__/auth/handler") {
          Session.shared().deepLink = deepLink;
          console.log("🔒 💾 Saved deep link from auth error:", Session.shared().deepLink);
        }
        return redirect(redirectTo);
      }
      throw error; // Let other errors propagate
    }
  };
  return authenticatedLoader;
};
