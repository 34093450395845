import React from "react";
import { Box, MenuItem, Stack } from "@mui/material";
import { FormTextField } from "../../common/form/FormTextField";
import { FormCheckboxGroup } from "../../common/form/FormCheckboxGroup";
import { FormSelect } from "../../common/form/FormSelect";
import { CollectProjectStats } from "../../../api/apimodels";

interface StepDatasetConfigurationProps {
  selectedProject?: CollectProjectStats;
}

export const StepDatasetConfiguration: React.FC<StepDatasetConfigurationProps> = ({ selectedProject }) => {
  return (
    <>
      <Box>
        <Stack spacing={3} sx={{ p: 2, borderRadius: 1 }}>
          <FormTextField
            name="name"
            fullWidth
            label="Dataset Name"
            variant="outlined"
            InputProps={{ sx: { borderRadius: 1 } }}
          />
          <FormTextField
            name="description"
            fullWidth
            label="Dataset Description"
            variant="outlined"
            multiline
            rows={2}
            InputProps={{ sx: { borderRadius: 1 } }}
          />
        </Stack>
      </Box>
      {selectedProject?.text_questions && Object.keys(selectedProject.text_questions).length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ p: 2, borderRadius: 1 }}>
            <FormCheckboxGroup
              name="selectedQuestions"
              options={Object.entries(selectedProject.text_questions).map(([id, label]) => ({ id, label }))}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Box sx={{ p: 2, borderRadius: 1 }}>
          <FormSelect name="textTargetColumn" label="Text Target Column" variant="outlined" fullWidth sx={{ mb: 2 }}>
            <MenuItem value="original_text">original_text</MenuItem>
            <MenuItem value="translated_text">translated_text</MenuItem>
            <MenuItem value="text">text</MenuItem>
          </FormSelect>
        </Box>
      </Box>
    </>
  );
};
