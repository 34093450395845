import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  Alert,
} from "@mui/material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QuoteIcon from "@mui/icons-material/FormatQuote";
import DialogIcon from "@mui/icons-material/Forum";
import RagIcon from "@mui/icons-material/EmojiObjects";
import { useState } from "react";
import { AggregationDialog } from "./tools/AggregationDialog";
import { SampleStateSelected } from "./DataState";
import { PromptType } from "../../prompt/PromptTypes";

const ToolsList = styled(List)(({ theme }) => ({
  "& .MuiListItemButton-root": {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
}));

const textSx: any = { fontSize: "95%" };

export type SelectedItems = Record<string, SampleStateSelected>;

type Props = {
  selectedItems: SelectedItems;
  project_id: number;
  dataset_id: number;
  filename: string;
};

export function ToolsPanel({ selectedItems, project_id, dataset_id, filename }: Props) {
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);
  const [dialogDialogOpen, setDialogDialogOpen] = useState(false);
  const [transformDialogOpen, setTransformDialogOpen] = useState(false);

  // FIXME: Provide a proper typed format for sample selections, TextID should be easy to extract

  const selectedTextIds = Object.keys(selectedItems).filter((k) => selectedItems[k].selected);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="subtitle1" sx={{ textAlign: "center", mb: 1 }}>
        AI Tools
      </Typography>
      <ToolsList>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setSummaryDialogOpen(true)} disabled={selectedTextIds.length === 0}>
            <ListItemIcon>
              <SummarizeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Summary" primaryTypographyProps={textSx} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setQuoteDialogOpen(true)} disabled={selectedTextIds.length === 0}>
            <ListItemIcon>
              <QuoteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Quote" primaryTypographyProps={textSx} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setDialogDialogOpen(true)} disabled={selectedTextIds.length === 0}>
            <ListItemIcon>
              <DialogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Dialog" primaryTypographyProps={textSx} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setTransformDialogOpen(true)} disabled={selectedTextIds.length === 0}>
            <ListItemIcon>
              <DialogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Rewrite" primaryTypographyProps={textSx} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton disabled>
            <ListItemIcon>
              <RagIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Rag" primaryTypographyProps={textSx} />
          </ListItemButton>
        </ListItem>
      </ToolsList>
      {summaryDialogOpen && (
        <AggregationDialog
          type={PromptType.manyToOneSummary}
          open={summaryDialogOpen}
          onClose={() => {
            setSummaryDialogOpen(false);
          }}
          ids={selectedTextIds}
          {...{ project_id, dataset_id, filename }}
        />
      )}
      {quoteDialogOpen && (
        <AggregationDialog
          type={PromptType.manyToOneQuote}
          open={quoteDialogOpen}
          onClose={() => {
            setQuoteDialogOpen(false);
          }}
          ids={selectedTextIds}
          {...{ project_id, dataset_id, filename }}
        />
      )}
      {dialogDialogOpen && (
        <AggregationDialog
          type={PromptType.manyToOneDialog}
          open={dialogDialogOpen}
          onClose={() => {
            setDialogDialogOpen(false);
          }}
          ids={selectedTextIds}
          {...{ project_id, dataset_id, filename }}
        />
      )}
      {transformDialogOpen && (
        <AggregationDialog
          type={PromptType.oneToOne}
          open={transformDialogOpen}
          onClose={() => {
            setTransformDialogOpen(false);
          }}
          ids={selectedTextIds}
          {...{ project_id, dataset_id, filename }}
        />
      )}
    </Box>
  );
}
