import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { CollectProjectStats, DatasetModel, ConversationSummaryResponse } from "../../../api/apimodels";
import { Apis } from "../../../api/apis";
import { StepSelectProject } from "./StepSelectProject";
import { StepFiltering } from "./StepFiltering";
import { StepConversations } from "./StepConversations";
import { StepStatistics } from "./StepStatistics";
import { StepDatasetConfiguration } from "./StepDatasetConfiguration";

export const datasetFromCollectSchema = z.object({
  name: z.string().min(1, "Name is required").describe("Dataset Name"),
  description: z.string().describe("Dataset Description"),
  onlyComplete: z.boolean().describe("Only completed conversations"),
  textTargetColumn: z.enum(["text", "original_text", "translated_text"]).describe("Text Target Column"),
  selectedQuestions: z.array(z.string()).min(1, "Please select at least one question").describe("Questions"),
  collectProjectId: z.string().min(1, "Please select a project").describe("Project"),
  startTime: z.coerce.date().optional().describe("Start Time"),
  endTime: z.coerce.date().optional().describe("End Time"),
});
export type DatasetFromCollectFormData = z.infer<typeof datasetFromCollectSchema>;

interface DatasetFromCollectProps {
  projectId: number;
  open: boolean;
  onClose: () => void;
  onSuccess: (dataset: DatasetModel) => void;
}


const defaultValues: DatasetFromCollectFormData = {
  name: "",
  description: "",
  textTargetColumn: "original_text",
  selectedQuestions: [],
  collectProjectId: "",
  onlyComplete: true,
};

export const CollectImportDialog: React.FC<DatasetFromCollectProps> = ({ projectId, open, onClose, onSuccess }) => {
  const [collectProjects, setCollectProjects] = useState<CollectProjectStats[]>([]);
  const [loading, setLoading] = useState(false);
  const [projectLoadError, setProjectLoadError] = useState<string | null>(null);
  const [importing, setImporting] = useState(false);
  const [deletingConversations, setDeletingConversations] = useState(false);
  const [deletingConversationsError, setDeletingConversationsError] = useState<string | null>(null);
  const [restoringConversations, setRestoringConversations] = useState(false);
  const [restoringConversationsError, setRestoringConversationsError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [fetchingStats, setFetchingStats] = useState(false);
  const [conversations, setConversations] = useState<ConversationSummaryResponse | null>(null);
  const [fetchingConversations, setFetchingConversations] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const steps = ["Select Project", "Filtering", "Conversations", "Statistics", "Dataset Configuration"];

  const formMethods = useForm<DatasetFromCollectFormData>({
    resolver: zodResolver(datasetFromCollectSchema),
    defaultValues,
    mode: "onChange",
  });
  const { watch, setValue } = formMethods;
  const collectProjectId = watch("collectProjectId");
  const [selectedProject, setSelectedProject] = useState<CollectProjectStats | undefined>(undefined);

  const filteredProjects = useMemo(() => {
    if (!searchQuery.trim()) return collectProjects;
    const query = searchQuery.toLowerCase().trim();
    return collectProjects.filter((project) => {
      const projectName = (project.title || "").toLowerCase();
      const orgName = (project.organization_name || "").toLowerCase();
      return projectName.includes(query) || orgName.includes(query) || project.project_id.toString().includes(query);
    });
  }, [collectProjects, searchQuery]);

  const handleSearchClear = () => setSearchQuery("");

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      setProjectLoadError(null);
      try {
        const projects = await Apis.shared().project.fetchCollectProjects(projectId);
        setCollectProjects(projects);
      } catch (e: any) {
        const errorMessage = e?.response?.data?.detail || e.message || "Unknown error";
        setProjectLoadError(`Failed to load projects: ${errorMessage}`);
        console.error(e);
      }
      setLoading(false);
    };
    fetchProjects();
  }, [projectId]);

  const handleProjectSelect = async (collectProjectId: string, initial: boolean = false) => {
    formMethods.clearErrors();
    setSuccessMessage(null);
    formMethods.setValue("collectProjectId", collectProjectId);

    const fetchParams = {
      project_id: collectProjectId,
      only_complete: initial ? false : formMethods.getValues("onlyComplete"),
      start_time: initial ? undefined : formMethods.getValues("startTime")?.toISOString(),
      end_time: initial ? undefined : formMethods.getValues("endTime")?.toISOString(),
    };

    let stats: CollectProjectStats;

    try {
      stats = await Apis.shared().project.fetchCollectProjectStatistics(projectId, fetchParams);
      setSelectedProject(stats);
    } catch (error: any) {
      formMethods.setError("root", {
        type: "statistics",
        message: error?.details?.detail || error.message || "Failed to fetch statistics",
      });
      return;
    }

    const name = [
      stats?.organization_name,
      stats?.title,
      stats?.start_date && moment(stats.start_date).format("YYYY-MM-DD"),
      `(${collectProjectId})`,
    ]
      .filter(Boolean)
      .join(" - ");

    formMethods.reset({
      ...defaultValues,
      name,
      description: stats?.description || "",
      collectProjectId,
      selectedQuestions: stats?.text_questions ? Object.keys(stats.text_questions) : [],
      startTime: stats?.start_date ? new Date(stats.start_date) : undefined,
      endTime: stats?.end_date ? new Date(stats.end_date) : undefined,
    });

    formMethods.trigger();
  };

  const fetchConversations = async (offset: number = 0) => {
    if (!selectedProject) return;
    setSuccessMessage(null);
    setFetchingConversations(true);
    try {
      const convData = await Apis.shared().project.fetchCollectProjectConversations(projectId, {
        project_id: selectedProject.project_id,
        only_complete: formMethods.getValues("onlyComplete"),
        start_time: formMethods.getValues("startTime")?.toISOString(),
        end_time: formMethods.getValues("endTime")?.toISOString(),
        limit: 10,
        offset,
      });
      setConversations(convData);
    } catch (error: any) {
      console.error("Failed to fetch conversations", error);
      setConversations(null);
    } finally {
      setFetchingConversations(false);
    }
  };

  const handleNext = useCallback(async () => {
    if (activeStep === 0) {
      await handleProjectSelect(collectProjectId, true);
    } else if (activeStep === 1) {
      await fetchConversations();
    } else if (activeStep === 2) {
      await handleProjectSelect(collectProjectId, false);
    }
    setActiveStep((prev) => prev + 1);
  }, [activeStep, selectedProject, formMethods, fetchConversations]);

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleClose = () => {
    setActiveStep(0);
    formMethods.reset();
    onClose();
  };

  const onSubmit = async (data: DatasetFromCollectFormData) => {
    if (!selectedProject) return;
    setSuccessMessage(null);
    setImporting(true);
    try {
      const dataset = await Apis.shared().project.importCollectDataset(projectId, {
        project_id: data.collectProjectId,
        name: data.name.trim(),
        only_complete: data.onlyComplete,
        description: data.description.trim(),
        text_target_column: data.textTargetColumn,
        text_question_template_ids: data.selectedQuestions,
        start_time: data.startTime?.toISOString(),
        end_time: data.endTime?.toISOString(),
      });
      setSuccessMessage(`Dataset ${dataset.name} imported successfully`);
      onSuccess(dataset);
      handleClose();
    } catch (error: any) {
      if (error.json) {
        const json = await error.json();
        formMethods.setError("root", {
          type: "submit",
          message: json?.details?.detail || json?.detail || json?.message || "Failed to import dataset",
        });
      } else {
        formMethods.setError("root", {
          type: "submit",
          message: "Failed to import dataset",
        });
      }
    } finally {
      setImporting(false);
    }
  };

  const handleDeleteConversations = async (conversationIds: string[]) => {
    if (!selectedProject) return;
    setDeletingConversations(true);
    setDeletingConversationsError(null);
    try {
      await Apis.shared().project.deleteCollectConversations(projectId, {
        project_id: selectedProject.project_id,
        conversation_ids: conversationIds,
      });
      await fetchConversations(conversations?.offset || 0);
    } catch (error: any) {
      console.error("Failed to delete conversations", error);
      setDeletingConversationsError(error?.details?.detail || error.message || "Failed to delete conversations");
    } finally {
      setDeletingConversations(false);
    }
  };

  const handleRestoreAllConversations = async () => {
    if (!selectedProject) return;
    setRestoringConversations(true);
    setRestoringConversationsError(null);
    try {
      const result = await Apis.shared().project.restoreAllCollectConversations(
        projectId, // analysis platform project id, numeric
        {
          project_id: selectedProject.project_id, // collect project id, string
        },
      );
      await fetchConversations(conversations?.offset || 0);
      if (result.restored_count > 0) {
        setSuccessMessage(`Successfully restored ${result.restored_count} conversations`);
      } else {
        setSuccessMessage("No conversations were restored");
      }
    } catch (error: any) {
      console.error("Failed to restore conversations", error);
      setRestoringConversationsError(error?.details?.detail || error.message || "Failed to restore conversations");
    } finally {
      setRestoringConversations(false);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <StepSelectProject
            loading={loading}
            projectLoadError={projectLoadError}
            filteredProjects={filteredProjects}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearchClear={handleSearchClear}
            selectedProject={selectedProject}
            handleProjectSelect={(projectId) => handleProjectSelect(projectId, true)}
          />
        );
      case 1:
        return (
          <StepFiltering selectedProject={selectedProject} setValue={(name, value) => setValue(name as any, value)} />
        );
      case 2:
        return (
          <StepConversations
            fetchingConversations={fetchingConversations}
            conversations={conversations}
            fetchConversations={fetchConversations}
            handleDeleteConversations={handleDeleteConversations}
            handleRestoreAllConversations={handleRestoreAllConversations}
          />
        );
      case 3:
        if (!selectedProject) return <Alert severity="error">No project selected</Alert>;
        return (
          <StepStatistics
            fetchingStats={fetchingStats}
            statistics={selectedProject}
            formMethods={formMethods}
            fetchStatistics={() => handleProjectSelect(collectProjectId, false)}
          />
        );
      case 4:
        return <StepDatasetConfiguration selectedProject={selectedProject} />;
      default:
        return <Alert severity="error">Invalid step</Alert>;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" PaperProps={{ sx: { minHeight: "70vh" } }}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6">Import from Collect Service</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 2 }}>
            {formMethods.formState.errors.root && (
              <Alert severity="error">{formMethods.formState.errors.root.message}</Alert>
            )}
            {successMessage && <Alert severity="success">{successMessage}</Alert>}
            {deletingConversations && <Alert severity="info">Deleting conversations...</Alert>}
            {restoringConversations && <Alert severity="info">Restoring conversations...</Alert>}
            {deletingConversationsError && <Alert severity="error">{deletingConversationsError}</Alert>}
            {restoringConversationsError && <Alert severity="error">{restoringConversationsError}</Alert>}
            <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ mt: 3 }}>{renderStepContent(activeStep)}</Box>
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Box sx={{ flex: 1 }} />
        {activeStep > 0 && (
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
        )}
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} variant="contained" disabled={activeStep === 0 && !selectedProject}>
            Next
          </Button>
        ) : (
          <Button
            onClick={formMethods.handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            disabled={
              !formMethods.formState.isValid || importing || !selectedProject || formMethods.formState.isSubmitting
            }
          >
            {importing ? <CircularProgress size={24} /> : "Create Dataset"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
