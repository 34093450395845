import DatasetIcon from "@mui/icons-material/Dataset";
import ErrorIcon from "@mui/icons-material/Error";
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, styled, Typography, useTheme } from "@mui/material";
import { formatJsonDate } from "../../util/dateformat";
import { DateText } from "./DateText";

//
const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  "& .MuiListItemSecondaryAction-root": {
    right: 0,
  },
}));

type Props = {
  name: string;
  description: string;
  createdAt?: string;
  downloadUrl?: string | undefined | null;
  filename: string | undefined | null;
};

export default function DataHeaderItem(props: Props) {
  const { name, description, downloadUrl, filename, createdAt } = props;
  const theme = useTheme();
  const isError = downloadUrl && !filename;

  return (
    <CustomListItem>
      <ListItemAvatar>
        <Avatar sx={{ color: theme.palette.text.primary, background: isError ? theme.palette.grey[300] : undefined }}>
          {isError ? <ErrorIcon /> : <DatasetIcon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="subtitle1" fontWeight={600}>
            {name}
          </Typography>
        }
        secondary={
          <Box component="span">
            <Typography component="span" sx={{ display: "block" }}>
              {description}
              {!!createdAt && (
                <DateText>
                  Created: {formatJsonDate(createdAt)}, Filename: {filename}
                </DateText>
              )}
            </Typography>
            {isError && (
              <Typography component="span" color="error" sx={{ display: "block" }}>
                Data is missing. Dataset is unusable.{" "}
              </Typography>
            )}
          </Box>
        }
      />
    </CustomListItem>
  );
}
