import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlOwnProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { interpolateRgb } from "d3";
import { CustomChip, CustomChipBase, CustomChipTooltip } from "../../../../components/common/Tag";
import { StableColorMap } from "../../../../util/colormap";
import { ApiId, DataHeatmapResult, ExplorationFilter, LabelMappings, LabelValue } from "../../../../api/apimodels";
import AddIcon from "@mui/icons-material/Add";
import { Apis } from "../../../../api/apis";
import { useState } from "react";
import { arrayToDict, arrayToDictFn } from "../../../../util/dict";

const HeatmapCell = styled(CustomChipBase)(({ theme }) => ({
  borderRadius: 0,
  width: theme.spacing(3.5),
  lineHeight: theme.spacing(3.5),
  textAlign: "center",
  padding: 0,
}));

const Marker = styled(CustomChipBase)(({ theme }) => ({
  borderRadius: theme.spacing(3.5),
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  lineHeight: theme.spacing(3.5),
  textAlign: "center",
  padding: 0,
}));

type HeatmapDimensionOption = {
  id: string;
  type: string;
  propertyId: string;
  name: string;
};

type HeatmapDimensionSelectProps = {
  options: HeatmapDimensionOption[];
  sx?: SxProps<Theme>;
  value?: HeatmapDimensionOption;
  onChange: (option: HeatmapDimensionOption) => void;
  label: string;
  disabled?: boolean;
  variant?: FormControlOwnProps["variant"];
};

function HeatmapDimensionSelect(props: HeatmapDimensionSelectProps) {
  const { options, label, variant, value, disabled, sx, onChange } = props;
  function handleChange(e: SelectChangeEvent) {
    onChange(options.find((i) => i.id === (e.target.value as string))!);
  }
  return (
    <FormControl variant={variant ?? "filled"} fullWidth sx={sx}>
      <InputLabel id="hm-select-label">{label}</InputLabel>
      <Select
        labelId="hm-select-label"
        id="hm-select"
        value={value?.id}
        onChange={handleChange}
        disabled={disabled}
        label={label}
        fullWidth
      >
        {options.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function createHeatmapOptions(
  labelMappings: LabelMappings,
  includeThemes: boolean,
  includeTopics: boolean,
  labelNamePrefix: string = "Label: "
): HeatmapDimensionOption[] {
  const options: HeatmapDimensionOption[] = Object.entries(labelMappings)
    .map((e) => e[1])
    .toSorted((a, b) => a.order - b.order)
    .map((e) => ({
      id: `label_${e.id}`,
      propertyId: e.id,
      type: "label",
      name: `${labelNamePrefix}${e.name}`,
    }));
  if (includeThemes) {
    options.unshift({
      id: "theme_theme",
      propertyId: "theme",
      name: "Themes",
      type: "theme",
    });
  }
  if (includeTopics) {
    options.unshift({
      id: "topic_topic",
      propertyId: "topic",
      name: "Topics",
      type: "topic",
    });
  }
  options.unshift({ id: "", propertyId: "", name: "", type: "" });
  return options;
}

type AddHeatmapButtonProps = {
  labelMappings: LabelMappings;
  includeThemes: boolean;
  includeTopics: boolean;
  onCreate: (xAxis: [string, string], yAxis: [string, string]) => void;
};

export function AddHeatmapButton(props: AddHeatmapButtonProps) {
  const { labelMappings, onCreate, includeThemes, includeTopics } = props;
  const [open, setOpen] = useState(false);
  const [xAxis, setXAxis] = useState<HeatmapDimensionOption | undefined>();
  const [yAxis, setYAxis] = useState<HeatmapDimensionOption | undefined>();
  const onClose = () => setOpen(false);
  const onClickCreate = () => {
    if (!!xAxis && !!yAxis) {
      onCreate([xAxis.type, xAxis.propertyId], [yAxis.type, yAxis.propertyId]);
      setOpen(false);
    }
  };
  const options = createHeatmapOptions(labelMappings, includeThemes, includeTopics);
  return (
    <Stack direction="row" sx={{ minHeight: "5rem" }} alignItems="center" justifyContent="center">
      <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
        Add heatmap
      </Button>
      <Dialog open={open} onClose={onClose} sx={{ "& .MuiDialog-paper": { minWidth: "480px" } }}>
        <Box>
          <DialogTitle>Add heatmap</DialogTitle>
          <DialogContent>
            <HeatmapDimensionSelect options={options} label={"Rows"} sx={{ mb: 2 }} value={yAxis} onChange={setYAxis} />
            <HeatmapDimensionSelect
              options={options}
              label={"Columns"}
              sx={{ mb: 2 }}
              value={xAxis}
              onChange={setXAxis}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onClickCreate}>Create</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Stack>
  );
}

type HeatmapData = [string, [string, number][] | undefined, number][];

function preprocessHeatmapData(
  yxData: [string, [string, number][] | undefined, number][],
  sort: boolean
): { rows: [string, number][]; table: HeatmapData; maxValue: number; maxRowSum: number } {
  // Extract the number of occurrences of the tag at each row
  const rowTotals: [string, number][] = yxData.map(([rowId, _, rowCount]) => [rowId, rowCount]);

  let table = [...yxData];

  if (sort) {
    table.sort((a, b) => b[2] - a[2]);
    rowTotals.sort((a, b) => b[1] - a[1]);
  }

  // Compute maxRow value (maxY) and max cell value
  const maxRowSum = rowTotals.reduce((a, c) => Math.max(a, c[1]), 0);
  const maxValue = yxData.reduce((p, [_, __, count]) => Math.max(count, p), 0); // find maximum number i data table

  return { rows: rowTotals, table, maxRowSum, maxValue };
}

export function CustomHeatmapChart(props: HeatmapChartProps) {
  const { yxData, themeColorFn, sortY, title, sx, onClose } = props;
  const uiTheme = useTheme();
  const { rows, table, maxRowSum, maxValue } = preprocessHeatmapData(yxData, sortY);

  const colorFn = interpolateRgb(uiTheme.palette.modalDark, "#FFFFFF");
  const transferFn = (v: number | undefined) => Math.pow((v ?? 0) / maxValue, 0.75);

  function heatmapCellStyle(count: number): SxProps<Theme> {
    const p = transferFn(count);
    return {
      backgroundColor: colorFn(p),
      color: p > 0.75 ? uiTheme.palette.common.black : "",
    };
  }
  return (
    <>
      <Stack direction="row" sx={{ width: "100%", ...sx }} justifyContent="center" alignItems="center">
        <Typography textAlign="center" sx={{ ml: "8rem" }}>
          {title}
          {onClose && (
            <Button sx={{ width: "8rem" }} onClick={onClose}>
              REMOVE
            </Button>
          )}
        </Typography>
      </Stack>
      <Stack direction="row" sx={{ minWidth: "100%" }}>
        <Stack direction="column" alignItems="flex-end">
          <Marker sx={{ visibility: "hidden", mb: 1 }} />
          {rows.map(([id]) => (
            <CustomChip noTooltip key={id} label={id} />
          ))}
        </Stack>
        <Stack direction="column" alignItems="flex-start" sx={{ width: "100%" }}>
          <Marker sx={{ visibility: "hidden", mb: 1 }} />
          {rows.map(([id, value]) => (
            <CustomChip noTooltip key={id} label={value + ""} sx={{ minWidth: `${(100 * value) / maxRowSum}%` }} />
          ))}
        </Stack>
        {table.length > 0 && (
          <Stack direction="column" alignItems="flex-start" sx={{ width: "100%", ml: 4 }}>
            <Stack direction="row">
              {table[0][1]!.map(([th]) => (
                <CustomChipTooltip key={th} title={th.replaceAll("-", " ")} placement="top">
                  <Marker sx={{ backgroundColor: themeColorFn(th), mb: 1 }} />
                </CustomChipTooltip>
              ))}
            </Stack>
            {table.map(([yId, xData]) => (
              <Stack direction="row" key={yId}>
                {xData!.map(([xId, count]) => (
                  <HeatmapCell key={xId} sx={heatmapCellStyle(count)}>
                    {count}
                  </HeatmapCell>
                ))}
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </>
  );
}

type ConfigurableHeatmapProps = {
  labelMappings: LabelMappings;
  themeColorFn: StableColorMap;
  projectId: ApiId;
  datasetId: ApiId;
  datasetFilename: string;
  hasThemes: boolean;
  hasTopics: boolean;
  filter?: ExplorationFilter;
};

function createLabelValueSortOrder(values: LabelValue[]): Record<string, number> {
  const pairs: [string, number][] = Object.entries(values).map((e) => [e[1].value, e[1].order]);
  const result: Record<string, number> = {};
  pairs.forEach((p) => (result[p[0]] = p[1]));
  return result;
}

type HeatmapItem = {
  id: string;
  title: string;
  sort: boolean;
  result: DataHeatmapResult;
};

function getHeatmapTitle(xAxis: [string, string], yAxis: [string, string], labelMappings: LabelMappings): string {
  const [xType, xId] = xAxis;
  const [yType, yId] = yAxis;
  const xName = xType === "label" ? labelMappings[xId].name : capitalize(xId);
  const yName = yType === "label" ? labelMappings[yId].name : capitalize(yId);
  return `${yName} / ${xName}`;
}

export type HeatmapChartProps = {
  sx?: SxProps<Theme>;
  title: string;
  yxData: HeatmapData;
  sortY: boolean;
  labelMappings: LabelMappings;
  onClose?: () => void;
  themeColorFn: StableColorMap;
};

function expandLabels(result: DataHeatmapResult, labelMappings: LabelMappings): DataHeatmapResult {
  if (!result.heatmap) {
    return result;
  }
  const { x_axis, y_axis, items } = result.heatmap;
  let hmItems: HeatmapData = [...items];
  if (y_axis[0] === "label") {
    let valueMapping = arrayToDict(labelMappings[y_axis[1]].values ?? [], "value");
    hmItems = hmItems.map((r) => [valueMapping[r[0]]?.name ?? r[0], r[1], r[2]]);
  }
  if (x_axis[0] === "label") {
    let valueMapping = arrayToDict(labelMappings[x_axis[1]].values ?? [], "value");
    hmItems = hmItems.map((r) => {
      return [r[0], r[1]?.map(([id, count]) => [valueMapping[id]?.name ?? id, count]), r[2]];
    });
  }
  return { ...result, heatmap: { x_axis, y_axis, items: hmItems } };
}

export function ConfigurableHeatmaps(props: ConfigurableHeatmapProps) {
  const { labelMappings, themeColorFn, projectId, datasetId, datasetFilename, filter, hasThemes, hasTopics } = props;
  const [heatmaps, setHeatmaps] = useState<HeatmapItem[]>([]);

  function onClose(id: string) {
    setHeatmaps([...heatmaps.filter((hm) => hm.id !== id)]);
  }
  function onCreate(xAxis: [string, string], yAxis: [string, string]) {
    let xSort = {};
    let ySort = {};
    xSort = xAxis[0] === "label" ? createLabelValueSortOrder(labelMappings![xAxis[1]]!.values ?? []) : {};
    ySort = yAxis[0] === "label" ? createLabelValueSortOrder(labelMappings![yAxis[1]]!.values ?? []) : {};
    const hmId = `${xAxis[0]}_${xAxis[1]}_${yAxis[0]}_${yAxis[1]}`;
    if (heatmaps.find((h) => h.id === hmId)) {
      return;
    }
    Apis.shared()
      .data.queryHeatmap(projectId, datasetId, datasetFilename, xAxis, yAxis, filter, xSort, ySort)
      .then((result) =>
        setHeatmaps([
          ...heatmaps,
          {
            id: hmId,
            title: getHeatmapTitle(xAxis, yAxis, labelMappings),
            sort: Object.keys(ySort).length === 0,
            result: expandLabels(result, labelMappings),
          },
        ])
      )
      .catch(console.error);
  }
  return (
    <Box>
      {heatmaps.map(({ sort, result: h, title, id }) => (
        <CustomHeatmapChart
          sx={{ my: 4 }}
          title={title}
          key={`hm_${id}`}
          yxData={h.heatmap!.items}
          onClose={() => onClose(id)}
          themeColorFn={themeColorFn}
          sortY={sort}
          labelMappings={labelMappings}
        />
      ))}
      <AddHeatmapButton
        labelMappings={labelMappings}
        onCreate={onCreate}
        includeThemes={hasThemes}
        includeTopics={hasTopics}
      />
    </Box>
  );
}
