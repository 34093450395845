export function createThrottler<T>(fn: (params: T) => void, msec: number) {
  const data: { handle: any; lastTrigger: number; params: T | undefined } = {
    handle: 0,
    lastTrigger: 0,
    params: undefined,
  };
  return (params: T) => {
    const interval = Date.now() - data.lastTrigger;
    data.params = params;
    data.lastTrigger = Date.now();
    if (interval < msec) {
      return;
    }
    data.handle = setTimeout(() => {
      fn(params);
      data.lastTrigger = 0;
    }, msec);
  };
}

export function createDebouncer<T>(fn: (params: T) => void, msec: number) {
  const data: { handle: any; params: T | undefined } = {
    handle: 0,
    params: undefined,
  };
  return (params: T, force: boolean = false) => {
    data.params = params;
    clearTimeout(data.handle);
    data.handle = setTimeout(
      () => {
        fn(params);
      },
      force ? 0 : msec
    );
  };
}

// NOTE: I have not tested this one. I ended up not needing it. Keeping it around for now because it can be useful.

export function createAsyncThrottler<T>(fn: (params: T) => Promise<void>, msec: number): (params: T) => void {
  const data: { handle: any; triggering: boolean; retrigger: boolean; params: T | undefined } = {
    handle: null,
    triggering: false,
    retrigger: false,
    params: undefined,
  };
  const triggerFn = (params: T) => {
    data.params = params;
    if (data.triggering) {
      data.retrigger = true;
      return;
    }
    if (data.handle === null) {
      data.handle = setTimeout(() => {
        data.triggering = true;
        fn({ ...params })
          .catch((error) => {
            console.error(error);
          })
          .then(() => {
            data.handle = null;
            data.triggering = false;
            if (data.retrigger) {
              data.retrigger = false;
              triggerFn(data.params!);
            }
          });
      }, msec);
    }
  };
  return triggerFn;
}

export function delayObject<T>(item: T, timeout: number): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    setTimeout(() => {
      resolve(item);
    }, timeout);
  });
}
