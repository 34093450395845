import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  Alert,
  Chip,
  ChipProps,
  useTheme,
  Tooltip,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLoaderData, useNavigate, useRouteLoaderData, useRevalidator } from "react-router-dom";
import {
  LangTextAggregateOptions,
  LangTextSummaryAvailableOptions,
  LangTextSummaryStatistics,
  ProjectModel,
  PromptModel,
  PromptClassificationItem,
} from "../../../../api/apimodels";
import { Apis } from "../../../../api/apis";
import { ApiBatchResult, ApiGeneralError } from "../../../../api/types";
import { sortedResource } from "../../../../util/sorting";
import { createDebouncer } from "../../../../util/throttling";
import { promptPath } from "../../../PageInfo";
import { PromptType } from "../../../prompt/PromptTypes";
import { ModelOptions } from "./ModelOptions";
import Markdown from "react-markdown";
import styles from "../../../../util/markdown.module.css";
import { stripMarkdownBlock } from "../../../../util/markdown";
import { SavePromptDialog } from "./SavePromptDialog";
import { ToolsDialog } from "./ToolsDialog";

type Props = {
  open: boolean;
  onClose: () => void;
  ids: string[];
  project_id: number;
  dataset_id: number;
  filename: string;
  type: PromptType;
};

function isPromptError(e: Error | undefined) {
  if (e instanceof ApiGeneralError) {
    const apiError = e as ApiGeneralError;
    if (apiError.details) {
      return apiError.details["type"] === "prompt";
    }
  }
  return false;
}

const ClassificationChip = styled(({ ...props }: ChipProps) => <Chip size="small" variant="outlined" {...props} />)(
  ({ theme }) => ({
    margin: theme.spacing(0.5),
    cursor: "default",
  })
);

const ClassificationStrip = ({
  classifications,
  loading,
}: {
  classifications: PromptClassificationItem[] | undefined;
  loading?: boolean;
}) => {
  return !classifications ? undefined : (
    <Stack spacing={1}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {classifications.map((c) => (
          <Tooltip key={c.name} title={c.description}>
            <Box>
              <ClassificationChip
                component="span"
                label={`${c.name}`}
                color={c.is_highlighted ? "warning" : "default"}
                title={c.description}
                key={c.name}
                sx={{ opacity: loading ? 0.5 : 1 }}
              />
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Stack>
  );
};

const ResultPanel = ({ result, title }: { result: string; title: string }) => {
  const [tab, setTab] = useState(0);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Tabs value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
        <Tab label="Markdown" />
        <Tab label="Raw" />
      </Tabs>
      {tab === 0 && <Markdown className={styles.markdown}>{stripMarkdownBlock(result)}</Markdown>}
      {tab === 1 && <TextField sx={{ mt: 4 }} value={result} label={title} multiline fullWidth />}
    </Box>
  );
};

function filterAndSortPrompts(prompts: PromptModel[] | undefined | null, type: PromptType): PromptModel[] {
  return sortedResource(
    (prompts ?? []).filter((p: any) => p.type === type),
    true
  );
}

function aggregationActivtyFromType(type: PromptType): string {
  switch (type) {
    case PromptType.manyToOneSummary:
      return "Summary";
    case PromptType.manyToOneDialog:
      return "Dialog";
    case PromptType.manyToOneQuote:
      return "Quote";
    case PromptType.oneToOne:
      return "Transformation";
  }
}

type CostInfoProps = {
  title: string;
  cost: number;
  forceGt?: boolean;
  loading?: boolean;
};

function CostInfo({ title, cost, forceGt, loading }: CostInfoProps) {
  const sx = { mr: 2, fontWeight: "bold" };
  const theme = useTheme();
  const costStr = "$" + Number(cost).toFixed(2);
  return loading ? (
    <Typography variant="body2" sx={sx}></Typography>
  ) : (
    <Typography variant="body2" color={cost && cost > 0.25 ? theme.palette.warning.main : "default"} sx={sx}>
      {title}: {forceGt ? `> ${costStr}` : cost < 0.005 ? `< ${costStr}` : `${costStr}`}
    </Typography>
  );
}

function StatusBar({
  loading,
  cost,
  costEstimation,
  classification,
}: {
  loading: boolean;
  cost: number | undefined;
  costEstimation: LangTextSummaryStatistics | undefined;
  classification: PromptClassificationItem[] | undefined;
}) {
  return (
    <Box sx={{ px: 3 }}>
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" sx={{ mt: 2 }} alignItems="center">
        {cost !== undefined ? (
          <CostInfo title="Cost" cost={cost} />
        ) : cost === undefined && costEstimation ? (
          <CostInfo
            title="Estimated Cost"
            cost={costEstimation.estimated_cost_usd}
            forceGt={costEstimation.is_reasoning_model}
          />
        ) : (
          <CostInfo title="Estimated Cost" cost={0} />
        )}

        <Typography variant="body2">&nbsp;</Typography>
        {classification !== undefined && <ClassificationStrip classifications={classification} />}
        {loading && <CircularProgress sx={{ mr: 1 }} size={16} />}
      </Stack>
    </Box>
  );
}

export const AggregationDialog = (props: Props) => {
  const { open, type, ids, onClose, project_id, dataset_id, filename } = props;
  const [result, setResult] = useState<string | undefined>();
  const [classification, setClassification] = useState<PromptClassificationItem[] | undefined>(undefined);
  const [cost, setCost] = useState<number | undefined>(undefined);
  const [costEstimation, setCostEstimation] = useState<LangTextSummaryStatistics | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>();
  const [modelsOptions, setModelsOptions] = useState<LangTextSummaryAvailableOptions | undefined>();
  const navigate = useNavigate();
  const [options, setOptions] = useState<LangTextAggregateOptions | undefined>();
  const [loading, setLoading] = useState(false);
  const [updatingStats, setUpdatingStats] = useState(false);
  const [currentIds, setCurrentIds] = useState<Set<string>>(new Set(ids));
  const project = useRouteLoaderData("project") as ProjectModel;
  const { prompts: loaderPrompts } = useLoaderData() as { prompts: ApiBatchResult<PromptModel> };
  const [prompts, setPrompts] = useState<PromptModel[]>(filterAndSortPrompts(loaderPrompts.items ?? [], type));
  const [savePromptData, setSavePromptData] = useState<[PromptModel | undefined, string] | undefined>();
  const revalidator = useRevalidator();

  // NOTE: The options are updated for each change. Since the summary requires an API call, we use a special options-state to throttle the updates
  const [summaryOptions, setSummaryOptions] = useState<LangTextAggregateOptions | undefined>(undefined);
  const setSummaryOptionsDebounced = useMemo(
    () =>
      createDebouncer((opts: LangTextAggregateOptions) => {
        setSummaryOptions(opts);
      }, 1000),
    []
  );

  useEffect(() => {
    if (!summaryOptions) {
      return;
    }
    setUpdatingStats(true);
    setError(undefined);
    setCost(undefined);
    setCostEstimation(undefined);
    Apis.shared()
      .lang.querySummaryStatistics(project_id, dataset_id, filename, ids, summaryOptions!, 300)
      .then((result) => {
        setUpdatingStats(false);
        setCostEstimation(result);
      })
      .catch((error) => {
        setUpdatingStats(false);
        setError(error);
      });
  }, [dataset_id, filename, ids, project_id, summaryOptions]);

  function onGenerate() {
    setLoading(true);
    setError(undefined);
    setCost(undefined);
    setClassification(undefined);
    Apis.shared()
      .lang.generateSummary(project_id, dataset_id, filename, ids, options!)
      .then(({ text, prompt_classification, cost_usd }) => {
        setResult(text);
        setClassification(prompt_classification);
        setCost(cost_usd);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // Quick hack to capture o1-preview related errors (need temperature to be set to 1)
        if (error instanceof ApiGeneralError && error.details && error.details["type"] === "params") {
          setError(error.details["message"]);
        } else {
          setError(error);
        }
      });
  }

  const onSavePromptPreset = (name: string, description: string) => {
    const asyncFn = async () => {
      await Apis.shared().project.createPrompt(project.id!, {
        name,
        description,
        type,
        text: savePromptData![1],
      });
      const result = await Apis.shared().project.fetchPrompts(project.id!);
      setPrompts(filterAndSortPrompts(result.items, type));
      setSavePromptData(undefined);
      revalidator.revalidate();
    };
    asyncFn();
  };

  useEffect(() => {
    if (!modelsOptions) {
      Apis.shared()
        .lang.summaryOptions()
        .then((availableOptions) => {
          const opts = availableOptions.model_options.filter((m) => m.name === availableOptions.defaults.model);
          const defaultOptions: LangTextAggregateOptions = {
            model: opts![0].name,
            temperature: availableOptions.defaults.temperature,
            fixed_temperature: opts![0].fixed_temperature,
            is_reasoning_model: opts![0].is_reasoning_model,
            prompt: availableOptions.defaults.prompt,
          };
          if (prompts.length > 0) {
            defaultOptions.prompt = prompts[0].text;
          }
          setModelsOptions(availableOptions);
          setOptions(defaultOptions);
          setSummaryOptions(defaultOptions);
        })
        .catch(setError);
    }
  }, [modelsOptions, prompts]);

  // Manage caching of old summary to save some bucks on OpenAI

  useEffect(() => {
    const newIds = new Set(ids);
    if (newIds.size !== currentIds.size || newIds.difference(currentIds).size !== 0) {
      setCurrentIds(new Set(ids));
    }
  }, [ids, currentIds]);

  return (
    <ToolsDialog open={open} onClose={onClose} fullWidth PaperProps={{ component: "form" }}>
      <DialogTitle>
        {loading ? "Generating" : "Generate"} a {aggregationActivtyFromType(type)} of {ids.length} Texts
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ mt: 2, width: "100%" }} direction="column">
          {loading ? (
            <CircularProgress sx={{ alignSelf: "center" }} />
          ) : (
            <Box>
              {
                // -------- PROMPT --------
                !!options && (
                  <ModelOptions
                    prompts={prompts}
                    options={options}
                    sx={{ mb: 2 }}
                    onManage={() => navigate("/" + promptPath(project.id!))}
                    onChange={(opts: LangTextAggregateOptions) => {
                      setSummaryOptions(opts);
                      setOptions(opts);
                    }}
                    onDebouncedChange={(opts: LangTextAggregateOptions) => {
                      setSummaryOptionsDebounced(opts);
                      setOptions(opts);
                    }}
                    onSavePrompt={(original, text) => setSavePromptData([original, text])}
                    availableOptions={modelsOptions!}
                    promptError={isPromptError(error)}
                  />
                )
              }
              {!result && !error && type !== PromptType.oneToOne && ids.length > 200 && (
                <Stack width="auto" alignSelf="flex-start" direction="row" sx={{ my: 2 }}>
                  <Alert severity="warning" sx={{ fontSize: "0.875rem", flexGrow: 0 }}>
                    Over 200 responses - tools may omit some details.
                  </Alert>
                  <Box flexGrow={1} />
                </Stack>
              )}
              {result && (
                // -------- RESULT --------
                <Box>
                  <Divider sx={{ my: 2 }} />
                  <ResultPanel title={`Summary of ${ids.length} selected texts`} result={result} />
                </Box>
              )}
              {error && (
                // -------- ERROR --------
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" color="error">
                    Generation failed.{" "}
                    {isPromptError(error)
                      ? "Prompt error - make sure you have the {text} placeholder included in the prompt."
                      : error + ""}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Stack>
      </DialogContent>
      <StatusBar loading={updatingStats} cost={cost} costEstimation={costEstimation} classification={classification} />
      <DialogActions sx={{ px: 2 }}>
        <Button onClick={onGenerate} disabled={loading && !!options}>
          {result ? "Regenerate" : "Generate"}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      {savePromptData !== undefined && (
        <SavePromptDialog
          open={savePromptData !== undefined}
          original={savePromptData && savePromptData[0]}
          text={(savePromptData && savePromptData[1]) ?? ""}
          onClose={() => setSavePromptData(undefined)}
          onSave={onSavePromptPreset}
        />
      )}
    </ToolsDialog>
  );
};
