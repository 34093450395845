import { Alert, Box, Breadcrumbs, Button, Container, Grid, Stack, Typography } from "@mui/material";
import * as PageInfo from "../../pages/PageInfo";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { StandardCard } from "../../components/common/StandardCard";
import { ProcessorModel, ProjectModel } from "../../api/apimodels";
import { Nav } from "../../components/common/Nav";
import { Apis } from "../../api/apis";
import { EditDetailsDialog } from "../../components/common/EditDetailsDialog";
import { useState } from "react";
import { createDownloadHandler } from "../../util/htmldom";
import { useRevalidator } from "react-router-dom";
export function ProcessorDetailsPage() {
  const navigate = useNavigate();
  const processor = useLoaderData() as ProcessorModel;
  const project = useRouteLoaderData("project") as ProjectModel;
  const [editOpen, setEditOpen] = useState(false);
  const revalidator = useRevalidator();

  const onEditDialogClose = () => {
    setEditOpen(false);
  };

  const onEditDialogSave = (name: string, description: string) => {
    Apis.shared()
      .project.updateProcessor(processor.id!, processor.id!, name, description)
      .then((result) => {
        navigate(".", { replace: true });
        setEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onDownload = createDownloadHandler(processor.filename!, () =>
    Apis.shared().project.createProcessorDownloadUrl(project.id ?? "", processor.id ?? "")
  );

  return !processor ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
          <Nav to="./..">{PageInfo.ProcessorPage.name}</Nav>
          <Typography color="text.primary">{processor.name}</Typography>
        </Breadcrumbs>
        <Box sx={{ mx: 3, mt: 2 }}>
          {processor.is_archived && (
            <Alert severity="warning">This processor is archived. You cannot use it to create new datasets.</Alert>
          )}
        </Box>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3} sx={{ "& .MuiPaper-root": { mt: 0 } }}>
            <Grid item xs={12} md={6} lg={6}>
              <StandardCard>
                <Typography variant="h6" sx={{ pb: 2 }}>
                  General information
                </Typography>
                <Typography>
                  <strong>Filename: </strong>
                  {processor.filename}
                </Typography>
                <Stack direction="row" justifyContent="flex-end">
                  <Button onClick={onDownload}>Download</Button>
                  <Button
                    onClick={() => {
                      setEditOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </Stack>
              </StandardCard>
            </Grid>
            <Grid item xs={12} md={4} lg={4}></Grid>
          </Grid>
          <EditDetailsDialog
            entityType="processor"
            entityId={processor.id!}
            projectId={project.id!}
            isArchived={processor.is_archived ?? false}
            dialogTitle="Edit processor"
            name={processor.name}
            description={processor.description}
            onClose={onEditDialogClose}
            onSave={onEditDialogSave}
            open={editOpen}
            onDelete={async () => {
              await Apis.shared().project.deleteProcessor(project.id!, processor.id!);
              onEditDialogClose();
              navigate(`/project/${project.id}/processor`);
            }}
            onArchive={async () => {
              await Apis.shared().project.toggleArchiveProcessor(project.id!, processor.id!);
              revalidator.revalidate();
              onEditDialogClose();
            }}
          />
        </Container>
      </Box>
    </Box>
  );
}
