import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { useState } from "react";

interface DeleteButtonProps extends ButtonProps {
  entityType?: string;
  onDelete: () => Promise<void>;
  hardDelete?: boolean;
  confirmTitle?: string;
  confirmMessage?: string;
  tooltipTitle?: string;
  showIcon?: boolean;
}

export function DeleteButton({
  entityType = "item",
  onDelete,
  hardDelete = false,
  confirmTitle,
  confirmMessage,
  tooltipTitle = "Delete",
  showIcon = false,
  children = "Delete",
  ...buttonProps
}: DeleteButtonProps) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete();
    } catch (error) {
      console.error("🚫 ❌ Failed to delete", error);
    } finally {
      setIsDeleting(false);
      setConfirmOpen(false);
    }
  };

  const title = confirmTitle || `Delete ${entityType}?`;
  const message =
    confirmMessage ||
    `This action will ${hardDelete ? "permanently" : "soft-"}delete this ${entityType}. ${
      hardDelete
        ? "This cannot be undone."
        : "It will be hidden from all views but can be restored by an administrator."
    }`;

  const button = (
    <Button
      color="error"
      onClick={() => setConfirmOpen(true)}
      disabled={isDeleting || buttonProps.disabled}
      startIcon={showIcon ? <DeleteIcon /> : undefined}
      {...buttonProps}
    >
      {children}
    </Button>
  );

  return (
    <>
      {tooltipTitle ? <Tooltip title={tooltipTitle}>{button}</Tooltip> : button}

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
