import { Dialog, styled } from "@mui/material";

export const ToolsDialog = styled(Dialog)(({ theme }) => ({
  "& form.MuiDialog-paper": {
    minWidth: "1280px",
    maxWith: "1280px",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("xl")]: {
      minWidth: "90%",
      maxWith: "none",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "90%",
      maxWith: "none",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "90%",
      maxWith: "none",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
      maxWith: "none",
    },
  },
}));
