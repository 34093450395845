import { Alert, Box, Button, CircularProgress, FormControlLabel, Stack, Switch } from "@mui/material";
import React, { useState } from "react";
import { ConversationSummaryResponse } from "../../../api/apimodels";
import ConfirmButton from "../../common/ConfirmButton";
import { CollectConversations } from "./CollectConversations";
import Delete from "@mui/icons-material/Delete";

interface StepConversationsProps {
  fetchingConversations: boolean;
  conversations: ConversationSummaryResponse | null;
  fetchConversations: (offset?: number) => Promise<void>;
  handleDeleteConversations: (conversationIds: string[]) => Promise<void>;
  handleRestoreAllConversations: () => Promise<void>;
}

export const StepConversations: React.FC<StepConversationsProps> = ({
  fetchingConversations,
  conversations,
  fetchConversations,
  handleDeleteConversations,
  handleRestoreAllConversations,
}) => {
  const [showDeleteHandles, setShowDeleteHandles] = useState<boolean>(false);

  return (
    <Box>
      {fetchingConversations ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress size={40} />
        </Box>
      ) : conversations ? (
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={showDeleteHandles}
                    onChange={(e) => setShowDeleteHandles(e.target.checked)}
                    size="small"
                  />
                }
                label="Delete mode"
              />
              {showDeleteHandles && (
                <ConfirmButton
                  size="small"
                  variant="outlined"
                  onConfirm={handleRestoreAllConversations}
                  confirmText="Restore"
                  dialogTitle="Restore all deleted conversations?"
                  dialogContent={
                    <>
                      Are you sure you want to restore <strong>all</strong> deleted conversations for this project,
                      independently of this import process? The selected time range will be ignored.
                    </>
                  }
                >
                  Restore All Deleted
                </ConfirmButton>
              )}
            </Box>
            <Button size="small" onClick={() => fetchConversations(conversations.offset)}>
              Refresh
            </Button>
          </Stack>

          <CollectConversations
            conversations={conversations}
            onLoadMore={fetchConversations}
            loading={fetchingConversations}
            onDeleteConversations={showDeleteHandles ? handleDeleteConversations : undefined}
          />
        </Stack>
      ) : (
        <Alert severity="warning">Failed to load conversations.</Alert>
      )}
    </Box>
  );
};
