import { Session } from "../api/sessionstore";
import { ApiNotFoundError } from "../api/types";
import { ErrorInterceptor } from "../api/httpclient";
import { ResourceLookupResult } from "../api/apimodels";

export class OrganizationController {
  private static instance: OrganizationController;
  private listeners: Array<(newOrgId: number) => void> = [];

  static getInstance() {
    if (!this.instance) {
      this.instance = new OrganizationController();
    }
    return this.instance;
  }

  async switchOrganization(orgId: number) {
    // 1. Update the session
    Session.shared().organizationId = orgId;

    // 2. Notify all listeners
    this.listeners.forEach((listener) => listener(orgId));

    // 3. Return the new organization ID
    return orgId;
  }

  addListener(listener: (newOrgId: number) => void) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }
}

// Custom error type
export class OrganizationSwitchNeededError extends Error {
  constructor(public orgId: number) {
    super(`Organization switch needed to ${orgId}`);
    this.name = "OrganizationSwitchNeededError";
  }
}

function extractResourceId(path: string, type: "project"): number | undefined {
  const result = path.match(`.*\\/${type}\\/(\\d+)(?:\\/.*$|$)`);
  return result && result.length > 1 ? parseInt(result[1]) : undefined;
}

// Modified interceptor to handle organization switch
export function createOrganizationSwitchInterceptor(
  organizationLookupFn: (type: string, id: number) => Promise<ResourceLookupResult>
): ErrorInterceptor {
  return async (error: any, url: string) => {
    try {
      // First check if we have a token - if not, don't try organization lookup
      if (!Session.shared().token) {
        console.log("🚫 No token available, skipping organization lookup");
        return error;
      }

      const pathname = new URL(url).pathname;
      if (error instanceof ApiNotFoundError) {
        const type = "project";
        const projectId = extractResourceId(pathname, type);
        if (projectId) {
          const orgInfo = await organizationLookupFn(type, projectId);

          if (orgInfo && orgInfo.organization_id !== Session.shared().organizationId) {
            // Handle organization switch directly in interceptor
            console.log(`🔄 Organization mismatch detected. Switching to organization ${orgInfo.organization_id}`);

            // Switch organization
            await OrganizationController.getInstance().switchOrganization(orgInfo.organization_id);
            // Wait a moment for organization switch to take effect
            if (window.location.pathname !== "/login" && window.location.pathname !== "/__/auth/handler") {
              Session.shared().deepLink = window.location.pathname + window.location.search;
            }
            window.location.reload();

            // Return undefined to signal that error was handled and request should be retried
            return undefined;
          }
        }
      }

      // If we didn't handle the error, return it for the next interceptor
      return error;
    } catch (e) {
      // If any error happens in our interceptor, return the original error
      console.error("🚫 Error in organization switch interceptor:", e);
      return error;
    }
  };
}
