import AddButtonIcon from "@mui/icons-material/Add";
import { Box, FormControl, IconButton, Stack, SxProps, Theme } from "@mui/material";
import {
  emptyExplorationFilterClauses,
  ExplorationFilterClause,
  ExplorationFilterClauses,
} from "../../../../api/apimodels";
import { useState } from "react";
import { Operator, OperatorButton, OperatorType, OrderedOperators } from "./Operator";
import {
  fakeNameFromId,
  FilterClauseLabel,
  FilterHeader,
  getBypassSx,
  MiniCheckbox,
  NestedFilterSection,
  sectionLabelSx,
} from "./common";
import { FilterClause } from "./FilterClause";

type TopicFilterProps = {
  filter: ExplorationFilterClauses | undefined;
  topicIds: string[];
  onChange: (newFilter: ExplorationFilterClauses) => void;
};

export function TopicFilter(props: TopicFilterProps) {
  const { filter, onChange, topicIds } = props;
  const [op, setOp] = useState<OperatorType>(
    OrderedOperators.find((o) => o.id === filter?.operator) ?? Operator.ANY_OF
  );

  const onEnableChange = () => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    newFilter.enabled = !newFilter.enabled;
    onChange(newFilter as ExplorationFilterClauses);
  };

  const onAddClause = () => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    const clauses = [...newFilter.clauses, { ids: [], enabled: true, operator: Operator.ANY_OF.id }];
    newFilter.clauses = clauses;
    onChange(newFilter);
  };

  const onChangeClause = (index: number, clause: ExplorationFilterClause) => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    const newClauses = [...newFilter.clauses];
    newClauses[index] = clause;
    newFilter.clauses = newClauses;
    onChange(newFilter);
  };

  const onOperatorChange = (newOperator: OperatorType) => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    newFilter.operator = newOperator.id;
    setOp(newOperator);
    onChange(newFilter);
  };

  const onRemoveClause = (index: number) => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    const newClauses = [...newFilter.clauses];
    newClauses.splice(index, 1);
    newFilter.clauses = newClauses;
    onChange(newFilter);
  };

  const bypassSx = getBypassSx(!!filter?.enabled);

  return (
    <Box>
      <FormControl sx={{ width: "100%" }}>
        <Stack direction="column" alignItems="center">
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%", mb: 1 }}>
            <FilterHeader id="filter-panel-theme-label">
              <MiniCheckbox checked={!!filter?.enabled} onChange={onEnableChange} sx={{ fontSize: "18px" }} />
              <FilterClauseLabel variant="body1" sx={{ ...sectionLabelSx, ...bypassSx } as SxProps<Theme>}>
                Topic Filter
              </FilterClauseLabel>
            </FilterHeader>
            <OperatorButton op={op} onChange={onOperatorChange} />
          </Stack>
          {(filter?.clauses ?? []).map((clause, i) => {
            return (
              <NestedFilterSection key={`_${i}`} sx={{ px: 1, mb: 1, ...bypassSx }}>
                <FilterClause
                  filter={clause}
                  label="Topics:"
                  addLabel={"Add topic"}
                  options={topicIds.map((id) => ({ id, name: fakeNameFromId(id) }))}
                  onChange={(clause: ExplorationFilterClause) => onChangeClause(i, clause)}
                  onRemove={() => {
                    onRemoveClause(i);
                  }}
                />
              </NestedFilterSection>
            );
          })}
          <Box>
            <IconButton onClick={onAddClause} color="primary">
              <AddButtonIcon />
            </IconButton>
          </Box>
        </Stack>
      </FormControl>
    </Box>
  );
}
