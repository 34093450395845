import { Box, SxProps, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useMemo } from 'react';
import { NamedItemModel } from '../../api/apimodels';

type Props<T extends NamedItemModel> = {
  label: string,
  className?: string,
  resources: T[],
  disabled?: boolean,
  selected?: T,
  variant?: "filled" | "standard" | "outlined",
  resourceFilter?: (item: T) => boolean,
  onChange: (value: T) => void,
  renderName?: (item: T) => string,
  sx?: SxProps<Theme>
}

function createLookup<T extends NamedItemModel>(resources: T[]): Record<string, T> {
  const lookup: Record<string, T> = {};
  resources.forEach((p) => { lookup[p.id!] = p; });
  return lookup;
}

export default function ResourceSelect<T extends NamedItemModel>(props: Props<T>) {
  const { resources: originalResources, label, className, onChange, disabled, resourceFilter, variant, sx, renderName } = props;
  const resources = resourceFilter ? originalResources.filter((r) => resourceFilter!(r)) : originalResources;
  const lookup = useMemo(() => (createLookup(resources)), [resources]);
  const handleChange = (event: SelectChangeEvent) => {
    const resourceId = parseInt(event.target.value);
    onChange(lookup[resourceId]);
  };
  const resource = lookup[props.selected?.id ?? ''];

  return (
    <Box className={className} sx={sx}>
      <FormControl variant={variant ?? "filled"} sx={{ width: '100%' }}>
        <InputLabel id="resource-select-label">{label}</InputLabel>
        <Select
          labelId="resource-select-label"
          id="resource-select"
          value={resource?.id ? resource.id + '' : ''}
          onChange={handleChange}
          disabled={disabled}
          label={label}
        >
          {resources.map((r) => (
            <MenuItem key={r.id} value={r.id}>{renderName ? renderName(r) : r.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
