import {
  ApiId,
  LangTextAggregateOptions,
  LangTextSummaryAvailableOptions,
  LangTextSummaryStatistics,
  LangTextSummmary,
} from "../apimodels";
import { standardGetOptions, standardPostOptions } from "../helpers";
import { HttpClient } from "../httpclient";
import { FetchFn, ILangApi } from "../types";

export class LangApi implements ILangApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  public ver: number;

  constructor(httpClient: HttpClient) {
    this.baseUrl = httpClient.baseUrl;
    this.fetchFn = (input, init) => httpClient.fetch(input, init);
    this.ver = Math.random() * 10000000;
  }

  async summaryOptions(): Promise<LangTextSummaryAvailableOptions> {
    const url = `${this.baseUrl}/summary/options`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    return data as LangTextSummaryAvailableOptions;
  }

  async querySummaryStatistics(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    ids: string[],
    options: LangTextAggregateOptions,
    numOutputTokens: number
  ): Promise<LangTextSummaryStatistics> {
    console.log({ options });
    const url = `${this.baseUrl}/summary/statistics?project_id=${projectId}&dataset_id=${datasetId}&filename=${filename}&num_output_tokens=${numOutputTokens}`;
    const response = await this.fetchFn(url, standardPostOptions({ body: JSON.stringify({ ids, options }) }));
    const data = await response.json();
    return data as LangTextSummaryStatistics;
  }

  async generateSummary(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    ids: string[],
    options: LangTextAggregateOptions
  ): Promise<LangTextSummmary> {
    const url = `${this.baseUrl}/summary?project_id=${projectId}&dataset_id=${datasetId}&filename=${filename}`;
    const response = await this.fetchFn(url, standardPostOptions({ body: JSON.stringify({ ids, options }) }));
    const data = await response.json();
    return data as LangTextSummmary;
  }
}
