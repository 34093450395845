import React from "react";
import Button from "@mui/material/Button";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

interface ToggleShowArchivedButtonProps {
  archivedCount?: number;
}

const ToggleShowArchivedButton: React.FC<ToggleShowArchivedButtonProps> = ({ archivedCount = 0 }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const archived = searchParams.get("archived") === "1";

  const toggleArchived = () => {
    setSearchParams((prev) => {
      if (prev.has("archived")) {
        prev.delete("archived");
      } else {
        prev.set("archived", "1");
      }
      return prev;
    });
  };

  const buttonText = archived ? "Show active" : `Show archived${archivedCount > 0 ? ` (${archivedCount})` : ""}`;

  if (archivedCount === 0) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button variant="outlined" color="primary" onClick={toggleArchived} disabled={!archived && archivedCount === 0}>
        {buttonText}
      </Button>
    </Box>
  );
};

export default ToggleShowArchivedButton;
