import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { CollectProjectStats } from "../../../api/apimodels";
import { FormCheckbox } from "../../common/form/FormCheckbox";
import { FormDateTimePicker } from "../../common/form/FormDateTimePicker";

interface StepFilteringProps {
  selectedProject?: CollectProjectStats;
  setValue: (name: string, value: any) => void;
}

export const StepFiltering: React.FC<StepFilteringProps> = ({ selectedProject, setValue }) => {
  return (
    <Box>
      <Stack spacing={3} sx={{ p: 2, borderRadius: 1 }}>
        <Stack spacing={2} direction="row">
          <FormDateTimePicker name="startTime" label="Start Time" />
          <FormDateTimePicker name="endTime" label="End Time" />
          <Button
            variant="outlined"
            color="secondary"
            title="Reset to Project Dates"
            onClick={() => {
              if (selectedProject) {
                setValue("startTime", selectedProject.start_date ? new Date(selectedProject.start_date) : undefined);
                setValue("endTime", selectedProject.end_date ? new Date(selectedProject.end_date) : undefined);
              }
            }}
          >
            Reset
          </Button>
        </Stack>
        <FormCheckbox name="onlyComplete" label="Only Complete Conversations" />
      </Stack>
    </Box>
  );
};
