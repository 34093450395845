/**
 * Session Storage Utility
 *
 * This file handles the storage of authentication tokens in localStorage or sessionStorage
 * depending on whether the user wants to remain logged in between browser sessions.
 *
 * For the complete authentication flow and token refresh mechanism, see:
 * - src/contexts/AuthContext.tsx
 * - docs/AUTH.md
 */

export interface ISession {
  get token(): string | null;
  set token(t: string | null);
  get organizationId(): number | null;
  set organizationId(id: number | null);
  get deepLink(): string | null;
  set deepLink(url: string | null);
}

enum SessionKeys {
  token = "session.token",
  organizationId = "session.organizationId",
  deepLink = "session.deepLink",
}

enum SessionMetaKeys {
  storage = "session.meta.storage",
}

interface SessionStorageProvider {
  storage: Storage;
  name: string;
}

export const PersistentStorageProvider: SessionStorageProvider = Object.freeze({
  storage: localStorage,
  name: "persistent",
});

export const EphemeralStorageProvider: SessionStorageProvider = Object.freeze({
  storage: sessionStorage,
  name: "ephemeral",
});

const AllSessionKeys = [SessionKeys.organizationId];

/**
 * Session provides token storage only
 *
 * IMPORTANT: This class only handles storage of tokens and data.
 * It is NOT responsible for:
 * - Refreshing tokens
 * - Managing auth state
 *
 * Auth state is managed in AuthContext which uses Session for storage.
 */
export class Session implements ISession {
  private provider: SessionStorageProvider;

  constructor(provider: SessionStorageProvider) {
    this.provider = provider;
  }

  get token(): string | null {
    return sessionStorage.getItem(SessionKeys.token);
  }

  set token(t: string | null) {
    if (t === null) {
      sessionStorage.removeItem(SessionKeys.token);
    } else {
      sessionStorage.setItem(SessionKeys.token, t);
    }
  }

  get organizationId(): number | null {
    const id = this.provider.storage.getItem(SessionKeys.organizationId);
    if (id === null) {
      return null;
    }
    return parseInt(id);
  }

  set organizationId(id: number | null) {
    if (id === null) {
      this.provider.storage.removeItem(SessionKeys.organizationId);
    } else {
      this.provider.storage.setItem(SessionKeys.organizationId, id.toString());
    }
  }

  get deepLink(): string | null {
    return localStorage.getItem(SessionKeys.deepLink);
  }

  set deepLink(url: string | null) {
    console.log("🔒 🔗 Setting deepLink:", url);
    if (url === null) {
      localStorage.removeItem(SessionKeys.deepLink);
    } else {
      localStorage.setItem(SessionKeys.deepLink, url);
    }
  }

  private _changeStorageProvider(provider: SessionStorageProvider) {
    if (this.provider.name === provider.name) {
      return;
    }
    const orgnizationId = this.provider.storage.getItem(SessionKeys.organizationId);
    if (orgnizationId) {
      provider.storage.setItem(SessionKeys.organizationId, orgnizationId);
    }
    this.clear();
    this.provider = provider;
  }

  clear() {
    sessionStorage.removeItem(SessionKeys.token);
    AllSessionKeys.forEach((k) => {
      this.provider.storage.removeItem(k);
    });
  }

  set(token: string) {
    this.token = token;
  }

  private static _sharedInstance: Session | null = null;

  public static createShared(provider: SessionStorageProvider) {
    this._sharedInstance = new Session(provider);
    localStorage.setItem(SessionMetaKeys.storage, provider.name);
  }

  public static storageProviderByName(name: string | undefined | null): SessionStorageProvider {
    switch (name) {
      case PersistentStorageProvider.name:
        return PersistentStorageProvider;
      case EphemeralStorageProvider.name:
        return EphemeralStorageProvider;
    }
    return EphemeralStorageProvider;
  }

  public static getDefaultStorageProvider(): SessionStorageProvider {
    return Session.storageProviderByName(localStorage.getItem(SessionMetaKeys.storage));
  }

  public static changeStorageProvider(instance: Session, name: string) {
    const provider = Session.storageProviderByName(name);
    console.log("🔍 changeStorageProvider", provider);
    instance._changeStorageProvider(provider);
    localStorage.setItem(SessionMetaKeys.storage, provider.name);
  }

  public static shared(): Session {
    return Session._sharedInstance!;
  }
}
