import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useRevalidator } from "react-router-dom";
import { PromptModel } from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { ArchiveButton } from "../../components/common/ArchiveButton";
import { DeleteButton } from "../../components/common/DeleteButton";
import { WideDialog } from "../../components/common/WideDialog";
import { PromptTypeSelect } from "./PromptsPage";
import { PromptType, promptTypeInfo } from "./PromptTypes";

type EditDetailsDialogProps = {
  open: boolean;
  dialogTitle: string;
  projectId: number;
  prompt: PromptModel;
  onClose: () => void;
  onSave: (name: string, description: string, text: string, type: PromptType) => void;
};

export function EditPromptDialog(props: EditDetailsDialogProps) {
  const { open, onClose, onSave, dialogTitle, prompt, projectId } = props;
  const { name, description, type, text } = prompt;
  const [typeId] = promptTypeInfo(type);
  const [currentType, setCurrentType] = useState(typeId as PromptType);
  const [currentText, setCurrentText] = useState(text);
  const [currentName, setCurrentName] = useState(name ?? "");
  const [currentDescription, setCurrentDescription] = useState(description ?? "");
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  useEffect(() => {
    if (open) {
      setCurrentName(type);
      setCurrentDescription(text);
      setCurrentName(name ?? "");
      setCurrentDescription(description ?? "");
    } else {
      setCurrentName("");
      setCurrentName("");
    }
  }, [open, name, description, type, text]);

  return (
    <WideDialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSave(currentName, currentDescription, currentText, currentType);
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <TextField
          required
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={currentName}
          onChange={(e) => {
            setCurrentName(e.target.value);
          }}
          variant="standard"
        />
        <TextField
          multiline
          margin="dense"
          id="description"
          name="description"
          label="Description"
          type="text"
          value={currentDescription}
          onChange={(e) => {
            setCurrentDescription(e.target.value);
          }}
          fullWidth
          variant="standard"
        />
        <Divider sx={{ mt: 2, mb: 2 }} />
        <TextField
          id="update-resource-prompt-text"
          label="Prompt text"
          variant="filled"
          multiline
          fullWidth
          value={currentText}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setCurrentText(e.target.value)}
          sx={{ pb: 2 }}
        />
        <PromptTypeSelect label="Type" type={currentType} onChange={setCurrentType} tooltipPlacement={"right"} />
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <DeleteButton
          entityType="prompt"
          hardDelete={false}
          onDelete={async () => {
            await Apis.shared().project.deletePrompt(projectId, prompt.id!);
            onClose();
            navigate("/project/" + projectId + "/exploration");
          }}
        />
        <ArchiveButton
          entityType="prompt"
          isArchived={prompt.is_archived ?? false}
          onToggleArchive={async () => {
            await Apis.shared().project.toggleArchivePrompt(projectId, prompt.id!);
            revalidator.revalidate();
            onClose();
          }}
        />
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Save</Button>
      </DialogActions>
    </WideDialog>
  );
}
