import { ProcessorModel } from "../../api/apimodels";
import { Box, Container, Grid, Typography } from "@mui/material";
import ResourceList from "../../components/common/ResourceList";
import ResourceCreateForm from "../../components/common/ResourceCreateForm";
import { ProcessorPage as ProcessorPageInfo } from "../PageInfo";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import { Apis } from "../../api/apis";
import { ApiBatchResult, ApiGeneralError } from "../../api/types";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { sortedResource } from "../../util/sorting";
import ToggleShowArchivedButton from "../../components/common/ToggleShowArchivedButton";

export function CreateProcessorForm() {
  const api = Apis.shared().project;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<Error | undefined>();
  const projectId: number | undefined = params.projectId ? parseInt(params.projectId) : undefined;

  const onCreateProcessor = async (name: string, description: string, filename?: string, data?: ArrayBuffer) => {
    const processor: ProcessorModel = { name, description, manifest: [] as any };
    setError(undefined);
    if (projectId) {
      try {
        const result = await api.createProcessor(projectId, processor);
        await api.uploadProcessorContent(projectId, result.id!, filename!, data!);
        navigate(location, { replace: true });
      } catch (e: any) {
        if (e instanceof ApiGeneralError) {
          setError(new Error(`Upload failed, status code ${e.statusCode}, message: ${e.details?.reason ?? ""}`));
        } else {
          setError(new Error(`Upload failed, general error: ${e.message}`));
          console.error(e);
        }
      }
    }
  };

  return (
    <ResourceCreateForm
      title="Add model"
      icon={ProcessorPageInfo.menuIcon}
      embedded={false}
      error={error}
      onCreate={onCreateProcessor}
      resourceNameLabel={"Model name"}
      resourceDescriptionLabel={"Model description"}
      uploadButtonLabel={"Upload zip"}
      createButtonLabel={"Create model"}
    />
  );
}

export default function ProcessorsPage() {
  const data = useLoaderData() as { processors: ApiBatchResult<ProcessorModel>; archivedCount: number };
  const navigate = useNavigate();

  const items = sortedResource(data.processors.items, true);

  function onSelectModel(id: number) {
    navigate("./" + id);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
              <ResourceList
                onSelectResource={onSelectModel}
                title="Models"
                icon={ProcessorPageInfo.menuIcon}
                iconFn={(item) => (item.filename !== null ? ProcessorPageInfo.menuIcon : <ErrorIcon color="error" />)}
                items={items}
                createLabel="Add model"
                isLoadingFn={(item) => false}
                forceExpand={data.processors.count === 0}
                footer={<ToggleShowArchivedButton archivedCount={data.archivedCount} />}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <CreateProcessorForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
