import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BarChart, PieChart } from "@mui/x-charts";
import { CollectProjectStats } from "../../../api/apimodels";
import { CollectProjectDetailsTable } from "./CollectProjectDetailsTable";

export function CollectStatistics({
  projectWithStats,
  onlyComplete,
}: {
  projectWithStats: CollectProjectStats;
  onlyComplete: boolean;
}) {
  return (
    <Stack gap={3}>
      <CollectProjectDetailsTable
        project={projectWithStats}
        padding
        startTime={projectWithStats.start_date ? new Date(projectWithStats.start_date) : undefined}
        endTime={projectWithStats.end_date ? new Date(projectWithStats.end_date) : undefined}
        onlyComplete={onlyComplete}
      />
      <Stack gap={2}>
        {projectWithStats.total_conversations > 0 ? (
          projectWithStats.categorical_stats.map((stat) => {
            // Create a mapping of value codes to their display names
            const valueMap = new Map(stat.descriptor.values.map((v) => [v.value, v]));

            // Sort dataset by the order specified in descriptor, using relative counts
            const dataset = Object.entries(stat.absolute_counts)
              .map(([value, count]) => ({
                category: valueMap.get(value)?.name || value,
                value: count,
                relativeValue: stat.relative_counts[value] * 100, // Convert to percentage
                description: valueMap.get(value)?.description || "",
                order: valueMap.get(value)?.order || 0,
              }))
              .sort((a, b) => b.value - a.value); // Sort by absolute count descending

            return (
              <Card key={stat.question_id} sx={{ p: 2 }}>
                <CardHeader title={stat.question_text} />
                <CardContent>
                  <Typography variant="body1">
                    {stat.descriptor.single_select && "Single-select question"}
                    {!stat.descriptor.single_select && "Multi-select question"}
                    {!stat.descriptor.single_select &&
                      stat.descriptor.num_select_min &&
                      `, min. ${stat.descriptor.num_select_min}`}
                    {!stat.descriptor.single_select &&
                      stat.descriptor.num_select_max &&
                      `, max. ${stat.descriptor.num_select_max}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {stat.descriptor.description}
                  </Typography>
                  <Box sx={{ width: "100%", height: 220 }}>
                    {stat.descriptor.single_select ? (
                      <PieChart
                        dataset={dataset}
                        series={[
                          {
                            data: dataset.map((d) => ({
                              label: d.category,
                              value: d.value,
                            })),
                          },
                        ]}
                        slotProps={{
                          legend: {
                            hidden: true,
                          },
                        }}
                      />
                    ) : (
                      <BarChart
                        width={500}
                        height={220}
                        series={[{ data: dataset.map((d) => d.value), id: "pvId" }]}
                        xAxis={[{ data: dataset.map((d) => d.category), scaleType: "band" }]}
                      />
                    )}
                  </Box>
                  <Table size="small" sx={{ mt: 2 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell align="right">Count</TableCell>
                        {stat.descriptor.single_select && <TableCell align="right">Percentage</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataset.map((row) => (
                        <TableRow key={row.category}>
                          <TableCell>{row.category}</TableCell>
                          <TableCell align="right">{row.value}</TableCell>
                          {stat.descriptor.single_select && (
                            <TableCell align="right">{row.relativeValue.toFixed(1)}%</TableCell>
                          )}
                        </TableRow>
                      ))}
                      {stat.descriptor.single_select && (
                        <TableRow sx={{ "& td": { fontWeight: "bold" } }}>
                          <TableCell>Total</TableCell>
                          <TableCell align="right">{stat.total_answers}</TableCell>
                          <TableCell align="right">100.0%</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <Alert severity="warning">No statistics available</Alert>
        )}
      </Stack>
    </Stack>
  );
}
