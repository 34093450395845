import ArchiveIcon from "@mui/icons-material/Archive";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useRevalidator } from "react-router-dom";
import { ProjectModel } from "../../api/apimodels";
import ToggleShowArchivedButton from "../common/ToggleShowArchivedButton";
type Props = {
  projects: ProjectModel[];
  onSelectProject: (id: number) => void;
  archivedCount: number;
};

function ProjectList(props: Props) {
  const revalidator = useRevalidator();
  const { projects, archivedCount } = props;

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        "& .MuiTypography-root.title": { marginTop: 0, marginBottom: 2, width: "100%" },
        "& .MuiListItem-root": { paddingLeft: 0, alignSelf: "flex-end" },
        "& .MuiListItemText-secondary": { color: "inherit" },
      }}
    >
      <Typography className="title" variant="h6">
        All projects
      </Typography>
      <List dense={false}>
        {revalidator.state === "loading" ? (
          <Stack direction="column" alignItems="center">
            <CircularProgress size={32} sx={{ my: 2 }} />
          </Stack>
        ) : projects.length === 0 ? (
          <Typography>No projects found</Typography>
        ) : (
          <>
            {projects
              .map((p) => ({ p, color: undefined }))
              .map(({ p, color }) => (
                <ListItem key={p.id}>
                  <ListItemButton onClick={() => props.onSelectProject(p.id!)}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: color }}>{p.is_archived ? <ArchiveIcon /> : <FolderIcon />}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ color }}
                      primary={[p.name, p.is_archived && "Archived"].filter(Boolean).join(" · ")}
                      secondary={p.subtitle}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </>
        )}
      </List>
      <ToggleShowArchivedButton archivedCount={archivedCount} />
    </Paper>
  );
}

export default ProjectList;
