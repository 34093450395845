import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
  User,
} from "firebase/auth";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticatePermissions } from "../api/apimodels";
import { Apis } from "../api/apis";
import { Session } from "../api/sessionstore";
import { ApiLoadingState } from "../api/types";
import { auth } from "../firebase-config";

export const REFRESH_SESSION_INTERVAL = 1000 * 60 * 30; // 30 minutes - half the time of the Firebase ID token timeout

// Types
interface AuthResult {
  idToken: string;
  token: string | null;
  permissions: AuthenticatePermissions;
}

// Define the auth state and context types
export type AuthState = {
  user: User | null;
  loading: ApiLoadingState;
  permissions: {
    organizations: any[];
    currentOrganizationId?: number;
  } | null;
  isProcessingDeepLink: boolean;
};

type AuthContextType = AuthState & {
  login: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
};

const initialAuthState: AuthState = {
  user: null,
  loading: ApiLoadingState.notLoading,
  permissions: null,
  isProcessingDeepLink: false,
};

export const AuthContext = createContext<AuthContextType>({
  ...initialAuthState,
  login: async () => {},
  signUp: async () => {},
  loginWithGoogle: async () => {},
  logout: async () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);
  const navigate = useNavigate();
  const location = useLocation();
  const [navigatedToDeepLink, setNavigatedToDeepLink] = useState<string | null>(null);
  const googleProvider = useMemo(() => new GoogleAuthProvider(), []);

  // Set up Google provider
  googleProvider.setCustomParameters({
    client_id: "635645546819-1s5tnbgcl02itb4m0hp1qv5m6t9m1udn.apps.googleusercontent.com",
  });

  /**
   * Authenticates Firebase user with backend API
   * @private
   */
  const authenticateFirebaseUser = useCallback(async (user: User): Promise<AuthResult> => {
    const apis = Apis.shared();
    const idToken = await user.getIdToken();
    const { token, permissions } = await apis.auth.authenticate(idToken || "");
    return { idToken, token: token ?? null, permissions };
  }, []);

  /**
   * Refreshes the user's session by exchanging Firebase token for app token
   */
  const refreshSession = useCallback(
    async (user: User) => {
      try {
        // Use the local method instead of authService
        const { token, permissions } = await authenticateFirebaseUser(user);
        console.log("🔍 🔄 Session refresh attempt", { success: !!token });

        if (!token) {
          setAuthState((state) => ({
            ...state,
            loading: ApiLoadingState.error,
          }));
          return;
        }

        // Store token and configure API refresh
        Session.shared().token = token;

        // Set up refresh parameters directly here
        const refreshTime = Date.now();
        const apis = Apis.shared();
        apis.needSessionRefreshFn = () => Promise.resolve(Date.now() - refreshTime > REFRESH_SESSION_INTERVAL);
        apis.sessionRefreshFn = () => refreshSession(user);
        Session.shared().organizationId = Session.shared().organizationId ?? permissions.organizations[0]?.id;
        // Update auth state
        setAuthState({
          user,
          loading: ApiLoadingState.notLoading,
          permissions: {
            organizations: permissions.organizations,
            currentOrganizationId: Session.shared().organizationId ?? permissions.organizations[0]?.id,
          },
          isProcessingDeepLink: false,
        });
      } catch (error) {
        console.error("🔍 ❌ Error refreshing session:", error);
        setAuthState((state) => ({
          ...state,
          loading: ApiLoadingState.error,
        }));
      }
    },
    [authenticateFirebaseUser]
  );

  // Monitor Firebase auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      console.log("Firebase auth state changed:", user, "deepLink:", Session.shared().deepLink);
      if (user) {
        setAuthState((state) => ({
          ...state,
          loading: ApiLoadingState.loading,
        }));
        refreshSession(user).then(() => {
          console.log("🔒 🔄 Session refresh completed", Session.shared().deepLink);
          // Handle deep link navigation in a centralized way
          if (Session.shared().deepLink) {
            const deepLink = Session.shared().deepLink!;
            // Use window.location for actual navigation
            console.log("🔒 🚀 Navigating to deepLink after login:", deepLink);
            setAuthState((state) => ({
              ...state,
              isProcessingDeepLink: true,
            }));
            navigate(deepLink);
            setNavigatedToDeepLink(deepLink);
            return; // Prevent any further execution
          }
        });
      } else {
        setAuthState({
          user: null,
          loading: ApiLoadingState.notLoading,
          permissions: null,
          isProcessingDeepLink: false,
        });
      }
    });

    return () => unsubscribe();
  }, [navigate, refreshSession]);

  // Clear deepLink after successful navigation
  useEffect(() => {
    // If we've navigated to the deep link and the location now matches that link, clear it
    if (navigatedToDeepLink && location.pathname === navigatedToDeepLink.split("?")[0]) {
      console.log("🔒 🔗 Clearing deepLink after successful navigation:", navigatedToDeepLink);
      Session.shared().deepLink = null;
      setNavigatedToDeepLink(null);
      setAuthState((state) => ({
        ...state,
        isProcessingDeepLink: false,
      }));
    }
  }, [location, navigatedToDeepLink]);

  // Auth methods
  const login = useCallback(async (email: string, password: string) => {
    try {
      setAuthState((state) => ({
        ...state,
        loading: ApiLoadingState.loading,
        isProcessingDeepLink: true,
      }));
      await signInWithEmailAndPassword(auth, email, password);
      // The auth state change listener will handle the session refresh
    } catch (error: any) {
      console.error("Login error:", error);
      setAuthState((state) => ({
        ...state,
        loading: ApiLoadingState.error,
        isProcessingDeepLink: false,
      }));
      throw error;
    }
  }, []);

  const signUp = useCallback(async (email: string, password: string) => {
    try {
      setAuthState((state) => ({
        ...state,
        loading: ApiLoadingState.loading,
        isProcessingDeepLink: true,
      }));
      await createUserWithEmailAndPassword(auth, email, password);
      // The auth state change listener will handle the session refresh
    } catch (error: any) {
      console.error("Sign up error:", error);
      setAuthState((state) => ({
        ...state,
        loading: ApiLoadingState.error,
        isProcessingDeepLink: false,
      }));
      throw error;
    }
  }, []);

  const loginWithGoogle = useCallback(async () => {
    try {
      setAuthState((state) => ({
        ...state,
        loading: ApiLoadingState.loading,
        isProcessingDeepLink: true,
      }));
      await signInWithRedirect(auth, googleProvider);
      // The redirect result will be handled by the auth state listener
    } catch (error: any) {
      console.error("Google login error:", error);
      setAuthState((state) => ({
        ...state,
        loading: ApiLoadingState.error,
        isProcessingDeepLink: false,
      }));
      throw error;
    }
  }, [googleProvider]);

  const logout = useCallback(async () => {
    try {
      await signOut(auth);
      // Clear session data
      Session.shared().clear();
      setAuthState({
        user: null,
        loading: ApiLoadingState.notLoading,
        permissions: null,
        isProcessingDeepLink: false,
      });
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        signUp,
        loginWithGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
