import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { WideDialog } from "./WideDialog";
import { useRevalidator } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DeleteButton } from "./DeleteButton";
import { ArchiveButton } from "./ArchiveButton";

type EditDetailsDialogProps = {
  open: boolean;
  dialogTitle: string;
  name?: string;
  description?: string;
  entityType: "exploration" | "dataset" | "processor" | "prompt";
  entityId: number;
  projectId: number;
  isArchived: boolean;
  onClose: () => void;
  onSave: (name: string, description: string) => void;
  onDelete: () => void;
  onArchive: (() => void) | null;
};

export function EditDetailsDialog(props: EditDetailsDialogProps) {
  const {
    open,
    onClose,
    onSave,
    dialogTitle,
    name,
    description,
    entityType,
    isArchived,
    onDelete,
    onArchive,
  } = props;
  const [currentName, setCurrentName] = useState(name ?? "");
  const [currentDescription, setCurrentDescription] = useState(description ?? "");
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  useEffect(() => {
    if (open) {
      setCurrentName(name ?? "");
      setCurrentDescription(description ?? "");
    } else {
      setCurrentName("");
      setCurrentName("");
    }
  }, [open, name, description]);

  return (
    <WideDialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSave(currentName, currentDescription);
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <TextField
          required
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={currentName}
          onChange={(e) => {
            setCurrentName(e.target.value);
          }}
          variant="standard"
        />
        <TextField
          multiline
          margin="dense"
          id="description"
          name="description"
          label="Description"
          type="text"
          value={currentDescription}
          onChange={(e) => {
            setCurrentDescription(e.target.value);
          }}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <DeleteButton
          entityType={entityType}
          onDelete={async () => {
            onDelete();
          }}
          hardDelete={false}
        />
        {onArchive ? (
          <ArchiveButton
            entityType={entityType}
            isArchived={isArchived}
            onToggleArchive={async () => {
              revalidator.revalidate();
              onArchive();
            }}
          />
        ) : null}
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Save</Button>
      </DialogActions>
    </WideDialog>
  );
}
