import { DataState } from "../pages/explorations/data/DataState";

export type ApiId = number | string;

export enum ResultState {
  completed = "completed",
  preparing = "preparing",
  running = "running",
  error = "error",
}

export enum ApiRole {
  admin = 30,
  standard = 20,
}

export interface NamedItemModel {
  id?: number;
  name: string;
  description: string;
}

export interface StandardItemModel extends NamedItemModel {
  created_at?: string;
}

export interface ResourceLookupResult {
  type: string;
  project_id?: number;
  organization_id: number;
}

export interface OrganizationInfo {
  id: number;
  name: string;
  role: number;
}

export interface AuthenticatePermissions {
  id: number;
  name: string;
  organizations: OrganizationInfo[];
}
export interface AuthenticateResponse {
  permissions: AuthenticatePermissions;
  token: string;
}
export interface ProjectModel extends StandardItemModel {
  subtitle?: string;
  latest_datasets?: DatasetModel[] | null;
  latest_explorations?: ExplorationModel[] | null;
  prompts?: PromptModel[] | null;
  is_archived?: boolean;
}

export interface ProjectCreateModel {
  name: string;
  subtitle?: string;
  description?: string;
}

export interface DownloadUrlInfo {
  url: string;
  expiresIn: number;
}

export const LabelColumnPrefix = "label_";

export interface DatasetArtifact extends StandardItemModel {
  size: number;
}

export interface DatasetModel extends StandardItemModel {
  filename?: string | null;
  labels_filename?: string | null;
  artifacts?: DatasetArtifact[];
  root_id?: number;
  parent_id?: number;
  job_id?: number;
  job?: JobInfoModel;
  columns?: string[];
  is_archived?: boolean;
}

export interface LabelValue {
  description: string;
  name: string;
  order: number;
  value: any;
}

export interface LabelMapping {
  description: string;
  id: string;
  name: string;
  order: number;
  type: string;
  values?: LabelValue[];
}

export type LabelMappings = Record<string, LabelMapping>;

export interface JobStatus {
  state: string;
  progress: number;
  time: string;
  message: string;
}

export interface LogEntry {
  timestamp: string;
  severity: string;
  message: string;
  extra: Record<string, any>;
}

export interface LogTableProps {
  logs: LogEntry[];
}

export interface JobExecutionStatusHistory {
  status: JobStatusEnum;
  message: string;
  created_at: string;
}

export interface JobExecutionIdentifier {
  image_uri: string;
  job_data?: string;
  hardware_config: {
    memory: string;
    cpu: string;
    timeout: number;
  };
  env_vars?: Record<string, string>;
  id: string;
  environment: "CLOUD_RUN_JOBS" | "LOCAL";
  details?: Record<string, any>;
}

export interface JobExecution {
  id: number;
  execution_identifier: JobExecutionIdentifier;
  status_history: JobExecutionStatusHistory[];
}
export enum JobStatusEnum {
  UNSPECIFIED = "COMPLETION_STATUS_UNSPECIFIED",
  SUCCEEDED = "EXECUTION_SUCCEEDED",
  FAILED = "EXECUTION_FAILED",
  RUNNING = "EXECUTION_RUNNING",
  PENDING = "EXECUTION_PENDING",
  CANCELLED = "EXECUTION_CANCELLED",
}

export const JobStatusEnumFinished = [JobStatusEnum.SUCCEEDED, JobStatusEnum.FAILED, JobStatusEnum.CANCELLED];

export interface JobInfoModel extends StandardItemModel {
  status?: JobStatus;
  latest_status?: JobStatusEnum;
  related_id: number;
  related_entity: string;
  processor_id: number;
  parameters?: string;
  completed: boolean;
  execution?: JobExecution;
  is_archived?: boolean;
}

export function isJobFailed(job: JobInfoModel, maxPendingTime: number = 60 * 60): boolean {
  return (
    !job.latest_status ||
    job.latest_status === JobStatusEnum.FAILED ||
    (Date.now() - new Date(job.created_at!).getTime()) / 1000 > maxPendingTime
  );
}

export interface CollectProjectMeta {
  project_id: string;
  title?: string;
  description?: string;
  start_date?: string;
  end_date?: string;
  organization_name?: string;
  organization_logo_url?: string;
  last_activity?: string | null;
  language: string;
  is_active: boolean;
}

export interface LabelField {
  id: string;
  name: string;
  order: number;
  description: string;
  type: string;
  values: LabelValue[];
  num_select_min?: number;
  num_select_max?: number;
  single_select?: boolean;
}

export interface CategoricalStat {
  question_id: string;
  question_text: string;
  total_answers: number;
  absolute_counts: Record<string, number>;
  relative_counts: Record<string, number>;
  descriptor: LabelField;
}

export interface CollectProjectStats extends CollectProjectMeta {
  total_conversations: number;
  total_conversations_completed: number;
  categorical_descriptor: LabelMappings;
  text_questions: Record<string, string>;
  categorical_stats: CategoricalStat[];
}

// PROCESSOR

export enum ProcessorParameterType {
  INTEGER = "INTEGER",
  ENUM = "ENUM",
  INTEGER_ENUM = "INTEGER_ENUM",
  FLOAT = "FLOAT",
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  KVLIST = "KVLIST",
  DATASET = "DATASET",
}

interface ProcessorBaseParameter {
  type: ProcessorParameterType;
  name: string;
  displayName?: string;
  description?: string;
  width?: number;
}

export interface ProcessorIntegerParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.INTEGER;
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  step?: number;
}

export interface ProcessorEnumParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.ENUM;
  defaultValue: string;
  values: string[];
}

export interface ProcessorIntegerEnumParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.INTEGER_ENUM;
  defaultValue: string;
  values?: number[];
  minValue?: number;
  maxValue?: number;
}

export interface ProcessorStringParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.STRING;
  defaultValue: string;
  minRows?: number;
  multiline?: boolean;
}

export interface ProcessorKVListParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.KVLIST;
  defaultValue: [string, string][];
  value: [string, string][];
  keyLabel: string;
  valueLabel: string;
}

export interface ProcessorFloatParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.FLOAT;
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  significatDigits?: number;
}

export interface ProcessorBooleanParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.BOOLEAN;
  labels?: [string, string];
  defaultValue: boolean;
}

export interface ProcessorDatasetParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.DATASET;
  defaultValue: number[];
  multiple: boolean;
  minSelections?: number;
  maxSelections?: number;
}

export type ProcessorParameterDescriptor =
  | ProcessorIntegerParameter
  | ProcessorIntegerEnumParameter
  | ProcessorFloatParameter
  | ProcessorEnumParameter
  | ProcessorBooleanParameter
  | ProcessorStringParameter
  | ProcessorKVListParameter
  | ProcessorDatasetParameter;

type ProcessorColumnDescriptor = {
  input: string[];
  output: string[];
};

export interface ProcessorManifest {
  formatters: Record<string, any>;
  columns?: ProcessorColumnDescriptor;
  parameters: [ProcessorParameterDescriptor];
}

export interface ProcessorModel extends StandardItemModel {
  filename?: string | null;
  manifest: ProcessorManifest;
  is_archived?: boolean;
}

export interface RegistryImage {
  id: string; // id of the image, image_name@digest
  image_path: string;
  digest: string;
  tags: string[];
  description: string;
  name: string;
  long_name: string;
  legacy: boolean;
  created_at: string;
  is_latest: boolean;
  build: boolean;
  manifest: ProcessorManifest | null;
}

// PROMPT

export interface PromptModel extends StandardItemModel {
  type: string;
  text: string;
  is_archived?: boolean;
}

export interface ExplorationFilterClause {
  id?: string;
  operator: string;
  enabled: boolean;
  ids: string[];
}

export const emptyExplorationFilterClause: ExplorationFilterClause = Object.freeze({
  operator: "or",
  enabled: true,
  ids: [],
});

export interface ExplorationFilterClauses {
  operator: string;
  enabled: boolean;
  clauses: ExplorationFilterClause[];
}

export const emptyExplorationFilterClauses: ExplorationFilterClauses = Object.freeze({
  operator: "or",
  enabled: true,
  clauses: [],
});

export interface ExplorationSampleFilter {
  operator: string;
  enabled: boolean;
  ids: string[];
}

export interface ExplorationFilter {
  topic?: ExplorationFilterClauses;
  theme?: ExplorationFilterClause;
  label?: ExplorationFilterClauses;
  sample?: ExplorationSampleFilter;
}

export function cleanupFilter(filter: ExplorationFilter | undefined): ExplorationFilter | undefined {
  if (!filter) {
    return undefined;
  }
  let { topic, theme, sample, label } = { ...filter };
  if ((theme?.ids ?? []).length === 0) {
    theme = undefined;
  }
  if (topic) {
    const { clauses } = topic;
    topic.clauses = [...clauses.filter((c) => c.ids.length > 0)];
    if (topic.clauses.length === 0) {
      topic = undefined;
    }
  }
  if (label) {
    const { clauses } = label;
    label.clauses = [...clauses.filter((c) => c.ids.length > 0)];
    if (label.clauses.length === 0) {
      label = undefined;
    }
  }
  if (!theme && !topic && !label) {
    return sample ? { sample } : undefined;
  }
  return { topic, theme, sample, label };
}

export const emptyExplorationFilter: ExplorationFilter = Object.freeze({
  topic: { ...emptyExplorationFilterClauses },
  theme: { ...emptyExplorationFilterClause },
  label: { ...emptyExplorationFilterClauses },
});
export interface ExplorationSelection extends StandardItemModel {
  filter: ExplorationFilter;
  data_state?: DataState;
}

export interface ExplorationModel extends StandardItemModel {
  dataset_id: number;
  dataset?: DatasetModel;
  selections: ExplorationSelection[];
  is_archived?: boolean;
}

type TextStatistics = {
  total: number;
  mean: number;
  bins: [number, number][];
};

type WordStatistics = {
  text: TextStatistics;
  original: TextStatistics;
};
export interface DataStatisticsResult {
  topics: [string, number][];
  themes: [string, number][];
  labels?: [string, [string, number][] | undefined][];
  theme_topics?: [string, [string, number][] | undefined][];
  heatmap?: [string, [string, number][] | undefined][];
  filtered_rows: number;
  total_rows: number;
  original_texts?: number;
  word?: WordStatistics;
}

export interface DataHeatmapResult {
  heatmap?: {
    x_axis: [string, string];
    y_axis: [string, string];
    items: [string, [string, number][] | undefined, number][];
  };
}

export interface DataSamplesResult {
  rows: Record<string, any>[];
  start: number;
  count: number;
  total_count: number;
}

export interface LangTextSummaryStatistics {
  estimated_token_count: number;
  estimated_cost_usd: number;
  is_reasoning_model: boolean;
}

export interface LangTextAggregateOptions {
  temperature: number;
  fixed_temperature: number | boolean | null;
  is_reasoning_model: boolean;
  model: string;
  prompt: string;
}

export interface ModelOptions {
  name: string;
  temperature_range: [number, number] | undefined | null;
  fixed_temperature: boolean;
  is_reasoning_model: boolean;
}

export interface LangTextSummaryAvailableOptions {
  models: string[];
  temperature: [number, number];
  defaults: LangTextAggregateOptions;
  model_options: ModelOptions[];
}

export interface PromptClassificationItem {
  category: string;
  name: string;
  description: string;
  is_highlighted: boolean;
}
export interface LangTextSummmary {
  text: string;
  prompt_classification: PromptClassificationItem[];
  cost_usd: number;
}

export interface JobModel {
  id: string;
  log_url: string;
}

export interface LogEntryModel {
  timestamp: string;
  severity: string;
  message: string;
  extra: Record<string, any>;
}

export interface CollectDatasetImport {
  project_id: string;
  name: string;
  only_complete: boolean;
  description: string;
  text_target_column: string;
  text_question_template_ids?: string[];
  start_time?: string;
  end_time?: string;
}

export interface ConversationMessage {
  timestamp: string;
  by: "user" | "system";
  type: string;
  content: string;
  template_id: string;
}

export interface ConversationMetadata {
  conversation_id: string;
  started_at: string;
  completed: boolean;
  message_count: number;
}

export interface ConversationSummary {
  conversation_id: string;
  messages: ConversationMessage[];
  metadata: ConversationMetadata;
}

export interface ConversationSummaryResponse {
  conversations: ConversationSummary[];
  total_count: number;
  offset: number;
  limit: number;
  has_more: boolean;
}
