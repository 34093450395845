import ErrorIcon from "@mui/icons-material/Error";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  IconButton,
  List,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useLoaderData, useLocation, useRouteLoaderData, useSearchParams } from "react-router-dom";
import {
  ApiId,
  DatasetArtifact,
  DatasetModel,
  DataStatisticsResult,
  JobInfoModel,
  JobStatus,
  JobStatusEnum,
  LabelColumnPrefix,
  LabelMappings,
  ProcessorModel,
  ProjectModel,
} from "../../api/apimodels";
import { Nav } from "../../components/common/Nav";
import { StandardCard } from "../../components/common/StandardCard";
import * as PageInfo from "../../pages/PageInfo";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Apis } from "../../api/apis";
import { AllColumns } from "../../api/dataconstants";
import { ApiBatchResult } from "../../api/types";
import DataHeaderItem from "../../components/common/DataHeaderItem";
import DatasetVersionPanel from "../../components/dataset/DatasetVersionPanel";
import { explorationPath } from "../../pages/PageInfo";
import { sortedResource } from "../../util/sorting";
import { requiredColumns } from "../explorations/ExplorationsPage";

import { ExpandLess as ExpandLessIcon } from "@mui/icons-material";
import LessIcon from "@mui/icons-material/ChevronLeft";
import MoreIcon from "@mui/icons-material/ChevronRight";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DateText } from "../../components/common/DateText";
import { EditDetailsDialog } from "../../components/common/EditDetailsDialog";
import { VisuallyHiddenInput } from "../../components/common/FileUploadButton";
import { LogsModal } from "../../components/processor/LogsModal";
import { formatJsonDate } from "../../util/dateformat";
import { createDownloadHandler } from "../../util/htmldom";
import { getJobStatusLabel } from "../../util/jobstate";
import { createJobIdMonitor } from "../../util/monitors";
import { DatasetPreviewModal } from "./DatasetPreviewModal";
import moment from "moment";
import { useRevalidator } from "react-router-dom";

const standardColumnIds = new Set<string>(AllColumns);

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  opacity: 0.75,
}));

const DescriptionTextSpan = styled("span")(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  opacity: 0.75,
}));

const ColumnChip = styled(Chip)(({ theme }) => ({
  fontSize: theme.typography.fontSize * 0.9,
  marginRight: theme.spacing(0.5),
  marginTop: theme.spacing(0.25),
  marginBottom: theme.spacing(0.25),
  height: theme.spacing(2.5),
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  textOverflow: "ellipsis",
  maxWidth: "12em",
}));

type ColumnsListProps = {
  columns: string[];
  title?: string;
  original: Set<string>;
  hideStandard?: boolean;
  expanded?: boolean;
  hideShowAll?: boolean;
  sx?: SxProps<Theme>;
};

function ColumnsList(props: ColumnsListProps) {
  const { columns, title, original, sx, expanded, hideStandard, hideShowAll } = props;
  const [showAll, setShowAll] = useState(!!expanded);
  const theme = useTheme();
  let nothingToExpand = false;
  let allAdded = new Set(columns).difference(original);

  if (!showAll) {
    const standardAdded = allAdded.intersection(standardColumnIds);
    nothingToExpand = standardAdded.size === allAdded.size;
    allAdded = standardAdded;
  }
  if (!!hideStandard) {
    allAdded = allAdded.difference(standardColumnIds);
  }
  const added = Array.from(allAdded);

  const onClickShowAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAll(!showAll);
  };

  return (
    <Box sx={sx}>
      {title && <Typography sx={{ display: "inline-flex", fontWeight: 600, mr: 1 }}>{title}</Typography>}
      {added.map((t: string) => (
        <ColumnChip
          key={t}
          label={t}
          size="small"
          sx={{ mr: 0.6, lineHeight: theme.typography.caption.fontSize }}
          title={standardColumnIds.has(t) ? "Standard column" : "Custom column"}
          color={standardColumnIds.has(t) ? "primary" : "default"}
        />
      ))}
      {!hideShowAll && (
        <IconButton size="small" sx={{ p: 0 }} color="primary" onClick={onClickShowAll} disabled={nothingToExpand}>
          {showAll ? <LessIcon /> : <MoreIcon />}
        </IconButton>
      )}
    </Box>
  );
}

type OriginalDatasetInfoProps = {
  projectId: number;
  dataset: DatasetModel;
  labels: LabelMappings | undefined;
  artifacts?: DatasetArtifact[];
  onSelectReferenceId: (id: number) => void;
  sx?: SxProps<Theme>;
};

function validateDatasetLabels(dataset: DatasetModel, labelDef: Record<string, object>) {
  const columnRe = new RegExp(`^${LabelColumnPrefix}`, "");
  const datasetColumnSet = new Set(dataset.columns?.map((c) => c.replace(columnRe, "")));
  const labelsSet = new Set(Object.keys(labelDef));
  const diff = labelsSet.difference(datasetColumnSet);
  return diff.size === 0;
}

type DownloadMenuProps = {
  projectId: ApiId;
  dataset: DatasetModel;
};

function DownloadMenu(props: DownloadMenuProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { projectId, dataset } = props;
  const [open, setOpen] = useState(false);
  const hasLabels = !!dataset.labels_filename;

  const onDownload = createDownloadHandler(dataset.filename!, () =>
    Apis.shared().project.createDatasetDownloadUrl(projectId ?? "", dataset.id ?? "")
  );
  const onDownloadLabels = hasLabels
    ? createDownloadHandler(dataset.labels_filename!, () =>
        Apis.shared().project.createDatasetLabelsDownloadUrl(projectId ?? "", dataset.id ?? "")
      )
    : undefined;
  return hasLabels ? (
    <>
      <Button
        ref={anchorRef}
        onClick={() => {
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        Download
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom-start" ? "left top" : "left bottom" }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      setOpen(false);
                      onDownload(e);
                    }}
                  >
                    Dataset
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setOpen(false);
                      onDownloadLabels!(e);
                    }}
                  >
                    Labels
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <Button onClick={onDownload}>Download</Button>
  );
}

type UploadMenuProps = {
  loading: boolean;
  projectId: ApiId;
  dataset: DatasetModel;
  onUploadLabels: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onUploadArtifact: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

function UploadMenu(props: UploadMenuProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { loading, onUploadLabels, onUploadArtifact } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => {
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        Upload
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom-start" ? "left top" : "left bottom" }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <MenuList>
                  <MenuItem onClick={(e) => {}} component="label">
                    Labels
                    {!loading && (
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onUploadLabels(e)
                            .then(() => {
                              setOpen(false);
                            })
                            .catch((error: Error) => {
                              console.error(error);
                            });
                        }}
                      />
                    )}
                  </MenuItem>
                  <MenuItem onClick={(e) => {}} component="label">
                    Artifact
                    {!loading && (
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onUploadArtifact(e)
                            .then(() => {
                              setOpen(false);
                            })
                            .catch((error: Error) => {
                              console.error(error);
                            });
                        }}
                      />
                    )}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function OriginalDatasetInfo(props: OriginalDatasetInfoProps) {
  const { dataset, labels, artifacts, projectId, onSelectReferenceId, sx } = props;
  const columns = (dataset.columns ?? []).map((c) => (c.length > 0 ? c : "[EMPTY]"));
  const [statistics, setStatistics] = useState<DataStatisticsResult | undefined>();
  const [statisticsError, setStatisticsError] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labelsError, setLabelsError] = useState(false);
  const [artifactsError, setArtifactsError] = useState(false);
  const location = useLocation();
  const api = Apis.shared().project;
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  useEffect(() => {
    if (!statistics) {
      Apis.shared()
        .data.queryStatistics(projectId, dataset.id!, dataset.filename!)
        .then(setStatistics)
        .catch((error) => {
          setStatisticsError(true);
          console.error(error);
        });
    }
  }, [dataset.filename, dataset.id, projectId, statistics]);

  const onEditDialogSave = (name: string, description: string) => {
    Apis.shared()
      .project.updateDataset(projectId, dataset.id!, name, description)
      .then((result) => {
        navigate(".", { replace: true });
        setEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onUploadLabels = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      async function onUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const { target } = e;
        setLoading(true);
        const fileReader = new FileReader();
        if ((target.files?.length ?? 0) > 0) {
          const file = target.files![0];
          fileReader.onload = async (e) => {
            const result = e.target?.result;
            if (result) {
              const resourceData = result as ArrayBuffer;
              const decoder = new TextDecoder();
              try {
                const labelJson = JSON.parse(decoder.decode(resourceData));
                if (!validateDatasetLabels(dataset, labelJson)) {
                  throw new Error("Label validation error");
                } else {
                  setLabelsError(false);
                  await api.uploadDatasetLabelsContent(projectId, dataset.id!, file.name, resourceData);
                  navigate(location, { replace: true });
                }
              } catch (e) {
                console.error(e);
                setLabelsError(true);
              }
            }
            setLoading(false);
          };
          fileReader.readAsArrayBuffer(file);
        }
      }
      return onUpload(e);
    },
    [api, dataset, location, navigate, projectId]
  );

  const onUploadArtifact = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      async function onUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const { target } = e;
        setLoading(true);
        const fileReader = new FileReader();
        if ((target.files?.length ?? 0) > 0) {
          const file = target.files![0];
          fileReader.onload = async (e) => {
            const result = e.target?.result;
            if (result) {
              const resourceData = result as ArrayBuffer;
              try {
                await api.uploadDatasetArtifact(projectId, dataset.id!, file.name, resourceData);
                navigate(location, { replace: true });
              } catch (e) {
                console.error(e);
                setArtifactsError(true);
              }
            }
            setLoading(false);
          };
          fileReader.readAsArrayBuffer(file);
        }
      }
      return onUpload(e);
    },
    [api, dataset, location, navigate, projectId]
  );

  return (
    <StandardCard sx={sx}>
      <DataHeaderItem
        name={dataset.name}
        description={dataset.description}
        filename={dataset.filename}
        createdAt={dataset.created_at}
      />
      <ColumnsList title="Columns" columns={columns} original={new Set()} sx={{ mt: 2.3 }} />
      {(statistics || statisticsError) && (
        <Stack direction="column">
          {labels && labels && Object.keys(labels).length > 0 && (
            <Typography sx={{ mt: 2 }}>
              <strong>Labels</strong>&nbsp;&nbsp;
              <DescriptionTextSpan>
                {Object.keys(labels)
                  .map((k) => labels[k])
                  .toSorted((a, b) => a.order - b.order)
                  .map((l) => l.name)
                  .join(", ")}
              </DescriptionTextSpan>
            </Typography>
          )}
          {artifacts && artifacts.length > 0 && (
            <Typography sx={{ mt: 2 }}>
              <strong>Artifacts</strong>&nbsp;&nbsp;
              <DescriptionTextSpan>{artifacts.map((a) => a.name).join(", ")}</DescriptionTextSpan>
            </Typography>
          )}
          {statistics ? (
            <Typography variant="body1" sx={{ mt: 2 }}>
              {statistics.total_rows} rows
              {statistics.original_texts && `, ${statistics.original_texts} original texts`}
              {statistics.topics && `, ${statistics.topics.length} topics`}
            </Typography>
          ) : (
            <Typography color="error" sx={{ mt: 2 }}>
              Statistics calcluation failed
            </Typography>
          )}
          {labelsError && (
            <Typography color="error">Labels do not match the dataset columns or corrupt file</Typography>
          )}
          {artifactsError && <Typography color="error">Artifacts upload failed</Typography>}
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button onClick={() => setShowPreview(true)}>Preview</Button>
            <DownloadMenu projectId={projectId} dataset={dataset} />
            <UploadMenu
              projectId={projectId}
              dataset={dataset}
              onUploadLabels={onUploadLabels}
              onUploadArtifact={onUploadArtifact}
              loading={loading}
            />
            <Button
              onClick={() => {
                setEditOpen(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                onSelectReferenceId(dataset.id!);
              }}
            >
              New version
            </Button>
          </Stack>
        </Stack>
      )}
      <EditDetailsDialog
        entityType="dataset"
        entityId={dataset.id!}
        projectId={projectId}
        isArchived={dataset.is_archived ?? false}
        dialogTitle="Edit dataset"
        name={dataset.name}
        description={dataset.description}
        onClose={() => {
          setEditOpen(false);
          revalidator.revalidate();
        }}
        onSave={onEditDialogSave}
        open={editOpen}
        onDelete={async () => {
          await Apis.shared().project.deleteDataset(projectId, dataset.id!);
          navigate(`/project/${projectId}`);
          setEditOpen(false);
        }}
        onArchive={async () => {
          await Apis.shared().project.toggleArchiveDataset(projectId, dataset.id!);
          revalidator.revalidate();
          setEditOpen(false);
        }}
      />
      {showPreview && (
        <DatasetPreviewModal
          open={showPreview}
          projectId={projectId}
          dataset={dataset}
          nRows={20}
          onClose={() => setShowPreview(false)}
        />
      )}
    </StandardCard>
  );
}

const ThemesTable = styled(Table)(({ theme }) => ({
  "& .MuiTableCell-root": {
    padding: theme.spacing(0.25),
    paddingRight: theme.spacing(1.0),
    border: "none",
  },
  "& .MuiTableCell-root:first-of-type": {
    minWidth: 0,
    width: 0,
    paddingLeft: 0,
    textAlign: "right",
  },
}));

type DatasetVersionItemProps = {
  projectId: number;
  dataset: DatasetModel;
  originalColumns: Set<string>;
  sx?: SxProps<Theme>;
  expanded: boolean;
  jobStatus?: JobStatus;
  processor: ProcessorModel | undefined;
  setExpanded: (id: ApiId, value: boolean) => void;
  onSelectReferenceId: (id: number) => void;
};

function datasetAge(dataset: DatasetModel): number {
  return (Date.now() - new Date(dataset.created_at!).getTime()) / 1000;
}

// FIXME: Clean up this messy status check.

function checkIsStuck(dataset: DatasetModel, timeout = 60 * 60) {
  return !dataset.filename && datasetAge(dataset) > timeout;
}

function checkIsPending(dataset: DatasetModel) {
  const hasEndState = [JobStatusEnum.SUCCEEDED, JobStatusEnum.FAILED, JobStatusEnum.CANCELLED].includes(
    dataset.job?.latest_status ?? JobStatusEnum.UNSPECIFIED
  );
  return !(hasEndState || checkIsStuck(dataset));
}

function formatParameters(job: JobInfoModel, processor: ProcessorModel, expanded: boolean) {
  const params = JSON.parse(job.parameters ?? "{}");
  const defs = processor.manifest?.parameters ?? [];
  return defs
    .map((d) => {
      return `${d.displayName}: ${params[d.name] ?? d.defaultValue}`;
    })
    .join(", ");
}

type ExpandableHeadlineProps = {
  title: string;
  open: boolean;
  horizontal?: boolean;
  onToggle: () => void;
};

function ExpandableHeadline({ open, onToggle, title, horizontal }: ExpandableHeadlineProps) {
  const iconStyle = { display: "inline-block", verticalAlign: "middle" };
  const lessIcon = horizontal ? <LessIcon sx={iconStyle} /> : <ExpandLessIcon sx={iconStyle} />;
  const moreIcon = horizontal ? <MoreIcon sx={iconStyle} /> : <ExpandMoreIcon sx={iconStyle} />;
  return (
    <Link sx={{ mb: 1 }} onClick={onToggle} color="inherit">
      <Typography sx={{ display: "inline-block", verticalAlign: "middle", lineHeight: "1.1rem" }}>{title}</Typography>
      {open ? lessIcon : moreIcon}
    </Link>
  );
}

const DatasetVersionItem = (props: DatasetVersionItemProps) => {
  const { dataset, sx, originalColumns, projectId, expanded, jobStatus, processor, setExpanded, onSelectReferenceId } =
    props;
  const { name, description, parent_id, id } = dataset;
  const isPending = checkIsPending(dataset);
  const isError =
    jobStatus?.state === "error" || checkIsStuck(dataset) || dataset.job?.latest_status === JobStatusEnum.FAILED;
  const [showPreview, setShowPreview] = useState(false);
  const [statistics, setStatistics] = useState<DataStatisticsResult | undefined>();
  const [showStatistics, setShowStatistics] = useState(false);
  const [showAllParameters, setShowAllParameters] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const navigate = useNavigate();
  const { artifacts } = dataset;
  const requiredColumnsSet = new Set(requiredColumns);
  const revalidator = useRevalidator();

  const onToggleAccordion = () => setExpanded(id!, !expanded);

  useEffect(() => {
    if (expanded && !statistics && !isError && dataset.filename) {
      Apis.shared()
        .data.queryStatistics(projectId!, dataset.id!, dataset.filename!)
        .then((result) => {
          setStatistics(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [expanded, statistics, isError, projectId, dataset.id, dataset.filename]);

  const onEditDialogClose = () => {
    setEditOpen(false);
  };

  const onEditDialogSave = (name: string, description: string) => {
    Apis.shared()
      .project.updateDataset(projectId, dataset.id!, name, description)
      .then((result) => {
        console.log("revalidating");
        revalidator.revalidate();
        setEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onExplore = (id: number) => {
    navigate("/" + explorationPath(projectId), { state: { selectedDatasets: [dataset.root_id!, dataset.id!] } });
  };

  const onDownload = createDownloadHandler(dataset.filename!, () =>
    Apis.shared().project.createDatasetDownloadUrl(projectId ?? "", dataset.id ?? "")
  );

  const buttons: [boolean, JSX.Element][] = [
    [!!dataset.job?.execution, <Button onClick={() => setShowLogs(true)}>Logs</Button>],
    [!isPending && !isError, <Button onClick={() => setShowPreview(true)}>Preview</Button>],
    [!isPending && !isError, <Button onClick={onDownload}>Download</Button>],
    [
      !isPending && !isError,
      <Button
        onClick={() => {
          setEditOpen(true);
        }}
      >
        Edit
      </Button>,
    ],
    [
      !isPending && !isError && requiredColumnsSet.difference(new Set(dataset.columns)).size === 0,
      <Button
        onClick={() => {
          onExplore(dataset.id!);
        }}
      >
        Explore
      </Button>,
    ],
    [
      !isPending && !isError,
      <Button
        onClick={() => {
          onSelectReferenceId(dataset.id!);
        }}
      >
        New version
      </Button>,
    ],
  ];

  return (
    <Accordion sx={{ ...sx, p: 1 }} expanded={expanded} onChange={onToggleAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          {isError && <ErrorIcon color="error" fontSize="small" sx={{ mr: 2 }} />}
          {isPending &&
            ((jobStatus?.progress ?? 0) > 0 ? (
              <Tooltip title={`${jobStatus?.state}: ${Math.round(jobStatus!.progress! * 100.0)}`}>
                <CircularProgress
                  variant="determinate"
                  value={Math.round(jobStatus!.progress! * 100.0)}
                  size={18}
                  sx={{ mr: 2, mt: "-1px" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={jobStatus?.state ?? "Starting"}>
                <CircularProgress size={18} sx={{ mr: 2, mt: "-1px" }} />
              </Tooltip>
            ))}
          <Box>
            <Typography sx={{ paddingTop: 0.2, whiteSpace: "pre" }}>
              {!parent_id ? `(Original) ${name}` : name}
            </Typography>
          </Box>
          <ColumnsList columns={dataset.columns ?? []} original={originalColumns} sx={{ ml: 1 }} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <DescriptionText sx={{ mt: 0, mb: 2 }}>{description}</DescriptionText>
        <DateText sx={{ m: 0, mb: 1 }}>
          <strong>Created:</strong> {formatJsonDate(dataset.created_at!)}&nbsp;
          <br />
          <strong>Execution state:</strong>{" "}
          <span>{getJobStatusLabel(dataset.job?.latest_status ?? JobStatusEnum.UNSPECIFIED)}</span>
          <br />
          {jobStatus?.message && (
            <>
              <strong>Message:&nbsp;</strong>
              <span style={{ whiteSpace: "pre-wrap" }}>{jobStatus.message}</span>
              <br />
            </>
          )}
          {dataset.parent_id && dataset.parent_id !== dataset.root_id && (
            <>
              <strong>Created from:&nbsp;</strong>
              <Link
                to={"/" + PageInfo.datasetPath(projectId!, dataset.root_id!) + "?xv=" + dataset.parent_id!}
                component={RouterLink}
              >
                version
              </Link>
            </>
          )}
          {dataset.job && processor && (
            <>
              <br />
              <strong>Model:</strong>{" "}
              <Nav to={"/" + PageInfo.processorPath(projectId, processor.id)}>{processor.name}</Nav>
              <br />
              <strong>Parameters:</strong> {formatParameters(dataset.job, processor, showAllParameters)}
            </>
          )}
          {artifacts && artifacts.length > 0 && (
            <>
              <br />
              <span>
                <strong>Artifacts:</strong>
                {artifacts.map((a) => a.name).join(", ")}
              </span>
            </>
          )}
        </DateText>
        {isError && (
          <Box>
            <Typography fontWeight={600}>Model error:</Typography>
            <Typography>{jobStatus?.message ?? "Unknown error"}</Typography>
          </Box>
        )}
        {statistics && statistics.total_rows && (
          <Box>
            <Typography>Number of rows: {statistics.total_rows}</Typography>
          </Box>
        )}
        {statistics && statistics.themes && (
          <Box sx={{ mt: 1 }}>
            <ExpandableHeadline
              title="Statistics"
              open={showStatistics}
              onToggle={() => setShowStatistics(!showStatistics)}
            />
            {showStatistics && (
              <ThemesTable sx={{ mb: 2, mt: 1 }}>
                <TableBody>
                  {statistics.themes.map(([id, count]) => (
                    <TableRow key={id}>
                      <TableCell>{count}</TableCell>
                      <TableCell>
                        <Chip size="small" label={id} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ThemesTable>
            )}
          </Box>
        )}
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
          {buttons
            .filter(([condition]) => condition)
            .map(([_, button], index) => (
              <React.Fragment key={index}>{button}</React.Fragment>
            ))}
        </Stack>
        <EditDetailsDialog
          dialogTitle="Edit dataset version"
          name={dataset.name}
          description={dataset.description}
          onClose={onEditDialogClose}
          onSave={onEditDialogSave}
          entityType="dataset"
          entityId={dataset.id!}
          projectId={projectId}
          isArchived={dataset.is_archived ?? false}
          onDelete={async () => {
            await Apis.shared().project.deleteDataset(projectId, dataset.id!);
            revalidator.revalidate();
            setEditOpen(false);
          }}
          onArchive={null}
          open={editOpen}
        />
      </AccordionDetails>
      {dataset.job?.execution ? (
        <LogsModal
          open={showLogs}
          execution={dataset.job.execution}
          jobId={dataset.job.id!}
          projectId={projectId}
          onClose={() => setShowLogs(false)}
        />
      ) : null}
      {showPreview && (
        <DatasetPreviewModal
          open={showPreview}
          projectId={projectId}
          dataset={dataset}
          nRows={20}
          onClose={() => setShowPreview(false)}
        />
      )}
    </Accordion>
  );
};

type DatasetVersionsListProps = {
  projectId: number;
  original: DatasetModel;
  versions: DatasetModel[];
  sx?: SxProps<Theme>;
  statusMap: Record<ApiId, JobStatus | undefined>;
  processors: ProcessorModel[];
  onSelectReferenceId: (id: number) => void;
};

const DatasetVersionsList = (props: DatasetVersionsListProps) => {
  const { versions, sx, original, projectId, statusMap, processors, onSelectReferenceId } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const originalColumns = new Set(original.columns ?? []);
  const expandedItems = new Set(searchParams.getAll("xv"));

  const setExpanded = (id: ApiId, value: boolean) => {
    const sId = id + "";
    const newSearchParams = new URLSearchParams(searchParams);
    const newExpandedItems = new Set(expandedItems);
    if (value) {
      newExpandedItems.add(sId);
    } else {
      newExpandedItems.delete(sId);
    }
    newSearchParams.delete("xv");
    newExpandedItems.forEach((i) => newSearchParams.append("xv", i));
    setSearchParams(newSearchParams);
  };

  const processorMap = useMemo(() => {
    const m: Record<ApiId, ProcessorModel> = {};
    processors.forEach((p) => {
      m[p.id!] = p;
    });
    return m;
  }, [processors]);

  return (
    <Box sx={sx}>
      <List>
        {versions.map((d) => (
          <DatasetVersionItem
            dataset={d}
            key={d.id}
            originalColumns={originalColumns}
            projectId={projectId}
            expanded={expandedItems.has(d.id + "")}
            processor={d.job?.processor_id ? processorMap[d.job?.processor_id!] : undefined}
            jobStatus={d.job && d.job_id! in statusMap ? statusMap[d.job_id!] : d.job?.status}
            setExpanded={setExpanded}
            onSelectReferenceId={onSelectReferenceId}
          />
        ))}
      </List>
    </Box>
  );
};

export function DatasetDetailsPage() {
  const [loadedDatasets, labelData, processors] = useLoaderData() as [
    ApiBatchResult<DatasetModel>,
    LabelMappings | undefined,
    ApiBatchResult<ProcessorModel>
  ];

  const [statusMap, setStatusMap] = useState<Record<ApiId, JobStatus | undefined>>({});
  const projectMeta = useRouteLoaderData("project") as ProjectModel;
  const dataset = loadedDatasets.items.find((d) => !d.parent_id)!;

  const [referenceVersionId, setReferenceVersionId] = useState<number | undefined>(undefined);
  const [completedSet, setCompletedSet] = useState(new Set<ApiId>());

  // FIXME: If we continue with react router loaders we stick to getting the datasets from the loader rather than storing the loaded items in a local state
  const [datasets, setDatasets] = useState<ApiBatchResult<DatasetModel>>({ count: 0, items: [], empty: true });
  const allVersions = sortedResource(datasets.items, true);
  const filteredVersions = allVersions.filter((v) => !!v.parent_id);
  const revalidator = useRevalidator();

  useEffect(() => {
    setDatasets(loadedDatasets);
  }, [loadedDatasets]);

  const itemMonitor = useMemo(
    () =>
      createJobIdMonitor(
        async (ids: ApiId[]) => {
          const jobs = await Apis.shared().project.fetchJobs(projectMeta.id!, false, true, "dataset", undefined, ids);
          const pending = jobs.filter((j) => !j.completed);
          const sMap: Record<ApiId, JobStatus | undefined> = {};
          pending.forEach((j) => {
            sMap[j.id!] = j.status ?? undefined;
          });
          setStatusMap(sMap);
          return pending;
        },
        (completedIds: ApiId[]) => {
          const newSet = new Set(completedIds);
          if (newSet.symmetricDifference(completedSet).size > 0) {
            setCompletedSet(newSet);
            // TODO: Fetch only updated datasets and patch the array instead
            Apis.shared().project.fetchDatasets(projectMeta.id!, dataset.id!, "all").then(setDatasets);
          }
        },
        2000
      ),
    [completedSet, dataset.id, projectMeta.id]
  );

  const onCreated = useCallback(() => {
    Apis.shared().project.fetchDatasets(projectMeta.id!, dataset.id!, "all").then(setDatasets);
  }, [dataset.id, projectMeta.id]);

  useEffect(() => {
    const monitorItems = datasets.items.filter((i) => i.job_id !== null && checkIsPending(i)).map((i) => i.job_id!);
    console.log("updating monitor items", monitorItems);
    itemMonitor(monitorItems);
  }, [datasets.items, itemMonitor]);

  useEffect(() => {
    Apis.shared()
      .project.fetchDatasets(projectMeta.id!, dataset.id!, "all")
      .then(setDatasets)
      .catch((error) => {
        console.error(error);
      });
    return () => itemMonitor(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeVersion() {
    setReferenceVersionId(undefined);
  }

  return !dataset ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
          <Nav to={"./.."}>{PageInfo.DatasetPage.name}</Nav>
          <Typography color="text.primary">{dataset.name}</Typography>
        </Breadcrumbs>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Stack direction="column" gap={3}>
            {dataset.is_archived && (
              <Alert severity="warning">This dataset is archived. You cannot use it for new explorations.</Alert>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <OriginalDatasetInfo
                  dataset={dataset}
                  labels={labelData}
                  artifacts={dataset.artifacts}
                  projectId={projectMeta.id!}
                  onSelectReferenceId={setReferenceVersionId}
                  sx={{ mt: 0 }}
                />
                {filteredVersions.length > 0 && (
                  <Typography variant="h6" sx={{ mt: 2, ml: 2, mb: 1 }}>
                    Versions
                  </Typography>
                )}
                <DatasetVersionsList
                  versions={filteredVersions}
                  original={dataset}
                  processors={processors.items ?? []}
                  projectId={projectMeta.id!}
                  statusMap={statusMap}
                  onSelectReferenceId={setReferenceVersionId}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <DatasetVersionPanel
                  source={dataset}
                  versions={allVersions}
                  referenceVersionId={referenceVersionId}
                  onCreated={onCreated}
                  onChangeVersion={onChangeVersion}
                  processors={processors.items ?? []}
                />
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
