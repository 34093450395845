import { OrganizationInfo, AuthenticateResponse } from "../apimodels";
import { standardGetOptions, standardPostOptions } from "../helpers";
import { ApiBatchResult, FetchFn, IAuthApi } from "../types";
import { HttpClient } from "../httpclient";

export class AuthApi implements IAuthApi {
  private baseUrl: string;
  private fetchFn: FetchFn;

  constructor(httpClient: HttpClient) {
    this.baseUrl = httpClient.baseUrl;
    this.fetchFn = (input, init) => httpClient.fetch(input, init);
  }

  googleLoginUrl(targetPath?: string): string {
    const url = new URL(this.baseUrl + "/login/google");
    url.searchParams.set("target", targetPath ?? "/");
    return url.toString();
  }

  async listOrganizations(token: string): Promise<ApiBatchResult<OrganizationInfo>> {
    const url = `${this.baseUrl}/organizations`;
    const options = standardGetOptions({ headers: { Authorization: `Bearer ${token}` } });
    const response = await this.fetchFn(url, options);
    const data = await response.json();
    return data;
  }

  async login(username: string, password: string): Promise<{ token: string; refreshToken: string }> {
    const url = `${this.baseUrl}/login`;
    const options = standardPostOptions({ body: JSON.stringify({ username, password }) });
    const response = await this.fetchFn(url, options);
    const data = await response.json();
    return { token: data.token, refreshToken: data.refreshToken };
  }

  async tokenRefresh(
    organizationId: number | undefined,
    refreshToken: string,
    projectId?: number
  ): Promise<{ token: string; refreshToken: string }> {
    const url = `${this.baseUrl}/refresh`;
    const options = standardPostOptions({
      body: JSON.stringify({
        organization_id: organizationId,
        refresh_token: refreshToken,
        project_id: projectId,
      }),
    });
    const response = await this.fetchFn(url, options);
    const data = await response.json();
    return { token: data.token, refreshToken: data.refresh_token };
  }

  async authenticate(token: string): Promise<AuthenticateResponse> {
    const url = `${this.baseUrl}/authenticate`;
    const options = standardPostOptions({ body: JSON.stringify({ token }) });
    const response = await this.fetchFn(url, options);
    return await response.json();
  }
}
