import { Box, PaletteMode, styled, useTheme } from "@mui/material";
import { blueberryTwilightPalette } from "@mui/x-charts/colorPalettes";
import { interpolateRgb } from "d3";
import { interpolateGreys } from "d3-scale-chromatic";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useLoaderData, useNavigate, useParams, useRevalidator, useSearchParams } from "react-router-dom";
import {
  DataStatisticsResult,
  emptyExplorationFilter,
  ExplorationFilter,
  ExplorationModel,
  ExplorationSelection,
  LabelMappings,
} from "../../../api/apimodels";
import { Apis } from "../../../api/apis";
import { ColumnId } from "../../../api/dataconstants";
import { ArchiveButton } from "../../../components/common/ArchiveButton";
import { DeleteButton } from "../../../components/common/DeleteButton";
import { StandardCard } from "../../../components/common/StandardCard";
import { ApplicationActionTypes, useApplicationDispatch } from "../../../state/applicationstate";
import * as colormap from "../../../util/colormap";
import ChartPanel from "./ChartPanel";
import { DataPanel } from "./DataPanel";
import {
  createEmptyDataState,
  DataStateActionTypes,
  dataStateReducer,
  selectedSampleIds,
  serializeDataState,
} from "./DataState";
import FilterPanel from "./FilterPanel";
import { SelectionPanel } from "./SelectionPanel";
import { ToolsPanel } from "./ToolsPanel";

const StyledGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "17em 1fr",
  gap: theme.spacing(3),
}));

const GridLeftColumn = styled(Box)(({ theme }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  gridRowStart: 1,
  gridRowEnd: 3,
}));

const LeftColumnCard = styled(StandardCard)(({ theme }) => ({
  marginTop: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  borderColor: theme.palette.grey[800],
  boxShadow: theme.shadows[2],
  backgroundImage: "none",
  borderWidth: theme.palette.mode === "light" ? "0px" : "1px",
  borderStyle: "solid",
}));

function getLastSelection(selections: ExplorationSelection[]) {
  return selections.reduce((p, c) => {
    return Date.parse(p.created_at!) > Date.parse(c.created_at!) ? p : c;
  }, selections[0]);
}

function getAvailableColumns(topicIds: any[], themeIds: any[]): ColumnId[] {
  const columns = [ColumnId.TEXT];
  if (topicIds.length > 0) {
    columns.push(ColumnId.TOPICS);
  }
  if (themeIds.length > 0) {
    columns.push(ColumnId.THEME);
  }
  return columns;
}

type ExplorationDetailsTabProps = {
  loading: boolean;
  setLoading: (v: boolean) => void;
};

function createPalette(ids: string[], mode: PaletteMode) {
  const mainPalette = blueberryTwilightPalette(mode);
  const len = ids.length / mainPalette.length;
  const paletteColors = ids.map((_, i) =>
    interpolateRgb(
      mainPalette[i % mainPalette.length],
      interpolateGreys(Math.floor(i / mainPalette.length) / len),
    )(0.25),
  );
  return paletteColors;
}

export function ExplorationDetailsDataTab(props: ExplorationDetailsTabProps) {
  const { loading, setLoading } = props;
  const { exploration, statistics, labels } = useLoaderData() as {
    exploration: ExplorationModel;
    statistics: DataStatisticsResult;
    labels: LabelMappings | undefined;
  };
  const [selections, setSelections] = useState(exploration.selections);
  const params = useParams();
  const projectId: number | undefined = params.projectId ? parseInt(params.projectId) : undefined;
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedId = parseInt(searchParams.get("selection") ?? "");
  const themeIds = statistics.themes ? statistics.themes.map((t) => t[0]).toSorted((a, b) => a.localeCompare(b)) : [];
  const topicIds = statistics.topics ? statistics.topics.map((t) => t[0]).toSorted((a, b) => a.localeCompare(b)) : [];
  const selection = selections.find((a) => a.id === selectedId);
  const [dataState, dataStateDispatch] = useReducer(dataStateReducer, createEmptyDataState());
  const columns = getAvailableColumns(topicIds, themeIds);
  const uiTheme = useTheme();
  const labelIds = Object.entries(labels ?? {})
    .flatMap(([k, e]) => [k, e.values?.flatMap((v) => v.name)])
    .flatMap((v) => v) as string[];
  const paletteIds = themeIds.concat(labelIds);
  const themeColorFn = colormap.buildFromIds(
    paletteIds,
    createPalette(paletteIds, uiTheme.palette.mode),
    uiTheme.palette.grey[700],
  );
  const [filter, setFilter] = useState<ExplorationFilter>(selection?.filter ?? emptyExplorationFilter);
  const appDispatch = useApplicationDispatch();

  const onFilterChange = useCallback((newFilter: ExplorationFilter, clearSelectedIds: boolean) => {
    setFilter(newFilter);
    if (clearSelectedIds) {
      dataStateDispatch({ type: DataStateActionTypes.clearSelected });
    }
  }, []);

  const onNewSelection = useCallback(
    (name: string, description: string, keepFilter: boolean) => {
      setLoading(true);
      const asyncFn = async () => {
        try {
          const newSelection = await Apis.shared().project.createExplorationSelection(projectId!, exploration.id!, {
            name,
            description,
            filter: keepFilter ? filter : emptyExplorationFilter,
            data_state: dataState,
          });
          if (!keepFilter) {
            setFilter(emptyExplorationFilter);
          }
          const updated = Apis.shared().project.fetchExploration(projectId!, exploration.id!);
          setSelections((await updated).selections);
          setLoading(false);

          const sp = new URLSearchParams(searchParams);
          sp.set("selection", newSelection.id! + "");
          setSearchParams(sp);
        } catch (e) {
          setLoading(false);
          appDispatch({
            type: ApplicationActionTypes.addNotification,
            notification: {
              message: `Create new selection failed. Error: ${e}`,
              type: "error",
            },
          });
          console.error(e);
        }
      };
      asyncFn();
    },
    [appDispatch, dataState, exploration.id, filter, projectId, searchParams, setLoading, setSearchParams],
  );

  const onPickSelection = (newSelection: ExplorationSelection | undefined) => {
    const sp = new URLSearchParams(searchParams);
    if (!newSelection) {
      sp.delete("selection");
      setFilter(emptyExplorationFilter);
      setSearchParams(sp);
    } else {
      sp.set("selection", newSelection.id! + "");
      setFilter(newSelection.filter);
      setSearchParams(sp);
      dataStateDispatch({
        type: DataStateActionTypes.loadUntypedState,
        state: newSelection.data_state,
      });
    }
  };

  const onSaveSelection = (savedSelection: ExplorationSelection) => {
    const newSelection = { ...savedSelection };
    const newSelections = [...selections];

    newSelection.filter = { ...filter };
    newSelection.data_state = serializeDataState(dataState);
    setLoading(true);
    const asyncFn = async () => {
      try {
        await Apis.shared().project.saveExplorationSelection(projectId!, exploration.id!, selection?.id!, newSelection);
        const idx = newSelections.findIndex((s) => s.id === newSelection.id);
        newSelections[idx] = newSelection;
        setSelections(newSelections);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        appDispatch({
          type: ApplicationActionTypes.addNotification,
          notification: {
            message: `Save failed. Error: ${e}`,
            type: "error",
          },
        });
        console.error(e);
      }
    };
    asyncFn();
  };

  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onDeleteSelection = (deletedSelection: ExplorationSelection) => {
    const asyncFn = async () => {
      try {
        setLoading(true);
        await Apis.shared().project.deleteExplorationSelection(projectId!, exploration.id!, deletedSelection?.id!);
        const idx = selections.findIndex((s) => s.id === deletedSelection.id);
        const remainingSelections = [...selections];
        remainingSelections.splice(idx, 1);
        //onPickSelection(selections[Math.min(idx, remainingSelections.length - 1)]);
        onPickSelection(remainingSelections[idx]);
        setSelections(remainingSelections);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        appDispatch({
          type: ApplicationActionTypes.addNotification,
          notification: {
            message: `Delete failed. Error: ${e}`,
            type: "error",
          },
        });
        console.error(e);
      }
    };
    asyncFn();
  };

  // Update the filter if user changes selection.
  //
  // Not super happy with bloating ExplorationDetailsPage
  // with more nitty gritty but I cannot think of any better substantially
  // better alternatives right now.

  useEffect(() => {
    if (filter.sample?.enabled) {
      const newIds = selectedSampleIds(dataState);
      const newIdsSet = new Set(newIds);
      if (filter.sample) {
        const filterIdsSet = new Set(filter.sample.ids);
        if (newIdsSet.size !== filterIdsSet.size || newIdsSet.difference(filterIdsSet).size > 0) {
          const newFilter = { ...filter };
          newFilter.sample = { ...filter.sample };
          newFilter.sample.ids = newIds;
          setFilter(newFilter);
        }
      }
    }
  }, [dataState, dataState.items, filter]);

  useEffect(() => {
    if (!!selectedId || !selections || selections.length === 0) {
      return;
    }
    onPickSelection(getLastSelection(selections));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, selections]);
  return (
    <StyledGrid sx={{ m: 3, mt: 0 }}>
      <GridLeftColumn>
        <LeftColumnCard>
          <SelectionPanel
            onPick={onPickSelection}
            onCreate={onNewSelection}
            onSave={onSaveSelection}
            onDelete={onDeleteSelection}
            selected={selection}
            selections={selections}
            sx={{ mr: 0 }}
          />
        </LeftColumnCard>
        <LeftColumnCard>
          <FilterPanel
            onChange={onFilterChange}
            filter={filter}
            themeIds={themeIds}
            themeColorFn={themeColorFn}
            topicIds={topicIds}
            labelMappings={labels}
            selectedIds={selectedSampleIds(dataState)}
          />
        </LeftColumnCard>
        <LeftColumnCard>
          <ToolsPanel
            selectedItems={dataState.items}
            project_id={projectId!}
            dataset_id={exploration.dataset!.id!}
            filename={exploration.dataset!.filename!}
          />
        </LeftColumnCard>
        <Box sx={{ mt: 2 }}>
          <DeleteButton
            entityType="exploration"
            hardDelete={false}
            onDelete={async () => {
              await Apis.shared().project.deleteExploration(projectId!, exploration.id!);
              navigate(`/project/${projectId}/exploration`);
            }}
          />
          <ArchiveButton
            entityType="exploration"
            isArchived={exploration.is_archived ?? false}
            onToggleArchive={async () => {
              await Apis.shared().project.toggleArchiveExploration(projectId!, exploration.id!);
              revalidator.revalidate();
            }}
          />
        </Box>
      </GridLeftColumn>
      <Box>
        <StandardCard>
          <ChartPanel
            projectId={projectId!}
            dataset={exploration.dataset!}
            filter={filter}
            labels={labels}
            themeColorFn={themeColorFn}
          />
        </StandardCard>
        <Box>
          <DataPanel
            projectId={projectId!}
            dataset={exploration.dataset!}
            filter={filter}
            themeColorFn={themeColorFn}
            columns={columns}
            dataState={dataState}
            dataStateDispatch={dataStateDispatch}
          />
        </Box>
      </Box>
    </StyledGrid>
  );
}
