import React from "react";
import moment from "moment";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Check as CheckIcon, Warning as WarningIcon } from "@mui/icons-material";
import { FormTextField } from "../../common/form/FormTextField";
import { CollectProjectStats } from "../../../api/apimodels";

interface StepSelectProjectProps {
  loading: boolean;
  projectLoadError: string | null;
  filteredProjects: CollectProjectStats[];
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  handleSearchClear: () => void;
  selectedProject?: CollectProjectStats;
  handleProjectSelect: (projectId: string) => void;
}

const formatDateRange = (startDate: Date | undefined | string, endDate: Date | undefined | string): string => {
  if (!startDate || !endDate) return "Full project period";
  const start = moment(startDate);
  const end = moment(endDate);
  const isStartTimeSignificant = !start.format("HH:mm").match(/(00:00|23:59)/);
  const isEndTimeSignificant = !end.format("HH:mm").match(/(00:00|23:59)/);
  if (start.isSame(end, "day")) {
    const dateStr = start.format("dddd YYYY-MM-DD");
    return isStartTimeSignificant && isEndTimeSignificant
      ? `${dateStr} (${start.format("HH:mm")} - ${end.format("HH:mm")})`
      : dateStr;
  }
  const startStr = `${start.format("dddd YYYY-MM-DD")}${isStartTimeSignificant ? " " + start.format("HH:mm") : ""}`;
  const endStr = `${end.format("dddd YYYY-MM-DD")}${isEndTimeSignificant ? " " + end.format("HH:mm") : ""}`;
  return `${startStr} - ${endStr}`;
};

export const StepSelectProject: React.FC<StepSelectProjectProps> = ({
  loading,
  projectLoadError,
  filteredProjects,
  searchQuery,
  setSearchQuery,
  handleSearchClear,
  selectedProject,
  handleProjectSelect,
}) => {
  return (
    <Box>
      {loading && <CircularProgress size={20} />}
      {projectLoadError && <Alert severity="error">{projectLoadError}</Alert>}
      {!loading && !projectLoadError && (
        <>
          <Box sx={{ mb: 2, position: "relative" }}>
            <FormTextField
              name="searchQuery"
              fullWidth
              placeholder="Search by project name, organization or ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
              InputProps={{
                sx: { borderRadius: 1, pr: searchQuery ? 6 : 2 },
                endAdornment: searchQuery ? (
                  <Button sx={{ position: "absolute", right: 8, minWidth: "auto", p: 0.5 }} onClick={handleSearchClear}>
                    <Typography fontSize="1.2rem">×</Typography>
                  </Button>
                ) : null,
              }}
            />
          </Box>
          {filteredProjects.length === 0 ? (
            <Alert severity="info">No projects match your search criteria</Alert>
          ) : (
            <List sx={{ overflow: "auto", mx: -2, borderRadius: 1 }}>
              {filteredProjects.map((project) => (
                <ListItemButton
                  key={project.project_id}
                  onClick={() => handleProjectSelect(project.project_id)}
                  selected={selectedProject?.project_id === project.project_id}
                  sx={{
                    cursor: "pointer",
                    borderRadius: 1,
                    mb: 0.5,
                    "&.Mui-selected": {
                      bgcolor: "action.hover",
                      color: "text.primary",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" fontWeight="medium">
                        {project.title}
                      </Typography>
                    }
                    secondary={
                      <Box sx={{ mt: 0.5 }}>
                        <Typography component="div" variant="body2" fontWeight="medium">
                          {project.organization_name}{" "}
                          <Typography component="span" variant="body2" color="text.secondary">
                            #{project.project_id}
                          </Typography>
                        </Typography>
                        <Typography component="div" variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                          {formatDateRange(project.start_date, project.end_date)}
                        </Typography>
                        {project.last_activity ? (
                          <Typography component="div" variant="body2" sx={{ mt: 0.5 }}>
                            Last activity: {moment(project.last_activity).format("YYYY-MM-DD HH:mm")}
                          </Typography>
                        ) : (
                          <Typography component="div" variant="body2" color="error" sx={{ mt: 0.5 }}>
                            No conversations completed
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                  {selectedProject?.project_id === project.project_id && (
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                  )}
                </ListItemButton>
              ))}
            </List>
          )}
        </>
      )}
    </Box>
  );
};
