import { Box, Breadcrumbs, Button, Container, Grid, Stack, SxProps, Theme } from "@mui/material";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ProjectModel, PromptModel } from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { Nav } from "../../components/common/Nav";
import { StandardCard } from "../../components/common/StandardCard";
import * as PageInfo from "../../pages/PageInfo";

import { Avatar, ListItem, ListItemAvatar, ListItemText, styled, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { DateText } from "../../components/common/DateText";
import { formatJsonDate } from "../../util/dateformat";
import { EditPromptDialog } from "./EditPromptDialog";
import { PromptType, promptTypeInfo } from "./PromptTypes";

//
const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  "& .MuiListItemSecondaryAction-root": {
    right: 0,
  },
}));

type Props = {
  type: PromptType;
  name: string;
  description?: string;
  createdAt?: string;
  sx?: SxProps<Theme>;
};

export default function PromptItem(props: Props) {
  const { type, name, description, createdAt, sx } = props;
  const theme = useTheme();
  const [, icon] = promptTypeInfo(type);

  return (
    <CustomListItem sx={sx}>
      <ListItemAvatar>
        <Avatar sx={{ color: theme.palette.text.primary, background: undefined }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          <Box component="span">
            <Typography component="span" sx={{ display: "block" }}>
              {description}
              {!!createdAt && <DateText>Created: {formatJsonDate(createdAt)}</DateText>}
            </Typography>
          </Box>
        }
      />
    </CustomListItem>
  );
}

export function PromptDetailsPage() {
  const navigate = useNavigate();
  const prompt = useLoaderData() as PromptModel;
  const [editOpen, setEditOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const project = useRouteLoaderData("project") as ProjectModel;

  const onDelete = async () => {
    try {
      await Apis.shared().project.deletePrompt(project.id!, prompt.id!);
      navigate("/" + PageInfo.promptPath(project.id!, undefined));
    } catch (e) {
      console.error(e);
    }
  };

  const onSave = async (name: string, description: string, text: string, type: PromptType) => {
    try {
      await Apis.shared().project.updatePrompt(project.id!, prompt.id!, {
        name,
        description,
        text,
        type,
      });
      setEditOpen(false);
      navigate("/" + PageInfo.promptPath(project.id!, prompt.id!));
    } catch (e) {
      console.error(e);
    }
  };

  return !prompt ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
          <Nav to="./..">{PageInfo.PromptPage.name}</Nav>
          <Typography color="text.primary">{prompt.name}</Typography>
        </Breadcrumbs>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3} sx={{ "& .MuiPaper-root": { mt: 0 } }}>
            <Grid item xs={12} md={6} lg={6}>
              <StandardCard>
                <PromptItem
                  name={prompt.name}
                  description={prompt.description}
                  type={prompt.type as PromptType}
                  sx={{ mb: 2 }}
                />
                <Typography variant="caption" component="h6">
                  Prompt text:
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                  {prompt.text}
                </Typography>
                <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                  <Button onClick={() => setConfirmDeleteOpen(true)}>Delete</Button>
                  <Button
                    onClick={() => {
                      setEditOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </Stack>
              </StandardCard>
            </Grid>
            <Grid item xs={12} md={4} lg={4}></Grid>
          </Grid>
          <EditPromptDialog
            open={editOpen}
            onClose={() => setEditOpen(false)}
            onSave={onSave}
            dialogTitle={"Edit Prompt"}
            prompt={prompt}
            projectId={project.id!}
          />
        </Container>
      </Box>
    </Box>
  );
}
