import { Card, styled } from "@mui/material";

export const StandardCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: "flex",
  boxShadow: theme.shadows[2],
  flexDirection: "column",
  "& .MuiStack-root.title": {
    marginTop: 0,
    marginBottom: 2,
    width: "100%",
  },
}));
