import React, { useState, ReactNode } from "react";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmButtonProps extends ButtonProps {
  dialogTitle?: string;
  dialogContent?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  dialogTitle = "Confirm Action",
  dialogContent = "Are you sure you want to proceed?",
  confirmText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  children,
  onClick,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    if (onClick) onClick(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <Button onClick={handleOpen} {...buttonProps}>
        {children}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {typeof dialogContent === "string" ? (
            <DialogContentText id="confirm-dialog-description">{dialogContent}</DialogContentText>
          ) : (
            dialogContent
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelText}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmButton;
