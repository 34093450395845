import React from "react";
import { Alert, Box, Button, CircularProgress, Stack } from "@mui/material";
import { CollectStatistics } from "./CollectStatistics";
import { UseFormReturn } from "react-hook-form";
import { CollectProjectStats } from "../../../api/apimodels";

interface StepStatisticsProps {
  fetchingStats: boolean;
  statistics: CollectProjectStats;
  formMethods: UseFormReturn<any>;
  fetchStatistics: () => Promise<void>;
}

export const StepStatistics: React.FC<StepStatisticsProps> = ({
  fetchingStats,
  statistics,
  formMethods,
  fetchStatistics,
}) => {
  return (
    <Box>
      {fetchingStats ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress size={40} />
        </Box>
      ) : statistics ? (
        <Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button size="small" onClick={fetchStatistics}>
              Refresh
            </Button>
          </Stack>
          <CollectStatistics projectWithStats={statistics} onlyComplete={formMethods.getValues("onlyComplete")} />
        </Stack>
      ) : (
        <Alert severity="warning">Failed to load statistics.</Alert>
      )}
    </Box>
  );
};
