import { boolStr } from "../../util/params";
import {
  ApiId,
  DatasetModel,
  ExplorationModel,
  ExplorationSelection,
  JobInfoModel,
  LabelMappings,
  ProcessorModel,
  ProjectModel,
  PromptModel,
  CollectProjectStats,
  CollectDatasetImport,
  LogEntryModel,
  RegistryImage,
  DownloadUrlInfo,
} from "../apimodels";
import { standardDeleteOptions, standardGetOptions, standardPostOptions, standardPutOptions } from "../helpers";
import { HttpClient } from "../httpclient";
import { ApiBatchResult, FetchFn, IProjectApi } from "../types";

export class ProjectApi implements IProjectApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  public ver: number;

  constructor(httpClient: HttpClient) {
    this.baseUrl = httpClient.baseUrl;
    this.fetchFn = (input, init) => httpClient.fetch(input, init);
    this.ver = Math.random() * 10000000;
  }

  async fetchProject(id: ApiId): Promise<ProjectModel> {
    const url = new URL(`${this.baseUrl}/project/${id}`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as ProjectModel;
  }

  async deleteProject(projectId: ApiId, hard?: boolean): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}`);
    if (hard) {
      url.searchParams.set("hard_delete", "true");
    }
    const options = standardDeleteOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async toggleArchiveProject(projectId: ApiId): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/archive`);
    const options = standardPutOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async fetchDataset(projectId: ApiId, datasetId: ApiId): Promise<DatasetModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as DatasetModel;
  }

  async fetchDatasetLabels(projectId: ApiId, datasetId: ApiId): Promise<LabelMappings | undefined> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/labels`);
    try {
      const response = await this.fetchFn(url.toString(), standardGetOptions());
      const data = await response.json();
      return data as LabelMappings;
    } catch (e) {
      // Special handling for 409 status
      if (e instanceof Response && e.status === 409) {
        return undefined;
      }
      throw e;
    }
  }

  async fetchDatasets(
    projectId: ApiId,
    datasetId?: ApiId,
    filter: "active" | "archived" | "all" = "active"
  ): Promise<ApiBatchResult<DatasetModel>> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset`);
    if (datasetId) {
      url.searchParams.set("show_versions", "true");
      url.searchParams.set("dataset_id", datasetId.toString());
    }

    url.searchParams.set("filter", filter);

    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    const items = data as DatasetModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async requestDownloadUrl(url: URL): Promise<DownloadUrlInfo> {
    const response = await this.fetchFn(url.toString(), standardPostOptions());
    const data = await response.json();
    const downloadUrl = new URL(data.download_url);
    if (url.hostname === "localhost") {
      downloadUrl.host = "localhost";
      downloadUrl.port = url.port;
    }
    return {
      url: downloadUrl.toString(),
      expiresIn: data.expires_in,
    };
  }

  async createDatasetDownloadUrl(projectId: ApiId, datasetId: ApiId): Promise<DownloadUrlInfo> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/download-url`);
    return this.requestDownloadUrl(url);
  }

  async createDatasetLabelsDownloadUrl(projectId: ApiId, datasetId: ApiId): Promise<DownloadUrlInfo> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/labels-download-url`);
    return this.requestDownloadUrl(url);
  }

  async createDatasetArtifactDownloadUrl(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string
  ): Promise<DownloadUrlInfo> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/artifact/${filename}/download-url`);
    return this.requestDownloadUrl(url);
  }

  async createProcessorDownloadUrl(projectId: ApiId, processorId: ApiId): Promise<DownloadUrlInfo> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/${processorId}/download-url`);
    return this.requestDownloadUrl(url);
  }

  async createDataset(projectId: ApiId, data: DatasetModel): Promise<DatasetModel> {
    const url = `${this.baseUrl}/project/${projectId}/dataset`;
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    return (await response.json()) as DatasetModel;
  }

  async createProcessedDataset(
    projectId: ApiId,
    parentId: ApiId,
    name: string | undefined,
    description: string | undefined,
    processorId: ApiId,
    processorParams: Record<string, any>,
    imageTag: string,
    imageName: string
  ): Promise<DatasetModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${parentId}/process`);
    const options = standardPostOptions({
      body: JSON.stringify({
        name,
        description,
        processor_id: processorId,
        processor_params: processorParams,
        image_tag: imageTag,
        image_name: imageName,
      }),
    });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as DatasetModel;
  }

  async updateDataset(projectId: ApiId, datasetId: ApiId, name?: string, description?: string): Promise<DatasetModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}`);
    const options = standardPutOptions({ body: JSON.stringify({ name, description }) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as DatasetModel;
  }

  async deleteDataset(projectId: ApiId, datasetId: ApiId, hard?: boolean): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}`);
    if (hard) {
      url.searchParams.set("hard_delete", "true");
    }
    const options = standardDeleteOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async fetchArchivedDatasetsCount(projectId: ApiId): Promise<number> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/archived-count`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data.count;
  }

  async toggleArchiveDataset(projectId: ApiId, datasetId: ApiId): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/archive`);
    const options = standardPutOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async uploadDatasetContent(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    data: ArrayBuffer
  ): Promise<Response> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/data/${filename}`);
    const options = standardPostOptions({ body: data });
    (options.headers! as any)["Content-Type"] = "application/octet-stream";
    const response = await this.fetchFn(url.toString(), options);
    return response;
  }

  async uploadDatasetLabelsContent(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    data: ArrayBuffer
  ): Promise<Response> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/labels/${filename}`);
    const options = standardPostOptions({ body: data });
    (options.headers! as any)["Content-Type"] = "application/octet-stream";
    const response = await this.fetchFn(url.toString(), options);
    return response;
  }

  async uploadDatasetArtifact(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    data: ArrayBuffer
  ): Promise<Response> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/artifact/${filename}`);
    const options = standardPostOptions({ body: data });
    (options.headers! as any)["Content-Type"] = "application/octet-stream";
    const response = await this.fetchFn(url.toString(), options);
    return response;
  }

  datasetDownloadUrl(projectId: ApiId, datasetId: ApiId): string {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/data`);
    return url.toString();
  }

  datasetLabelsDownloadUrl(projectId: ApiId, datasetId: ApiId): string {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/labels`);
    return url.toString();
  }

  datasetArtifactDownloadUrl(projectId: ApiId, datasetId: ApiId, filename: string): string {
    const url = new URL(`${this.baseUrl}/project/${projectId}/dataset/${datasetId}/artifact/${filename}`);
    return url.toString();
  }

  async fetchJobs(
    projectId: ApiId,
    completed: boolean,
    pending: boolean,
    relatedEntity?: string,
    relatedIds?: ApiId[],
    jobIds?: ApiId[]
  ): Promise<JobInfoModel[]> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/job`);
    url.searchParams.set("completed", boolStr(completed));
    url.searchParams.set("pending", boolStr(pending));
    if (relatedEntity) {
      url.searchParams.set("related_entity", relatedEntity);
    }
    if (relatedIds) {
      url.searchParams.set("related_ids", relatedIds.join(","));
    }
    if (jobIds) {
      url.searchParams.set("job_ids", jobIds.join(","));
    }
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as JobInfoModel[];
  }

  async fetchJob(projectId: ApiId, jobId: ApiId): Promise<JobInfoModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/job/${jobId}`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as JobInfoModel;
  }

  async fetchJobLogs(projectId: ApiId, jobId: ApiId): Promise<LogEntryModel[]> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/job/${jobId}/logs`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as LogEntryModel[];
  }

  async fetchProcessor(projectId: ApiId, processorId: ApiId): Promise<ProcessorModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/${processorId}`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as ProcessorModel;
  }

  async fetchProcessors(projectId: ApiId, showArchived: boolean = false): Promise<ApiBatchResult<ProcessorModel>> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor`);
    if (showArchived) {
      url.searchParams.set("archived", "true");
    }
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    const items = data as ProcessorModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async createProcessor(projectId: ApiId, data: ProcessorModel): Promise<ProcessorModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor`);
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as ProcessorModel;
  }

  async updateProcessor(
    projectId: ApiId,
    processorId: ApiId,
    name?: string,
    description?: string
  ): Promise<ProcessorModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/${processorId}`);
    const options = standardPutOptions({ body: JSON.stringify({ name, description }) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as ProcessorModel;
  }

  async deleteProcessor(projectId: ApiId, processorId: ApiId, hard?: boolean): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/${processorId}`);
    if (hard) {
      url.searchParams.set("hard_delete", "true");
    }
    const options = standardDeleteOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async toggleArchiveProcessor(projectId: ApiId, processorId: ApiId): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/${processorId}/archive`);
    const options = standardPutOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async fetchArchivedProcessorsCount(projectId: ApiId): Promise<number> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/archived-count`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data.count;
  }

  async uploadProcessorContent(
    projectId: ApiId,
    processorId: ApiId,
    filename: string,
    data: ArrayBuffer
  ): Promise<Response> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/${processorId}/data/${filename}`);
    const options = standardPostOptions({ body: data });
    (options.headers! as any)["Content-Type"] = "application/octet-stream";
    const response = await this.fetchFn(url.toString(), options);
    return response;
  }

  processorDownloadUrl(projectId: ApiId, processorId: ApiId): string {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/${processorId}/data`);
    return url.toString();
  }

  async fetchProcessorRegistryImages(
    projectId: ApiId,
    imageName?: string,
    latestOnly: boolean = true
  ): Promise<RegistryImage[]> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/processor/registry-images`);
    if (imageName) {
      url.searchParams.set("image_name", imageName);
    }
    if (latestOnly) {
      url.searchParams.set("latest_only", "true");
    }
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    return await response.json();
  }

  // ---- EXPLORATIONS -----

  async fetchExploration(projectId: ApiId, explorationId: ApiId): Promise<ExplorationModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/${explorationId}`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as ExplorationModel;
  }

  async fetchExplorations(projectId: ApiId, showArchived: boolean = false): Promise<ApiBatchResult<ExplorationModel>> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration`);
    if (showArchived) {
      url.searchParams.set("archived", "true");
    }
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    const items = data as ExplorationModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async createExploration(projectId: ApiId, data: ExplorationModel): Promise<ExplorationModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration`);
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as ExplorationModel;
  }

  async updateExploration(
    projectId: ApiId,
    explorationId: ApiId,
    name?: string,
    description?: string
  ): Promise<ExplorationModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/${explorationId}`);
    const options = standardPutOptions({ body: JSON.stringify({ name, description }) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as ExplorationModel;
  }

  async deleteExploration(projectId: ApiId, explorationId: ApiId, hard?: boolean): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/${explorationId}`);
    if (hard) {
      url.searchParams.set("hard_delete", "true");
    }
    const options = standardDeleteOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async toggleArchiveExploration(projectId: ApiId, explorationId: ApiId): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/${explorationId}/archive`);
    const options = standardPutOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async createExplorationSelection(
    projectId: ApiId,
    explorationId: ApiId,
    selection: ExplorationSelection
  ): Promise<ExplorationSelection> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/${explorationId}/selections`);
    const options = standardPostOptions({ body: JSON.stringify(selection) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as ExplorationSelection;
  }

  async saveExplorationSelection(
    projectId: ApiId,
    explorationId: ApiId,
    selectionId: ApiId,
    selection: ExplorationSelection
  ): Promise<ExplorationSelection> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/${explorationId}/selections/${selectionId}`);
    const options = standardPutOptions({ body: JSON.stringify({ ...selection }) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as ExplorationSelection;
  }

  async deleteExplorationSelection(projectId: ApiId, explorationId: ApiId, selectionId: ApiId): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/${explorationId}/selections/${selectionId}`);
    const options = standardDeleteOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async fetchArchivedExplorationsCount(projectId: ApiId): Promise<number> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/exploration/archived-count`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data.count;
  }

  // ---- PROMPT ----

  async fetchPrompts(projectId: ApiId, type?: string, showArchived?: boolean): Promise<ApiBatchResult<PromptModel>> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/prompt`);
    if (type) {
      url.searchParams.set("type", type);
    }
    if (showArchived) {
      url.searchParams.set("archived", "true");
    }
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    const items = data as PromptModel[];
    return {
      empty: items.length === 0,
      count: items.length,
      items,
    };
  }

  async fetchPrompt(projectId: ApiId, promptId: ApiId): Promise<PromptModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/prompt/${promptId}`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data as PromptModel;
  }

  async createPrompt(projectId: ApiId, data: PromptModel): Promise<PromptModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/prompt`);
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as PromptModel;
  }

  async updatePrompt(projectId: ApiId, promptId: ApiId, data: PromptModel): Promise<PromptModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/prompt/${promptId}`);
    const options = standardPutOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as PromptModel;
  }

  async deletePrompt(projectId: ApiId, promptId: ApiId, hard?: boolean): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/prompt/${promptId}`);
    if (hard) {
      url.searchParams.set("hard_delete", "true");
    }
    const options = standardDeleteOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async toggleArchivePrompt(projectId: ApiId, promptId: ApiId): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/prompt/${promptId}/archive`);
    const options = standardPutOptions();
    await this.fetchFn(url.toString(), options);
    return;
  }

  async fetchArchivedPromptsCount(projectId: ApiId): Promise<number> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/prompt/archived-count`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    const data = await response.json();
    return data.count;
  }

  async fetchCollectProjects(projectId: ApiId): Promise<CollectProjectStats[]> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/collect/projects`);
    const response = await this.fetchFn(url.toString(), standardGetOptions());
    return await response.json();
  }

  async importCollectDataset(projectId: ApiId, data: CollectDatasetImport): Promise<DatasetModel> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/collect/projects/import`);
    const options = standardPostOptions({
      body: JSON.stringify(data),
    });
    const response = await this.fetchFn(url.toString(), options);
    return (await response.json()) as DatasetModel;
  }

  async deleteCollectConversations(
    projectId: ApiId,
    data: {
      project_id: string;
      conversation_ids: string[];
    }
  ): Promise<void> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/collect/projects/conversations/delete`);
    const options = standardPostOptions({ body: JSON.stringify(data) });
    await this.fetchFn(url.toString(), options);
  }

  async restoreAllCollectConversations(
    projectId: ApiId,
    data: {
      project_id: string;
    }
  ): Promise<any> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/collect/projects/conversations/restore-all`);
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url.toString(), options);
    return await response.json();
  }

  async fetchCollectProjectStatistics(
    projectId: ApiId,
    data: {
      project_id: string;
      only_complete: boolean;
      start_time?: string;
      end_time?: string;
    }
  ): Promise<any> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/collect/projects/statistics`);
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url.toString(), options);
    return await response.json();
  }

  async fetchCollectProjectConversations(
    projectId: ApiId,
    data: {
      project_id: string;
      only_complete: boolean;
      start_time?: string;
      end_time?: string;
      limit?: number;
      offset?: number;
    }
  ): Promise<any> {
    const url = new URL(`${this.baseUrl}/project/${projectId}/collect/projects/conversations`);
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url.toString(), options);
    return await response.json();
  }
}
