import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ProjectList from "../components/project/ProjectList";
import CreateProjectForm from "../components/project/CreateProjectForm";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import * as PageInfo from "./PageInfo";
import { ApiRole, ProjectModel } from "../api/apimodels";
import { useApplication } from "../state/applicationstate";

function OverviewPage() {
  const navigate = useNavigate();
  const { projects, archivedCount } = useRouteLoaderData("projects") as {
    projects: ProjectModel[];
    archivedCount: number;
  };
  const { currentOrganization } = useApplication();

  function onSelectProject(id: number) {
    const project = projects.find((p) => p.id === id);
    console.assert(project !== undefined, "Missing project");
    navigate("/" + PageInfo.projectPath(id));
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
              <ProjectList onSelectProject={onSelectProject} projects={projects} archivedCount={archivedCount} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              {(currentOrganization?.role ?? 0) >= ApiRole.admin && <CreateProjectForm />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default OverviewPage;
