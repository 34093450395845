import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { PromptModel } from "../../../../api/apimodels";

type SavePromptDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (name: string, description: string) => void;
  text: string;
  original: PromptModel | undefined;
};

export const SavePromptDialog = (props: SavePromptDialogProps) => {
  const { open, original, onClose, onSave } = props;
  const [name, setName] = useState(original ? original.name + " Copy" : "");
  const [description, setDescription] = useState(original ? original.description : "");
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          py: 1,
        },
      }}
    >
      <DialogTitle>Save prompt preset</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack direction="column" gap={2}>
          <TextField
            label="Name"
            value={name}
            variant="standard"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setName(e.target.value)}
          />
          <TextField
            label="Description"
            multiline
            value={description}
            variant="standard"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDescription(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(name, description)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
