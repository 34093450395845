import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// Determine the environment
const isDevelopment = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUBYod3DuTsxnvMEK-6uPYb9WRPUbC5Ss",
  authDomain: isDevelopment ? window.location.host : "analysis.canucci.net",
  projectId: "cd-analysis-ui-b7b38",
  storageBucket: "cd-analysis-ui-b7b38.firebasestorage.app",
  messagingSenderId: "979920690132",
  appId: "1:979920690132:web:62520b221abfbcbebe9441",
};
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

// Connect to the emulator in development
if (isDevelopment) {
  console.log("🔥 Using Firebase Auth Emulator");
  connectAuthEmulator(auth, "http://localhost:9099");
}

export { auth };
