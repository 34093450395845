import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { OrganizationInfo } from "../api/apimodels";
import { Session } from "../api/sessionstore";
import { ApiLoadingState } from "../api/types";
import { UITheme } from "../components/theme";
import { useAuth } from "../contexts/AuthContext";
import { OrganizationController } from "./organization";

// Types and Interfaces
type ApplicationNotification = {
  id?: string; // Setting a constant ID can be used to group messages together that should all be cleared when closing one of them
  type: "info" | "warning" | "error";
  message: ReactNode;
  modal?: boolean;
};

export type ApplicationState = {
  maskTexts: boolean;
  uiTheme: UITheme;
  loading: ApiLoadingState;
  notifications: ApplicationNotification[];
  organizations: OrganizationInfo[];
  currentOrganization: OrganizationInfo | null;
};

// Action types grouped together
export enum ApplicationActionTypes {
  setLoading,
  addNotification,
  removeNotification,
  setMaskTexts,
  currentOrganization,
  setTheme,
  setCurrentOrganization,
  setOrganizationData,
}

// Action interfaces grouped together
interface ApplicationAction {
  type: ApplicationActionTypes;
}

export interface AddNotification extends ApplicationAction {
  type: ApplicationActionTypes.addNotification;
  notification: ApplicationNotification;
}

export interface RemoveNotifiction extends ApplicationAction {
  type: ApplicationActionTypes.removeNotification;
  id: string;
}

export interface SetMaskTextsAction extends ApplicationAction {
  type: ApplicationActionTypes.setMaskTexts;
  isMasked: boolean;
}

export interface SetThemeAction extends ApplicationAction {
  type: ApplicationActionTypes.setTheme;
  theme: UITheme;
}

export interface SetOrganizationDataAction extends ApplicationAction {
  type: ApplicationActionTypes.setOrganizationData;
  organizations: OrganizationInfo[];
  currentOrganizationId: number;
}

export interface SetLoadingAction extends ApplicationAction {
  type: ApplicationActionTypes.setLoading;
  state: ApiLoadingState;
}

export interface SetCurrentOrganizationAction extends ApplicationAction {
  type: ApplicationActionTypes.setCurrentOrganization;
  id: number;
}

type AnyApplicationAction =
  | AddNotification
  | RemoveNotifiction
  | SetThemeAction
  | SetMaskTextsAction
  | SetOrganizationDataAction
  | SetCurrentOrganizationAction
  | SetLoadingAction;

const emptyApplicationState: ApplicationState = {
  notifications: [],
  uiTheme:
    window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? UITheme.dark : UITheme.light,
  maskTexts: false,
  organizations: [],
  currentOrganization: null,
  loading: ApiLoadingState.notLoading,
};

function emptyStateFromStorage(): ApplicationState {
  let emptyState: ApplicationState = { ...emptyApplicationState };
  return emptyState;
}

function uniqueId() {
  return Math.random() + "_" + Date.now();
}

function handleNotifications(state: ApplicationState, action: AddNotification | RemoveNotifiction): ApplicationState {
  if (action.type === ApplicationActionTypes.addNotification) {
    return {
      ...state,
      notifications: [...state.notifications, { ...action.notification, id: action.notification.id ?? uniqueId() }],
    };
  } else {
    return {
      ...state,
      notifications: state.notifications.filter((n) => n.id !== action.id),
    };
  }
}

function handleOrganizationChange(
  state: ApplicationState,
  action: SetCurrentOrganizationAction | SetOrganizationDataAction
): ApplicationState {
  if (action.type === ApplicationActionTypes.setOrganizationData) {
    Session.shared().organizationId = action.currentOrganizationId;
    return {
      ...state,
      organizations: action.organizations,
      currentOrganization: action.organizations.find((o) => o.id === action.currentOrganizationId) ?? null,
    };
  } else {
    Session.shared().organizationId = action.id;
    return {
      ...state,
      currentOrganization: state.organizations.find((o) => o.id === action.id) ?? null,
    };
  }
}

function reducer(state: ApplicationState, action: AnyApplicationAction) {
  switch (action.type) {
    case ApplicationActionTypes.addNotification:
    case ApplicationActionTypes.removeNotification:
      return handleNotifications(state, action as any);

    case ApplicationActionTypes.setOrganizationData:
    case ApplicationActionTypes.setCurrentOrganization:
      return handleOrganizationChange(state, action as any);

    case ApplicationActionTypes.setMaskTexts:
      return { ...state, maskTexts: action.isMasked };

    case ApplicationActionTypes.setTheme:
      return { ...state, uiTheme: action.theme };

    case ApplicationActionTypes.setLoading:
      return { ...state, loading: action.state };

    default:
      throw new Error(`Unknown action type: ${(action as any).type}`);
  }
}

export const ApplicationContext = createContext(emptyStateFromStorage());
export const ApplicationDispatcherContext = createContext((_: AnyApplicationAction) => {});

export function useApplicationReducer() {
  return useReducer(reducer, emptyStateFromStorage());
}

export function useApplication() {
  return useContext(ApplicationContext);
}

export function useApplicationDispatch() {
  return useContext(ApplicationDispatcherContext);
}

export const ApplicationContextProvider = ({ children }: { children: ReactNode }) => {
  const [application, applicationDispatch] = useApplicationReducer();
  const { permissions } = useAuth();

  // Update application state when auth permissions change
  useEffect(() => {
    if (permissions) {
      applicationDispatch({
        type: ApplicationActionTypes.setOrganizationData,
        organizations: permissions.organizations,
        currentOrganizationId: Session.shared().organizationId ?? permissions.organizations[0]?.id,
      });
    }
  }, [permissions, applicationDispatch]);

  // Add listener to OrganizationController for global organization changes
  useEffect(() => {
    const unsubscribe = OrganizationController.getInstance().addListener((newOrgId: number) => {
      const newOrg = application.organizations.find((org) => org.id === newOrgId);
      // Only dispatch if the organization is changing
      if (newOrg && newOrg.id !== application.currentOrganization?.id) {
        applicationDispatch({
          type: ApplicationActionTypes.setCurrentOrganization,
          id: newOrgId,
        });
      }
    });

    return unsubscribe;
  }, [application.organizations, application.currentOrganization?.id, applicationDispatch]);

  return (
    <ApplicationContext.Provider value={application}>
      <ApplicationDispatcherContext.Provider value={applicationDispatch}>
        {children}
      </ApplicationDispatcherContext.Provider>
    </ApplicationContext.Provider>
  );
};
