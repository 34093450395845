import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Box, Button, CircularProgress, Container, Divider, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GoogleButton from "react-google-button";
import { useNavigate } from "react-router-dom";
import { Session } from "../api/sessionstore";
import { ApiLoadingState } from "../api/types";
import { useAuth } from "../contexts/AuthContext";

const SHOW_SIGNIN_WITH_EMAIL_PASSWORD = false;

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState("");
  const { loading, login, signUp, loginWithGoogle, isProcessingDeepLink, user } = useAuth();
  const [showEmailPasswordLogin, setShowEmailPasswordLogin] = useState(false);
  // const [remember, setRemember] = useState(false);
  const navigate = useNavigate();

  // Immediately check for deepLink on mount - before any auth state is available
  const hasDeepLink = Session.shared().deepLink !== null;

  // Redirect authenticated users away from login page
  useEffect(() => {
    // Only redirect if:
    // 1. User is authenticated (has a token)
    // 2. Not currently processing a deep link
    // 3. No deep link exists
    // 4. Not in the initial loading state
    if (Session.shared().token && !isProcessingDeepLink && !hasDeepLink) {
      console.log("🔒 🔄 Authenticated user on login page with no deep link, redirecting to overview");
      navigate("/overview");
    }
  }, [user, isProcessingDeepLink, hasDeepLink, navigate]);

  const handleEmailPasswordAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    try {
      if (isSignUp) {
        await signUp(email, password);
      } else {
        await login(email, password);
      }
    } catch (error: any) {
      console.error("Authentication error:", error);
      setError(error.message || "Authentication failed");
    }
  };

  const handleGoogleSignIn = async () => {
    setError("");

    try {
      await loginWithGoogle();
      // Redirect will happen automatically after successful auth
    } catch (error: any) {
      console.error("Google sign-in error:", error);
      setError(error.message || "Google sign-in failed");
    }
  };

  // Show spinner when:
  // 1. Loading state is active
  // 2. We're processing a deep link
  // 3. During initial load if a deep link exists
  if (loading === ApiLoadingState.loading || isProcessingDeepLink) {
    return (
      <Paper sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            {isProcessingDeepLink ? "Authenticating and redirecting..." : "Loading..."}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box component="main" sx={{ flexGrow: 0, overflow: "auto" }}>
          <Container maxWidth="xs">
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                Sign in
              </Typography>
              <Divider sx={{ mt: 2, mb: 2, width: "100%" }} />
              <Typography component="h3" variant="h6" sx={{ mb: 1 }}>
                Sign in to the Canucci Analysis platform.
              </Typography>
              {/*
              <FormControlLabel
                onChange={() => {
                  const shouldRemember = !remember;
                  Session.changeStorageProvider(
                    Session.shared(),
                    shouldRemember ? PersistentStorageProvider.name : EphemeralStorageProvider.name
                  );
                  setRemember(shouldRemember);
                }}
                checked={remember}
                sx={{ mt: 1, mb: 0 }}
                control={<Checkbox color="primary" />}
                label="Stay signed in"
              />
              */}
              <Divider sx={{ mt: 2, mb: 3, width: "100%" }} />
              <Box component="form" noValidate sx={{ mt: 0, mb: 2, width: "100%" }}>
                <GoogleButton onClick={handleGoogleSignIn} style={{ width: "100%" }} />
              </Box>
              {SHOW_SIGNIN_WITH_EMAIL_PASSWORD && (
                <Box>
                  <Box sx={{ mt: 1, mb: 0, width: "100%" }}>
                    <Button
                      variant="outlined"
                      fullWidth
                      size="large"
                      onClick={() => setShowEmailPasswordLogin(!showEmailPasswordLogin)}
                    >
                      Sign in with email and password
                    </Button>
                  </Box>
                  {showEmailPasswordLogin && (
                    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleEmailPasswordAuth}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        error={!!error}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        autoFocus
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        error={!!error}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete={isSignUp ? "new-password" : "current-password"}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={false} //loading === ApiLoadingState.loading
                      >
                        {isSignUp ? "Sign Up" : "Sign In"}
                      </Button>
                      <Button onClick={() => setIsSignUp(!isSignUp)} fullWidth sx={{ mt: 2 }}>
                        {isSignUp ? "Already have an account? Sign In" : "Don't have an account? Sign Up"}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              <Box component="form" noValidate sx={{ mt: 1 }}>
                {error && (
                  <Typography sx={{ mt: 2 }} color="error">
                    Login failed: {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Paper>
  );
}
