export function standardGetOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "GET",
    //mode: 'no-cors',
    redirect: "follow",
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardPostOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "POST",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardPutOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "PUT",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardDeleteOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "DELETE",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}
