import { ArrowDropDown } from "@mui/icons-material";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import { Button, ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate, useRevalidator } from "react-router-dom";
import { ApiRole } from "../../api/apimodels";
import { Session } from "../../api/sessionstore";
import { ApplicationActionTypes, useApplication, useApplicationDispatch } from "../../state/applicationstate";
import { OrganizationController } from "../../state/organization";

export function OrganizationSelector() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { organizations, currentOrganization } = useApplication();
  const dispatch = useApplicationDispatch();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = async (orgId: number) => {
    const org = organizations.find((org) => org.id === orgId);
    if (Session.shared().token && org) {
      // Update the application state
      dispatch({
        type: ApplicationActionTypes.setCurrentOrganization,
        id: org.id,
      });

      // Also notify the organization controller
      await OrganizationController.getInstance().switchOrganization(org.id);

      // Navigate and revalidate
      navigate("/overview");
      revalidator.revalidate();
      handleClose();
    }
  };

  return (
    <div>
      <Button
        variant="text"
        sx={{
          color: isDark ? "primary.main" : "primary.contrastText",
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<ArrowDropDown />}
        startIcon={(currentOrganization?.role ?? 0) >= ApiRole.admin && <AdminIcon fontSize="small" />}
      >
        {currentOrganization?.name}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {(organizations ?? []).map((org) => (
          <MenuItem key={org.id} onClick={() => handleSelect(org.id)} selected={org.id === currentOrganization?.id}>
            {org.role >= ApiRole.admin && (
              <ListItemIcon sx={{ "&.MuiListItemIcon-root": { minWidth: "28px" } }}>
                <AdminIcon fontSize="small" />
              </ListItemIcon>
            )}
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
